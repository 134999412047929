import React, { useState } from 'react';
import forgotLock from '../../assets/images/forgot_pwd.png';
import logo from '../../assets/images/logo_light.png';
import { sendRequest } from '../../util/API';
import { REQUESTURL } from '../../constant/REQUEST-URL';
import Loader from '../../component/Loader/Loader';
import Alert from '../../component/Alert/Alert';

const ForgotPassword = (props) => {

    const [email, setEmail] = useState("");

    const [alert, setAlert] = useState({
        timeout:2000,
        message:"",
        messageType:"",
        isVisible:false
     });
     const [loading, setLoading] = useState({
         isVisible:false
      });
 


    const onChangeHandler = (event) => {
        setEmail(event.target.value);
    }

    const onSubmit = () =>{
        setLoading({isVisible:true});
        const data = {
            email: email
        }
       
        sendRequest(REQUESTURL.FORGOT_PASSWORD,data).then(response=>{
            setLoading({isVisible:false});
            showAlert(response.statusMessage, 2000, "SUCCESS");
        }).catch(error=>{
            setLoading({isVisible:false});
            showAlert(error, 2000, "ERROR");
        })
    }


    const showAlert = (message, timeout, type)=>{
        setAlert({
            message:message,
            messageType: type,
            timeout: timeout,
            isVisible: true
        })
    }

    const onAlertClose = () => {
        setAlert({
            isVisible : false
        });
    }

    return (
        <div className="login">
            {loading.isVisible ? <Loader></Loader> : null} 
            {alert.isVisible ?  <Alert alert={alert} onClose={onAlertClose}></Alert> : null}
            <div className="bg_blue"></div>
            <div className="container">
                <div className="login_box">
                    <div className="table_cell">
                        <div className="center_div">
                            <div className="row">
                                <div className="col-md-6 pr-0 align-self-center">
                                    <div className="logo text-center">
                                        <img src={logo} className="img-fluid" alt="" />
                                        <h1>Triangle</h1>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <div className="white_box text-center">
                                        <img src={forgotLock} className="img-fluid lock" alt="" />
                                        <h2 className="mt-4 mb-5">Forgot Password?</h2>
                                        <form>
                                            <div className="form-group mb-4">
                                                <input type="text" className="from-control" value={email} placeholder="Email address" onChange={onChangeHandler}/>
                                            </div>
                                            <button type="button" className="btn btn-primary btn-lg btn-block" onClick={onSubmit}>Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;