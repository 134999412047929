import React from "react";
import { useEffect } from "react";
import mqttService from "../../util/MqttService";
import { useState } from "react";
import { convertDateTimeToString, getDeviceDate } from "../../util/DateUtil";
import MarkerPopup from "./MarkerPopup";
import { updateDevice } from '../../redux/Device/device.actions';
import { connect } from "react-redux";
import { sendAuthRequest } from "../../util/API";
import { REQUESTURL } from "../../constant/REQUEST-URL";

const FleetMarkerPopup = (props) => {
  let lastData = undefined;
  if(props.device.lastDeviceData!=null){
    let { location, courseAngle, inputDate, speed } = props.device.lastDeviceData;
    let latitude = location.coordinates[1];
    let longitude = location.coordinates[0];
    lastData = {
      angle: courseAngle,
      latitude: latitude,
      longitude: longitude,
      speed: speed,
      time: convertDateTimeToString(new Date(inputDate)),
      markerPosition:[latitude, longitude],
    }
  }
  let client;
  const [deviceData, setDeviceData] = useState(lastData);

  const [markerShow, setMarkerShow] = useState(false);

  const [parkingTime, setParkingTime] = useState(undefined);

  useEffect(() => {
    if (props.device!=undefined) {
      client = mqttService.getClient((error) => {
        console.log(error);
      });
      mqttService.subscribe(client, `hd/${props.device.imei}`, (error) => {
        console.log(error);
      });
      client.on("message", (topic, message, packet) => {
        console.log(new TextDecoder("utf-8").decode(message));
        messageReceived(new TextDecoder("utf-8").decode(message));
      });
    }

    return () => {
      if (props.device) {
        mqttService.unsubscribe(client, `hd/${props.device.imei}`);
        mqttService.closeConnection(client);
      }
    };
  },[deviceData]);

  const markerClickHandler = () =>{
    if(!markerShow){
      setMarkerShow(true);
    }else{
      setMarkerShow(false);
    }
  }

  
  

  function messageReceived(message) {
    // console.log(message);
    const data = message.split(",");
    const time = convertDateTimeToString(getDeviceDate(data[1])); //Date
    const latitude = data[2]; //Latitude
    const longitude = data[4]; //Longitude
    const angle = data[6]; //Angle
    const speed = data[7]; //speed
    if((speed == 0 || speed==undefined || speed=="") && markerShow){
      const data = {
        "deviceId": props.device.deviceId
      }
      sendAuthRequest(REQUESTURL.GET_LAST_PARKING_DATA, data).then(res=>{
        if(res.status==1){
           setParkingTime(res.result.differenceInMinute);
        }
      }, err=>{

      })
    }
    setDeviceData({
      angle: angle,
      latitude: latitude,
      longitude: longitude,
      speed: speed,
      time: time,
      markerPosition:[latitude, longitude],
    });
    props.update(props.device.imei, message);
  }
  
  return deviceData !== undefined ? (
    <MarkerPopup
      click={markerClickHandler} 
      device={props.device}
      speed={deviceData.speed ? deviceData.speed : 0}
      latitude={deviceData.latitude ? deviceData.latitude : ""}
      longitude={deviceData.longitude ? deviceData.longitude : ""}
      time={deviceData.time ? deviceData.time : ""}
      position={deviceData.markerPosition}
      angle={deviceData.angle}
      liveTrackParking={parkingTime!=undefined} 
      parkingTime= {parkingTime}
    ></MarkerPopup>
  ) : null;
};


// const mapStateToProps = (state, ownProps) => {
//   return {
//      message: state.device[ownProps.device.imei]
//    };
//   };
const mapDispatchToProps = (dispatch) => {
  return {
     update: (imei, message) => dispatch(updateDevice(imei, message)),
    };
  };

export default connect(null, mapDispatchToProps)(FleetMarkerPopup);
