import ManageHomeDevice from "./ManageHomeDevice/ManageHomeDevice";
import LiveTrack from './LiveTrack/LiveTrack';
import Playback from './Playback/Playback';

const getRoutes = (url) => {
    return  [
        {
            to: url + "/:id/play-back",
            label:"Play Back",
            component: Playback,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/:id/live-track",
            label:"Live Track",
            component: LiveTrack,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url,
            label:"Home",
            component: ManageHomeDevice,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
    ]
}
export default getRoutes;