export const isAuthenticated = () =>{
    return localStorage.getItem("_token") ? true : false
}

export const getLoginDetails = () => {
    if(isAuthenticated){
        return JSON.parse(localStorage.getItem("_USER"));
    }else {
        return null;
    }   
}


export const getLoginType = () => {
    if(isAuthenticated){
        return JSON.parse(localStorage.getItem("_USER")).type;
    }else {
        return null;
    }   
}

export const getRoleKey = () => {
    var key = 0;
    switch (getLoginType()){
        case "ROLE_USER":
            key = 1;
            break;
        case "ROLE_RETAILER":
            key = 2;
            break;
        case "ROLE_DEALER":
            key = 3;
            break;
        case "ROLE_MEGA_DEALER":
            key = 4;
            break;
        case "ROLE_ADMIN":
            key = 5;
            break;
        default:
            key = 0
    }
    return key;
}

export const isAdmin = () => {
    return getLoginType() === "ROLE_ADMIN";
}

export const isUser = () => {
    return getLoginType() === "ROLE_USER";
}
 

export const loginUserId = () => {
    if(isAuthenticated){
        return JSON.parse(localStorage.getItem("_USER")).userId;
    }else {
        return null;
    }   
}