import React from 'react';
import GeoFenceMap from '../../../component/Map/GeoFenceMap';
import { geoJSON } from 'leaflet';
const ViewMore = (props) => {
    const geofence = props.location.state;

    
    const prepareGeoJson = (geofence)=>{

        let array = [...geofence.area.coordinates];

        let coordinates = array.map(data=>{
            let temp =  data[0];
            data[0] = data[1];
            data[1] = temp;
            return data;
        });

        const geometry = {
            type:"Polygon",
            coordinates:[
                coordinates
            ]
        }
        
        const geoJson = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": geometry
                },
            ]
        };

        return geoJson;
    }

    const backClickHandler = () =>{
        props.history.goBack();
    }
    return (
        <div className="add_geofence page_wrapper_height">
            <div className="title_heading">
                <h2>View Geofence</h2>
                <div className="right_part ml-auto"><button className="btn btn-gray" type="button" onClick={backClickHandler}> Back</button></div>
            </div>
            <GeoFenceMap viewOnly={true} selectedGeoFence={prepareGeoJson(geofence)} centerPosition={geofence.area.coordinates[0]}></GeoFenceMap>
        </div>
    );

}



export default ViewMore;