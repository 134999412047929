import React from "react";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import CalenderInput from "../../../component/CalenderInput/CalenderInput";
import { generateLiveSharingLink, initializeFormState, updateFormState } from "../../../redux/Livetracksetting/Livetracksetting.actions";
import { convertDateToString } from "../../../util/DateUtil";
import "../LiveTrackSharingSetting.css"

class LiveSharingModal extends React.Component {

    dateChangeHandler = (moment, type) => {
        const value = convertDateToString(moment.toDate());
        this.props.updateFormState(type, value, this.props.formState);
    }

    generateLiveSharingLink = () => {
        this.props.generateLiveSharingLink(this.props.device, this.props.formState);
    }

    componentDidMount(){
        this.props.initializeFormState(this.props.device);
    }

    copyToClipboard = () =>{
        navigator.clipboard.writeText(this.props.formState.link);
    }

    render() {
        return (
            <Modal
                show="true"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton onHide={this.props.onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Live Sharing
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-12">
                                <div className="form-group">
                                    <label className="mb-2 w-100">Start Date</label>
                                    <Datetime
                                        onChange={(moment)=> this.dateChangeHandler(moment, "startDate")}
                                        dateFormat={"DD MMM, YYYY"}
                                        closeOnSelect={true}
                                        timeFormat={"HH:mm:ss"}
                                        inputProps={{ placeholder: "Start Date" }}
                                        renderInput={CalenderInput}
                                        value={this.props.formState.startDate ? new Date(this.props.formState.startDate) : new Date()}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-12">
                                <div className="form-group">
                                    <label className="mb-2 w-100">End Date</label>
                                    <Datetime
                                        onChange={(moment)=> this.dateChangeHandler(moment, "endDate")}
                                        dateFormat={"DD MMM, YYYY"}
                                        closeOnSelect={true}
                                        timeFormat={"HH:mm:ss"}
                                        inputProps={{ placeholder: "End Date" }}
                                        renderInput={CalenderInput}
                                        value={this.props.formState.endDate ? new Date(this.props.formState.endDate) : new Date()}
                                    />
                                </div>
                            </div>
                            {this.props.formState.link && (
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2 w-100">Generated Link</label>
                                        <div className="link position-relative">
                                            <input type="text" value={this.props.formState.link}  className="form-control" disabled />
                                            <i className="fa fa-clone" onClick={this.copyToClipboard}></i>
                                        </div>
                                        
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group">
                        <button type="button" className="form-control btn btn-blue mr-2" disabled={this.props.formState.link ? true: false} onClick={()=>this.generateLiveSharingLink()}>Generate Link</button>    
                    </div>
                    
                    <button onClick={this.props.onHide} type="button" className="btn btn-gray">Close</button>
                </Modal.Footer>
            </Modal>
        )
    }



}

function mapStateToProps(state) {
    return {
        formState: state.LiveTrackSetting.formState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateFormState: (type, value, formState) => dispatch(updateFormState(type, value, formState)),
        generateLiveSharingLink: (device, formState) => dispatch(generateLiveSharingLink(device, formState)),
        initializeFormState: (device) => dispatch(initializeFormState(device))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveSharingModal);