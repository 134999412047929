import React from 'react';
import { getColumn } from '../../util/ReportUtil';
export const TripReport = (props) =>{

    const columns = getColumn(props.reportType);

    
    let col = null;

    if(columns.length>0){
        col = columns.map(column=>{
            return (
                <th key={column.value}>{column.label}</th>
            )
        })
    }
    let rows = [];
    if(props.data && props.data.length>0){
        rows = props.data.map((data)=>{
            const rows = data.trips.map((trip, index)=>{
                return createRowForTrip(trip, index+1);
            })
            rows.push(createRowForDate(data));
            return rows;
        })
    }

    function createRowForDate(data){
        return (
            <tr>
                <td colSpan={8} style={{"lineHeight": "26px"}}>
                    <b className='font-weight-bold pt-1'>Date</b> : {data.startDate}<br/>
                    <b className='font-weight-bold pt-1'>Total Running KM</b> : {data.tripDistance}<br/>
                    <b className='font-weight-bold pt-1'>Driving Duration</b> : {getDuration(data.totalRunningDuration)}<br/>
                    <b className='font-weight-bold pt-1'>Parking Duration</b> : {getDuration(data.totalParkingDuration)}
                </td>
            </tr>
        )
    }

    function createRowForTrip(data, id){
        return (
            <>
            <tr>
                <td>{id}</td>
                <td>{data.startDate}</td>
                <td>{data.endDate}</td>
                <td>{data.type}</td>
                <td>{getDuration(data.totalDuration)}</td>
                <td>{data.type==="PARKING"? "-": data.tripDistance}</td>
                <td>{latLong(data.startLocation)}</td>
                <td>{latLong(data.endLocation)}</td>
            </tr>
            </>
        )
    }

    function latLong(location){
        const googleLink = `https://maps.google.com/?q=${location.coordinates[1]}, ${location.coordinates[0]}`;
        const latLong = (<a href={googleLink} target="_blank" rel="">{ `${location.coordinates[1]}, ${location.coordinates[0]}` }</a>);
        return latLong;
    }

    function getDuration(timeMinute){
        let totalHours = 0;
        while(timeMinute>=60){
            totalHours = totalHours + 1;
            timeMinute = timeMinute - 60;
        }
        if(totalHours==0){
            return  timeMinute + " Min";
        }
        return totalHours + " Hours " + timeMinute + " Min";
    }

    return (
        <table className="table table-bordered" id="trip_report">
            <thead>
                <tr>
                    {col}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );


}