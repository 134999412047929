import React, { useState } from "react";
import { EXTERNAL_API } from "../../util/API";
import { Popup, Marker } from "react-leaflet";
import { getIcon, getParkingIcon } from "../../util/MapIconUtil";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker"

const MarkerPopup = (props) => {

  const { name, type } = props.device;
  const [addressLine, setAddressLineVisible] = useState(false);
  const [address, setAddress] = useState("Loading...");

  const fetchAddressHandler = () => {
    setAddressLineVisible(true);
    const latitude = props.latitude;
    const longitude = props.longitude;
    fetchAddress(latitude, longitude);
  };

  let icon; 
  if(props.parking){
    icon = getParkingIcon();
  }else{
    icon = getIcon(props.angle,type, props.speed);
  }

  

  const fetchAddressFree = (latitude, longitude) => {
    
    const url = `https://geocode.xyz/${latitude},${longitude}?geoit=json`;
    EXTERNAL_API.get(url).then((data) => {
      data = data.data;
      let address = "";
      if (data.staddress !== undefined && data.staddress.length)
        address += data.staddress + ", ";

      if (data.city !== undefined) address += data.city + ", ";
      if (data.region !== undefined) address += data.region + ", ";

      if (data.country !== undefined) address += data.country + ", ";
      setAddress(address);
    });
  };

  const fetchAddress = (latitude, longitude) => {
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=256f9e21cda54fe69cdb3fac7b809719`;
    EXTERNAL_API.get(url).then((data) => {
      data = data.data;
      if (data.rate.remaining < 1) {
        fetchAddressFree();
      } else {
        if (data.results[0]) {
          const address = data.results[0].formatted;
          setAddress(address);
        } else {
          fetchAddressFree(latitude, longitude);
        }
      }
    });
  };
  let parkingTime = Math.floor(props.parkingTime);
  let hours = 0;
  while(parkingTime >60){
    parkingTime = parkingTime - 60;
    hours++;
  }
  let parkingString = `${parkingTime} minutes`;
  if(hours>0){
    parkingString = `${hours} Hours ${parkingTime} minutes`;
  }

  const handleClick = () =>{
    if(props.click){
      props.click();
    }
    
  }
  if(props.type=="PLAYBACK"){
    return (
      <Marker position={props.position} icon={icon} onClick={handleClick}>
        {getPopup()}
      </Marker>
    )
  }
  return (
    <ReactLeafletDriftMarker duration={4000} position={props.position} icon={icon} onClick={handleClick}>
        {getPopup()}
    </ReactLeafletDriftMarker>
  );

  function getPopup(){
    return (
      <Popup>
        <div className="map_popup">
          <div className="qa">
            <span className="name">Device:</span>
            <span className="value">{name}</span>
          </div>
          <div className="qa">
            <span className="name">Speed:</span>
            <span className="value">{`${props.speed} km/h`}</span>
          </div>
          <div className="qa">
            <span className="name">Time:</span>
            <span className="value">{props.time}</span>
          </div>
          <div className="qa">
            <span className="name">Lat/Lng:</span>
            <span className="value">{`${props.latitude}/${props.longitude}`}</span>
          </div>
          {(props.parking || props.liveTrackParking) && (
            <div className="qa">
              <span className="name">Parking:</span>
              <span className="value">{`${parkingString}`}</span>
            </div>
          )}
          {!props.parking && (
              <div className="addr">
                {addressLine && <p>{address}</p>}
                {!addressLine && (
                  <button
                    className="btn btn-blue btn-sm btn-block"
                    type="button"
                    onClick={fetchAddressHandler}
                  >
                    Fetch Address
                  </button>
                )}
            </div>
          )}
        </div>
      </Popup>
    )
  }
};

export default MarkerPopup;
