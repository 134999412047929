import React from 'react';
import { convertDateTimeToString } from '../../util/DateUtil';
import { getColumn } from '../../util/ReportUtil';
import { AcReport } from './ac-report';
import { IgnitionReport } from './ignition-report';
import {RunningReport} from './running-report';
import { StopReport } from './stop-report';
import { TripReport } from './trip-report';

const DATE_KEYS = ["startDate","endDate"];
const LOCATION_KEYS = ["startLocation","endLocation"];
const TIME_KEYS = ["totalRunningDuration"];
const DISTANCE_KEY = ["tripDistance"];
const ID_KEYS = ["id"];

const getColumnValue=(key, value)=>{
    if(isId(key)){
        return value[key];
    }else if(isLocation(key)){
        let location = value[key];
        return location.coordinates.join(", ");
    }else if(isDate(key)){
        let date = value[key];
        return convertDateTimeToString(new Date(date));
    }else if(isTime(key)){
        let timeMinute = +value[key];
        let totalHours = 0;
        while(timeMinute>=60){
            totalHours = totalHours + 1;
            timeMinute = timeMinute - 60;
        }
        return totalHours + " Hours " + timeMinute + " Min"
    }else if (isDistance(key)){
        let totalKM = value[key];
        return totalKM;
    }
    return "";
}

const isId = (key)=>{
    return ID_KEYS.includes(key);
}
const isLocation = (key)=>{
    return LOCATION_KEYS.includes(key);
}
const isTime = (key)=>{
    return TIME_KEYS.includes(key);
}
const isDistance = (key)=>{
    return DISTANCE_KEY.includes(key);
}
const isDate =(key)=>{
    return DATE_KEYS.includes(key);
}

export const ReportTable = (props) =>{
    


    let table = null;

    if(props.reportType==="RUNNING_REPORT"){
        table = <RunningReport reportType={props.reportType} data={props.data}></RunningReport>
    }else if(props.reportType==="STOP_REPORT"){
        table = <StopReport reportType={props.reportType} data={props.data}></StopReport>
    }else if(props.reportType==="AC_REPORT"){
        table = <AcReport reportType={props.reportType} data={props.data}></AcReport>
    }else if(props.reportType==="IGNITION_REPORT"){
        table = <IgnitionReport reportType={props.reportType} data={props.data}></IgnitionReport>
    }else if(props.reportType==="TRIP_REPORT"){
        table = <TripReport reportType={props.reportType} data={props.data}></TripReport>
    }

    const columns = getColumn(props.reportType);
    
    let col = null;

    if(columns.length>0){
        col = columns.map(column=>{
            return (
            <th key={column.value}>{column.label}</th>
            )
        })
    }
    let rowData = null; 
    if(props.data && props.data.length>0){
        rowData = props.data.map((value, index, arr)=>{
            let colData = Object.keys(value).map((key)=>{
                return <td key={key}>{getColumnValue(key, value)}</td>
            });
            return (
                <tr key={index}>
                    {colData}    
                </tr>
            )
        })
    }

    const mapValue = (key, index, value) =>{
        return (<td key={key}>{this.getColumnValue(key, value)}</td>);
    }

    return (
        <div className="custom_table">
            <div className="table-responsive">
               {table}
            </div>
        </div>
    );

    
};