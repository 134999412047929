import React from 'react';
import { useParams } from 'react-router-dom'
import forgotLock from '../../assets/images/forgot_pwd.png';
import logo from '../../assets/images/logo_light.png';
import Password from '../../component/PasswordComponent/Password';

const ResetPassword = (props) => {
    
    //to get the id
    console.log(useParams());

    return (
        <div className="login">
            <div className="bg_blue"></div>
            <div className="container">
                <div className="login_box">
                    <div className="table_cell">
                        <div className="center_div">
                            <div className="row">
                                <div className="col-md-6 pr-0 align-self-center">
                                    <div className="logo text-center">
                                        <img src={logo} className="img-fluid" alt="" />
                                        <h1>Triangle</h1>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <div className="white_box text-center">
                                        <img src={forgotLock} className="img-fluid lock" alt="" />
                                        <h2 className="mt-4 mb-5">Create Password</h2>
                                        <form>
                                            <div className="form-group mb-4">
                                                <Password placeholder="New Password"/>
                                            </div>
                                            <div className="form-group mb-4">
                                                <Password placeholder="Confirm Password"/>
                                            </div>
                                            
                                            <button type="button" className="btn btn-primary btn-lg btn-block">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;