import React from 'react';
import notification from '../../assets/audio/notification.mp3';

const NotificationBox = (props) => {


    const { messageType, timeout,  message, title } = props.notification;

    const alertClasses = ["alert", "box_shadow"];
    let icon = <i className="fas fa-info-circle"></i> ;
    switch(messageType){
        case "Over Speed":
            alertClasses.push("alert-success");
            icon = <i className="fas fa-tachometer-alt"></i>;
            break;
        case "Geofence":
            alertClasses.push("alert-success");
            icon = <i className="fa fa-map" aria-hidden="true"></i>;
            break;
        case "Battery":
            alertClasses.push("alert-success");
            icon = <i className="fas fa-car-battery"></i>;
            break;
        default:
            alertClasses.push("alert-info");
    }
    return (
        <div className="notification_box">
           <audio autoPlay="autoplay">
                <source src={notification} type="audio/mpeg"></source>
           </audio>
           <div className={alertClasses.join(" ")}>
                 <p className="title">
                 {icon}{title}
                </p>
                <p>
                   {message}
                </p>
            </div>
        </div>
    )
}


export default NotificationBox;