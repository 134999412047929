import React from 'react';
import './App.css';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Login from './view/Login/Login';
import Dashboard from './Layout/Dashboard/Dashboard';
import ForgotPassword from './view/ForgotPassword/ForgotPassword';
import ResetPassword from './view/ResetPassword/ResetPassword';
import PrivateRoute from './component/Route/PrivateRoute';
import { PrivacyPolicy } from './view/Privacy-policy/Privacy-Policy';
import LiveShare from './view/LiveShare/LiveShare';

function App() {

  return (
    <BrowserRouter>
      {/* <div className="App">   */}
        <Switch>
          <Route path="/login" component={Login}></Route>
          <PrivateRoute Component={Dashboard} path="/dashboard"></PrivateRoute>
          <Route path="/forgot-password" component={ForgotPassword}></Route>
          <Route path="/reset-password/:id" component={ResetPassword}></Route>
          <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
          <Route path="/liveshare/:id" component={LiveShare}></Route>
          <Redirect from="/" to="/dashboard"></Redirect>
        </Switch>
      {/* </div> */}
    </BrowserRouter>
  );
}

export default App;
