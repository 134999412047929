import React from 'react';
import {convertDateTimeToString} from '../../util/DateUtil';

const SaleDeviceItem = (props) => {

    const {username,email, saleDate} = props.saleDevice;
    const {name,imei,serialNo} = props.saleDevice.device
    return (
        <div className="col-xl-6 col-lg-12 col-md-12 col-12 theme_card_margin">
            <div className="list_theme_card">
                <div className="list_heading d-flex align-items-center">
                    <p>{name ? name : serialNo}</p>
                </div>
                <div className="details_action d-flex align-items-end">
                    <div className="details">
                        <p><strong>IMEI :</strong> {imei}</p>
                        <p><strong>SR No. :</strong> {serialNo}</p>
                        <p><strong>Name :</strong> {username}</p>
                        <p><strong>Email :</strong> {email}</p>
                        <p><strong>Date :</strong> {convertDateTimeToString(new Date(saleDate))}</p>
                    </div>
                    <div className="action ml-auto">
                        <button type="button" className="delete_icon_btn icon_btn" 
                        onClick={() => props.deleteSaleDeviceHandler(props.saleDevice)}><i className="fas fa-trash-alt"></i></button>
                    </div>  
                </div>
            </div>
        </div>
    );

}

export default SaleDeviceItem;