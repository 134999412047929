import React from 'react'

const Payments = () =>{
    return (
        <div className="dashboard_home">
            <h1>Payments</h1>
        </div>
    );
}

export default Payments;