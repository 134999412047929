import React from 'react';
import './Loader.css';

const Loader = () =>{

    return (
        <div className="loader_backdrop">
            <div className="loader">        
            </div>
        </div>
    )
}


export default Loader;