import React from 'react';

import Select from 'react-select';
const Suggestion = (props) => {

    return (
        <Select
            value={props.value}
            onChange={(event) => props.handleChange(event,props.type)}
            isDisabled={props.disabled? props.disabled: false}
            options={props.options} isSearchable={props.isSearchable} isMulti= {props.isMulti} defaultInputValue={props.defaultValue}
        />
    );
}

export default React.memo(Suggestion);