import React, {Component} from 'react';
import  { Switch, Redirect } from 'react-router-dom';
import getRoutes from './HomeRoutes';
import  ProtectedRoute from '../../component/Route/ProtectedRoute';
import './Home.css';
class Home extends Component{

    render(){
        const HomeRoute = getRoutes(this.props.match.url).map(ele=>{
            return <ProtectedRoute map={ele} key={ele.label} exact path={ele.to} Component={ele.component}/>
        });
        return (
            <Switch>
                {HomeRoute}
                <Redirect from={`${this.props.match.url}/`} to={`${this.props.match.url}`}></Redirect>
            </Switch>
        );
    }
}

export default Home;