import React, { createRef } from "react";
import FleetMarkerPopup from "../MarkerPopup/FleetMarkerPopup";

class FleetMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef();
  }

  render() {
    let markers = null;
    markers = this.props.devices.map(device=>{
        return (<FleetMarkerPopup key={device.deviceId} device={device}></FleetMarkerPopup>);
    });

    return markers;
  }
}

export default FleetMap;
