import React, {Component} from 'react';
import  { Switch, Redirect } from 'react-router-dom';
import getRoutes from './DeviceRoute';
import  ProtectedRoute from '../../component/Route/ProtectedRoute';

class Devices extends Component{

    
    render(){
        const deviceRoute = getRoutes(this.props.match.url).map(ele=>{
            return <ProtectedRoute map={ele} key={ele.label} path={ele.to} Component={ele.component}/>
        })
        return (
            <Switch>
                {deviceRoute}
                <Redirect from={`${this.props.match.url}/`}  to={`${this.props.match.url}/manage-device`}></Redirect>
            </Switch>
        );
    }
}

export default Devices;