import React from 'react';

const Alert = (props) => {


    const { messageType, timeout,  message } = props.alert;

    const alertClasses = ["alert", "box_shadow"];
    let icon = <i className="fas fa-info-circle"></i> ;
    switch(messageType){
        case "SUCCESS":
            alertClasses.push("alert-success");
            icon = <i className="fas fa-check-circle"></i>;
            break;
        case "ERROR":
            alertClasses.push("alert-danger");
            icon = <i className="fas fa-exclamation-circle"></i>;
            break;
        case "WARNING":
            alertClasses.push("alert-warning");
            icon = <i className="fas fa-exclamation-triangle"></i>
            break;
        default:
            alertClasses.push("alert-info");
    }


    setTimeout(()=>{
        props.onClose();
    }, timeout ? timeout : 2000);

    return (
        <div className="alert_box">
           <div className={alertClasses.join(" ")}>
                <p>
                    {icon}{message}
                </p>
            </div>
        </div>
    )
}


export default Alert;