import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { connect } from 'react-redux';
import { getLiveSharingLinks, updateLiveShareItem } from '../../redux/Livetracksetting/Livetracksetting.actions';
import { convertDateTimeToString } from '../../util/DateUtil';

class LiveTrackSharingSetting extends React.Component {


    componentDidMount() {
        this.props.getLiveSharingLinks();
    }

    updateStatus(item, status){
        item.status = !item.status;
        this.props.updateLiveShareItem(item);
    }

    deleteLink(item){
        this.props.updateLiveShareItem(item, "DELETE");
    }

    copyLink(item){
        navigator.clipboard.writeText(item.link);
    }

    getLiveSharingLinkItem(item) {
        return (
            <div className="col-xl-6 col-lg-12 col-md-12 col-12 theme_card_margin" key={item.id}>
                <div className="list_theme_card">
                    <div className="list_heading d-flex align-items-center">
                        <p>{item.deviceBean.name}</p>
                        <div className={item.status && !item.expired ? "right_div status_badge ml-auto active" : "right_div status_badge ml-auto inactive"}>
                            {item.status && !item.expired ? "Enabled" : "Disabled"}
                        </div>
                    </div>
                    <div className="details_action">
                        <div className="details live_share_details">
                            <p><strong>Start Date :</strong> {convertDateTimeToString(new Date(item.startDate))}</p>
                            <p><strong>End Date :</strong> {convertDateTimeToString(new Date(item.endDate))}</p>
                            <p className='link_for_live_sharing'><strong>Link :</strong>{item.link}</p>
                        </div>
                        <div className="action ml-auto live_share_action">
                            {!item.expired && (
                                <button
                                    type="button"
                                    className="edit_icon_btn icon_btn mr-2"
                                    onClick={() => this.copyLink(item)}>
                                    <i className="fas fa-copy"></i>
                                </button>
                            )}
                            {!item.expired && (
                                <button
                                    type="button"
                                    className="edit_icon_btn icon_btn mr-2"
                                    onClick={() => this.updateStatus(item)}>
                                    {item.status ? (<i className="fas fa-stop-circle"></i>) : <i className="fas fa-play-circle"></i>}
                                </button>
                            )}
                            
                            <button type="button" className="delete_icon_btn icon_btn" onClick={() => this.deleteLink(item)}><i className="fas fa-trash-alt"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderLiveSharingLinks() {
        if (!this.props.liveSharingLinks) return <></>
        return this.props.liveSharingLinks.map(item => this.getLiveSharingLinkItem(item))
    }

    render() {
        return (
            <div className="reports_page page_wrapper_height">
                <div className="title_heading">
                    <h2>Live Sharing Setting</h2>
                </div>
                <div className="custom_form inner_content_wrapper">
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <div className="inner_scroll_div">
                            <div className="row">
                                {this.renderLiveSharingLinks()}
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        liveSharingLinks: state.LiveTrackSetting.liveSharingLinks,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLiveSharingLinks: () => dispatch(getLiveSharingLinks()),
        updateLiveShareItem: (item, action) => dispatch(updateLiveShareItem(item, action))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveTrackSharingSetting);