import React, { useRef, useState } from "react";
import { FeatureGroup, Circle } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L, { geoJSON } from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

const DrawComponent = (props) => {
  let _editableFG = useRef(null);

  let [geoJsonData, setGeoJsonData] = useState(
    props.selectedGeoFence === null || props.selectedGeoFence === undefined
      ? undefined
      : props.selectedGeoFence
  );

  let draw = {
    rectangle: false,
    circle: false,
    marker: false,
    circlemarker: false,
    polyline: false,
    polygon: true,
  };
  if(props.viewOnly){
    draw = {
      rectangle: false,
      circle: false,
      marker: false,
      circlemarker: false,
      polyline: false,
      polygon: false,
    } 
  }

  const _onCreated = (e) => {
    let layer = e.layer;
    if (_editableFG && geoJsonData) {
      let leafletFg = _editableFG.leafletElement;
      leafletFg.removeLayer(layer);
    }
    _onChange();
  };

  const _onDeleted = (e) => {
    setGeoJsonData(undefined);
    props.onAreaDeleted();
  };

  const _onFeatureGroupReady = (reactFGref) => {
    // populate the leaflet FeatureGroup with the geoJson layers
    //Clear existing layers
    if (reactFGref) {
      let leafletFg = reactFGref.leafletElement;
      leafletFg.eachLayer((layer) => {
        leafletFg.removeLayer(layer);
      });
      if (!props.viewOnly) {
        setGeoJsonData(undefined);
      }
    }
    let displayPolygon = true;
    if (reactFGref && props.selectedGeoFence) {
      let leafletGeoJSON = new L.GeoJSON(props.selectedGeoFence);
      let leafletFG = reactFGref.leafletElement;
      if (!props.viewOnly) {
        setGeoJsonData(props.selectedGeoFence);
      }
      leafletGeoJSON.eachLayer((layer) => {
        leafletFG.addLayer(layer);
        displayPolygon = false;
      });
    }
    // leafletGeoJSON.eachLayer( (layer) => {
    //   leafletFG.addLayer(layer);
    // });

    // store the ref for future access to content

    _editableFG = reactFGref;
  };

  const _onChange = () => {
    // const { onChange } = this.props;

    let geoJson = _editableFG.leafletElement.toGeoJSON();
    const features = geoJson.features.filter((data) => {
      return data.geometry.type === "Polygon";
    });

    geoJson = {
      type: "FeatureCollection",
      features: features,
    };
    setGeoJsonData(geoJson);
    props.onAreaSelected(geoJson);
  };

  return (
    <FeatureGroup
      ref={(reactFGref) => {
        _onFeatureGroupReady(reactFGref);
      }}
    >
      <EditControl
        position="topright"
        onCreated={_onCreated}
        onDeleted={_onDeleted}
        draw={draw}
        edit={{
          edit: false,
          remove:!props.viewOnly
        }}
      />
    </FeatureGroup>
  );
};
export default DrawComponent;
