import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAuthenticated} from '../../util/Auth';

function PrivateRoute({ Component, ...rest }) {
    if(isAuthenticated()){
        return <Route {...rest} component={Component}></Route>
    }else{
        return  <Redirect to={{pathname: "/login"}}/>
    }
}
export default PrivateRoute;