import React, { Fragment } from 'react';
import './SpeedMeter.css';
const SpeedMeter = (props) => {
    // Get the length of the path
    // let length = path.getTotalLength();
    const length = 349.5177307128906;
    // Calculate the percentage of the total length
    let to = length * ((270 - props.speed) / 270);
    let strokeStyle = {
        strokeDashoffset : Math.max(0, to)
    }

    return (
        <Fragment>
            <svg height="170" width="200" viewBox="0 0 200 170">
                <path className="bg" stroke="#ccc" d="M41 149.5 a77 77 0 1 1 117.93 0"  fill="none"/>
                <path style={strokeStyle} stroke="#01BFCC" d="M41 149.5a77 77 0 1 1 117.93 0" fill="none" strokeDasharray="350" strokeDashoffset="350"/>
            </svg>
            <p>{props.speed} <span className="unit">km/h</span></p>
        </Fragment>
    );
}

export default SpeedMeter;