import React, { createRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './FleetTracking.css';
import FleetMap from '../../component/Map/FleetMap';
import { REQUESTURL } from '../../constant/REQUEST-URL';
import { sendAuthRequest } from '../../util/API';
import { getDeviceDate } from '../../util/DateUtil';
import FleetDeviceItem from '../../component/DeviceItem/FleetDeviceItem';
import { Map, TileLayer, LayersControl } from "react-leaflet";
import L from "leaflet";

const LIMIT_DEVICE = 10;
class FleetTracking extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            deviceList: [],
            isLoading: true,
            currentPage: 1,
            totalDevice: 0,
            alert: {
                timeout: 2000,
                message: "",
                messageType: "",
                isVisible: false
            },
            centerPosition: [21.912883, 70.039771],
            zoom: 8
        }
        this.mapRef = createRef();
    }


    fetchDevices = (data) => {
        data = {
            ...data,
            activeDevice:true
        }
        sendAuthRequest(REQUESTURL.FETCH_DEVICES_V2, data).then(response => {
            if (response.result) {

                if(response.result.devices!=null && response.result.devices.length > 0 && this.mapRef != undefined){
                    let boundArray = response.result.devices.filter(device=>device.lastDeviceData!=null).map(device=>{
                      let { location } = device.lastDeviceData;
                      let latitude = location.coordinates[1];
                      let longitude = location.coordinates[0];
                      return [latitude, longitude];
                    });
                    let map = this.mapRef.current.leafletElement;
                    if(map != undefined){
                      map.flyToBounds(boundArray,{animate:true});
                    }
                } 

                this.setState({
                    isLoading: false,
                    deviceList: response.result.devices,
                    totalDevice: response.result.totalDevices,
                    currentPage: response.result.currentPage
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }

        }).catch(error => {
          
            const alert = {
                ...this.state.alert,
                message: error,
                messageType: "ERROR",
                isVisible: true
            };
            this.setState({
                isLoading: false,
                alert: alert
            })
        });
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          this.searchDevices();
        }
      }
    searchDevices = (event)=>{
        const data = {
            currentPage: 1,
            limit: LIMIT_DEVICE,
            searchText: this.state.searchText,
            filterApplied:true,
            activeDevice:true
        }
        this.setState({
            currentPage: 1,
            isLoading: true,
            deviceList: [],
            isFilterApplied:true
        });
        this.fetchDevices(data);

    }

    sendRequestData = (isNext) => {
        const data = {
            currentPage: isNext ? this.state.currentPage + 1 : this.state.currentPage - 1,
            limit: LIMIT_DEVICE,
            nextPage: isNext,
            lastItemId: isNext ? this.state.deviceList[this.state.deviceList.length - 1].deviceId : this.state.deviceList[0].deviceId
        }
        this.setState({
            isLoading: true,
            deviceList: [],
        });
        this.fetchDevices(data)
    }

    componentDidMount(){
        const data = {
            currentPage: this.state.currentPage,
            limit: LIMIT_DEVICE
        }
        this.fetchDevices(data);
    }

    setCenterPosition = (position) =>{
        let map = this.mapRef.current.leafletElement;
        if(map != undefined){
            map.flyTo(new L.LatLng(position[0], position[1]),18,{animate:true});
        }
    }

    getDeviceList(){
        return this.state.deviceList.map(device=>{
            return (
                <FleetDeviceItem key={device.deviceId} device={device} setCenterPosition={this.setCenterPosition}/>
            );            
        });
    }

    zoomLevelChangeHandler = (event) =>{
        this.setState({
          zoom:event.sourceTarget._zoom
        });
    }

    render(){
        console.log(this.state.zoom);
        const disableClasses = "pagination_btn btn disable";
        const enableClasses = "pagination_btn btn";
        
        const items = this.getDeviceList();
        
        return(
            <div className="playback page_wrapper_height">
                <div className="title_heading">
                    <h2>Fleet Tracking</h2>
                    <div className="pagination_block">
                    <div className="pagination w-100 mt-0">
                        <button type="button" disabled={this.state.currentPage === 1} className={this.state.currentPage === 1 ? disableClasses : enableClasses} onClick={() => { this.sendRequestData(false) }}>Previous</button>
                        <button type="button" disabled={!((this.state.currentPage * LIMIT_DEVICE) < this.state.totalDevice)} className={(this.state.currentPage * LIMIT_DEVICE) < this.state.totalDevice ? enableClasses : disableClasses} onClick={() => { this.sendRequestData(true) }} >Next</button>
                    </div>
                </div>
                </div>
                <div className="custom_form inner_content_wrapper">
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <div className="inner_scroll_div">
                            <div className="list_of_vehicle">
                                <div className="fleet_searchbar">
                                <input type="text" className="form-control mr-2" placeholder="Search..." value={this.state.searchText} onChange={ (event)=>{this.setState({searchText:event.target.value})} } onKeyPress={this.handleKeyPress}/>
                                <i class="fa fa-search serch_icon" aria-hidden="true" onClick={this.searchDevices}></i>
                                </div>
                            <PerfectScrollbar options={{ suppressScrollX: true }}>
                                <ul>
                                    {items}
                                </ul>
                                </PerfectScrollbar>
                            </div>
                            <div className="map_sec">
                                <div className="live_track_map_block inner_content_wrapper">
                                    <Map
                                    className="livetrack_map"
                                    center={this.state.centerPosition}
                                    zoom={this.state.zoom}
                                    onzoomend={this.zoomLevelChangeHandler}
                                    ref={this.mapRef}>
                                    <LayersControl position="topright">
                                        <LayersControl.BaseLayer checked name="Google Map">
                                            <TileLayer
                                                attribution=""
                                                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                                                maxZoom={18}
                                                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                                                />
                                        </LayersControl.BaseLayer>
                                        <LayersControl.BaseLayer name="Google Settelite">
                                            <TileLayer
                                            attribution=""
                                            url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                                            maxZoom={18}
                                            subdomains={["mt0", "mt1", "mt2", "mt3"]}
                                            />
                                        </LayersControl.BaseLayer>
                                    </LayersControl>
                                    <FleetMap devices={this.state.deviceList} centerPosition={this.state.centerPosition}></FleetMap>
                                    </Map>
                                </div>
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}


export default FleetTracking;