import React, {Component} from 'react';
import  {Switch, Redirect } from 'react-router-dom';
import getRoutes from './GeofenceRoute';
import  ProtectedRoute from '../../component/Route/ProtectedRoute';
import './Geofence.css';
class Geofence extends Component{
    render(){
        const geofenceRoute = getRoutes(this.props.match.url).map(ele=>{
            return <ProtectedRoute map={ele} key={ele.label} exact path={ele.to} Component={ele.component}/>
        })
    return (
        <Switch>
            {geofenceRoute}
            <Redirect from={`${this.props.match.url}/`}  to={`${this.props.match.url}/manage-geofence`}></Redirect>
        </Switch>
    );
}
}

export default Geofence;

