import React, { useLayoutEffect, useRef } from 'react';
import * as moment from 'moment';
import AmCharts from "@amcharts/amcharts3-react";

export default class FuelChartReport extends React.Component{

    constructor(props){
        super(props);
    }

    componentDidUpdate(oldProps) {
    
        if(this.props.export===true){
            this.exportChart();
        }
    }

    newChangeData(){
        return this.props.data.map(data=>{
            return {
                date: moment(data.inputDate).format("DD-MMM HH:mm:ss"),
                // date: new Date(data.inputDate),
                fuel: parseFloat((data.factorA*data.fuel) + data.factorB).toFixed(0)
                
            }
        });
    }

    newRowFuelData(){
        return this.props.data.map(data=>{
            return {
                date: moment(data.inputDate).format("DD-MMM HH:mm:ss"),
                // date: new Date(data.inputDate),
                fuel: parseFloat(data.fuel)
                
            }
        });
    }

    componentDidMount(){
        let config = {
            "type": "serial",
            "categoryField": "date",
            "categoryAxis": {
                "gridPosition": "start",
                "labelRotation": 90
            },
            "theme": "light",
            "titles": [{
                "text": "Fuel Report"
              }],
            "marginRight": 80,
            "dataProvider": this.newChangeData(),
            "valueAxes": [{
                "position": "left",
                "title": "Fuel"
            }],
            "graphs": [{
                "id": "g1",
                "fillAlphas": 0.4,
                "valueField": "fuel",
                "balloonText": "<div style='margin:5px; font-size:19px;'>Fuel:<b>[[value]]</b></div>",
                "type": "smoothedLine",
                    
            }],
            "chartScrollbar": {
                "graph": "g1",
                "scrollbarHeight": 80,
                "backgroundAlpha": 0,
                "selectedBackgroundAlpha": 0.1,
                "selectedBackgroundColor": "#888888",
                "graphFillAlpha": 0,
                "graphLineAlpha": 0.5,
                "selectedGraphFillAlpha": 0,
                "selectedGraphLineAlpha": 1,
                "autoGridCount": true,
                "color": "#D4D4D4",
                "fontSize":0
            },
            "chartCursor": {
                "categoryBalloonDateFormat": "JJ:NN, DD MMMM",
                "cursorPosition": "mouse"
            },
            
            "export": {
                "enabled": true,
                "dateFormat": "YYYY-MM-DD HH:NN:SS"
            }
        };
        let config2 = {
            "type": "serial",
            "categoryField": "date",
            "categoryAxis": {
                "gridPosition": "start",
                "labelRotation": 90
            },
            "theme": "light",
            "titles": [{
                "text": "Voltage Report"
              }],
            "marginRight": 80,
            "dataProvider": this.newRowFuelData(),
            "valueAxes": [{
                "position": "left",
                "title": "Fuel"
            }],
            "graphs": [{
                "id": "g1",
                "fillAlphas": 0.4,
                "valueField": "fuel",
                "balloonText": "<div style='margin:5px; font-size:19px;'>Fuel:<b>[[value]]</b></div>",
                "type": "smoothedLine",
                    
            }],
            "chartScrollbar": {
                "graph": "g1",
                "scrollbarHeight": 80,
                "backgroundAlpha": 0,
                "selectedBackgroundAlpha": 0.1,
                "selectedBackgroundColor": "#888888",
                "graphFillAlpha": 0,
                "graphLineAlpha": 0.5,
                "selectedGraphFillAlpha": 0,
                "selectedGraphLineAlpha": 1,
                "autoGridCount": true,
                "color": "#D4D4D4",
                "fontSize":0
            },
            "chartCursor": {
                "categoryBalloonDateFormat": "JJ:NN, DD MMMM",
                "cursorPosition": "mouse"
            },
            
            "export": {
                "enabled": true,
                "dateFormat": "YYYY-MM-DD HH:NN:SS"
            }
        };

        this.chart = AmCharts.makeChart("chartdiv", config);
        this.chart2 = AmCharts.makeChart("chartdiv2", config2);
    }

    render(){
        return (
            <>
            <div id="chartdiv" style={{ width: "100%", height: "450px" }}></div>
            <div id="chartdiv2" style={{ width: "100%", height: "450px" }}></div>
            </>
        )
    }
}