import ManageDevice from "./ManageDevice/ManageDevice";
import AddDevice from './AddDevice/AddDevice';
import EditDevice from './EditDevice/EditDevice';

const getRoutes = (url) => {
    return  [
        {
            to: url + "/add-device",
            label:"Add Device",
            component: AddDevice,
            access: ["ROLE_ADMIN"]
        },
        {
            to: url + "/manage-device",
            label:"Device",
            component: ManageDevice,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/edit-device",
            label:"Edit Device",
            component: EditDevice,
            access: ["ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
    ]
}
export default getRoutes;