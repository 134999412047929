import React, {Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Password from '../../../component/PasswordComponent/Password';
import Suggestion from '../../../component/Suggestion/Suggestion';
import { validateEmail,validateCpassword, validateNumber } from '../../../util/Validation'
import { sendAuthRequest } from '../../../util/API';
import { REQUESTURL } from '../../../constant/REQUEST-URL';
import Loader from '../../../component/Loader/Loader';
import Alert from '../../../component/Alert/Alert';
import {loginUserId, getRoleKey} from '../../../util/Auth';
const USERTYPE = [
    { value: 'ROLE_MEGA_DEALER', label: 'Mega Dealer', key: 4 },
    { value: 'ROLE_DEALER', label: 'Dealer', key: 3 },
    { value: 'ROLE_USER', label: 'End User', key: 1},
    { value: 'ROLE_RETAILER', label: 'Retailer', key: 2 },
];
const MAPTYPE = [
    { value: 'GOOGLE_MAP', label: 'Google Map' },
    { value: 'FREE_MAP', label: 'Free Map' }
];
const TIMEZONE = [
    { value: 'INDIA', label: 'India' },
    { value: 'KENYA', label: 'Kenya' }
];
const NOTIFICATION = [
    { value: 'Y', label: 'Yes' },
    { value: 'N', label: 'No' }
];

class AddUser extends Component {
    constructor(props){
        super(props);
        this.isEditScreen = props.match.path.includes("edit");
        
        this.state = {       
            form:this.getForm(),
            isLoading: false,
            alert:{
                timeout:2000,
                message:"",
                messageType:"",
                isVisible:false
            }
        }
    }

    getForm = ()=>{
       
        if(this.isEditScreen){
            const {username, email, password, phoneNumber, type, companyName, gst, addressLine1, addressLine2, country, state, city, zipCode, mapType, timezone, notification, fuelNotification  } = this.props.location.state;
            const UserTypeArray = USERTYPE.filter((obj) => {
                return obj.value === type;
            });
            const MapTypeArray = MAPTYPE.filter((obj) => {
                return obj.value === mapType;
            });
            const TimezoneArray = TIMEZONE.filter((obj) => {
                return obj.value === timezone;
            });
            return {
                name:{
                    value:username ? username : "",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 email:{
                    value:email,
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    isDisabled: email ? true : false
                 },
                 password:{
                    value:password,
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 cpassword:{
                    value:password,
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 number:{
                    value:phoneNumber,
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 userType:{
                    value: UserTypeArray ? UserTypeArray[0] : "",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    isDisabled: UserTypeArray ? true : false
                 },
                 mapType:{
                    value: MapTypeArray[0],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 timezone:{
                    value:TimezoneArray[0],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 companyName:{
                    value:companyName,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 gst:{
                    value:gst,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 addressLine1:{
                    value:addressLine1,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 addressLine2:{
                    value:addressLine2,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 country:{
                    value:country,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 state:{
                    value:state,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 city:{
                    value:city,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 zipcode:{
                    value:zipCode,
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 notification:{
                    value:notification ? NOTIFICATION[0] : NOTIFICATION[1],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 fuelNotification:{
                    value: fuelNotification ? NOTIFICATION[0] : NOTIFICATION[1],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
            }
        }else{
            return {
                name:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 email:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 password:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 cpassword:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 number:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 userType:{
                    value: "",
                    message:undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                 },
                 mapType:{
                    value: MAPTYPE[0],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 timezone:{
                    value:TIMEZONE[0],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 notification:{
                    value:NOTIFICATION[0],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 fuelNotification:{
                    value:NOTIFICATION[0],
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 companyName:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 gst:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 addressLine1:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 addressLine2:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 country:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 state:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 city:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 },
                 zipcode:{
                    value:"",
                    message:undefined,
                    touched: false,
                    valid: false
                 }
            }
        }
    }
    
    handleChange = (selectedOption,type) => {
        let updatedSelect = {...this.state.form};
        updatedSelect[type].value = selectedOption;
        this.checkValidation(updatedSelect,type);
        this.setState(
            {...updatedSelect}
        );
    };
    checkValidation = (updatedstate,type) =>{
        updatedstate[type].touched = true;
            if(updatedstate[type].value === "" && updatedstate[type].isValidationRequire){
                updatedstate[type].valid = false;
                if(type === "name"){
                    updatedstate[type].message = "Please enter name." ;
                }else if(type === "email"){
                    updatedstate[type].message = "Please enter email." ;
                }else if(type === "password"){
                    updatedstate[type].message = "Please enter password." ;
                }else if(type === "cpassword"){
                    updatedstate[type].message = "Please enter confirm password." ;
                }else if(type === "userType"){
                    updatedstate[type].message = "Please select user type." ;
                }else if(type === "number"){
                    updatedstate[type].message = "Please enter phone number." ;
                }
            }else if(type === "number" && !validateNumber(updatedstate[type].value)){
                updatedstate[type].message = "Please enter valid phone number." ;
                updatedstate[type].valid = false;
            }else if (type === "email" && !validateEmail(updatedstate[type].value)){
                updatedstate[type].message = "Please enter valid email." ;
                updatedstate[type].valid = false;
            }else if (type === "cpassword" && !validateCpassword(updatedstate.cpassword.value, updatedstate.password.value)){
                updatedstate[type].message = "Password is not match." ;
                updatedstate[type].valid = false;
            }else{
                updatedstate[type].message = "";
                updatedstate[type].valid=true;
            }
    }
  
    
    onCloseAlert = ()=>{
        const alert = {
            ...this.state.alert,
            isVisible:false
        }
        this.setState({
            alert: alert
        })
    }
    changeHandler = (event, type) =>{
        const updatedstate = {...this.state.form};
        updatedstate[type].value = event.target.value;
        this.checkValidation(updatedstate,type);
        this.setState({
            ...updatedstate
        })
    }
    addNewUser = () => {
        this.setState({
            isLoading:true  
        });
        const form = { ...this.state.form };
        const data={
            username:form.name.value,
            password:form.password.value,
            email:form.email.value,
            type:form.userType.value.value,
            phoneNumber:form.number.value,
            status:1,
            timezone:form.timezone.value.value,
            referenceId: loginUserId(),
            companyName: form.companyName.value,
            gst: form.gst.value,
            addressLine1: form.addressLine1.value,
            addressLine2: form.addressLine2.value,
            country: form.country.value,
            state: form.state.value,
            city: form.city.value,
            zipCode: form.zipcode.value,
            mapType: form.mapType.value.value,
            notification: form.notification.value.value === "Y" ? true : false,
            fuelNotification: form.fuelNotification.value.value === "Y" ? true : false
        }
        // console.log(data);
        const clearForm = this.clearForm();
        sendAuthRequest(REQUESTURL.ADD_USER,data).then(response => {
            
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"SUCCESS",
                    timeout:2000,
                    message: response.statusMessage,
                    isVisible:true
                },
                form:clearForm
            });
        }).catch(error => {
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"ERROR",
                    timeout:2000,
                    message: error,
                    isVisible:true
                }
            });
        });
    }
    editUser = () => {
        this.setState({
            isLoading:true  
        });
        const form = { ...this.state.form };
        console.log(form.notification);
        const data={
            username:form.name.value,
            password:form.password.value,
            email:form.email.value,
            type:form.userType.value.value,
            phoneNumber:form.number.value,
            status:1,
            timezone:form.timezone.value.value,
            refId: this.props.location.state.refId,
            userId:this.props.location.state.userId,
            companyName: form.companyName.value,
            gst: form.gst.value,
            addressLine1: form.addressLine1.value,
            addressLine2: form.addressLine2.value,
            country: form.country.value,
            state: form.state.value,
            city: form.city.value,
            zipCode: form.zipcode.value,
            mapType: form.mapType.value.value,
            notification: form.notification.value.value === "Y" ? true : false,
            fuelNotification: form.fuelNotification.value.value === "Y" ? true : false
        }
        sendAuthRequest(REQUESTURL.EDIT_USER,data).then(response => {
            
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"SUCCESS",
                    timeout:2000,
                    message: response.statusMessage,
                    isVisible:true
                },
            });
        }).catch(error => {
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"ERROR",
                    timeout:2000,
                    message: error,
                    isVisible:true
                }
            });
        });
    }
    clearForm = () => {
        const updatedForm = {...this.state.form};
        for (let type in updatedForm) {
            updatedForm[type].value="";
            updatedForm[type].message=undefined;
            updatedForm[type].valid = false;
            updatedForm[type].touched= false;
        }
        return updatedForm;
    }
    submitHandler = () => {
        // console.log(this.state)
        let isValidType = true;
      
        const updatedstate = { ...this.state.form };
        for (let type in updatedstate) {
            this.checkValidation(updatedstate, type);
            if (updatedstate[type].valid === false) {
                isValidType = false;
                break;
            }
        }
        if(isValidType){
            if(this.isEditScreen){
                this.editUser();
            }else{
                this.addNewUser();
            }
           
        }else{
            const updatedstate = {...this.state.form};
            for(let type in updatedstate){
                this.checkValidation(updatedstate,type);
            }
            this.setState({
                ...updatedstate
            });
        }
    }
    cancelHandler = () =>{
        this.props.history.goBack();
    }
    render(){
        console.log(this.state)
        const isNameInValid = this.state.form.name.touched && !this.state.form.name.valid && this.state.form.name.message !== "";
        const isEmailInValid = this.state.form.email.touched && !this.state.form.email.valid && this.state.form.email.message !== "";
        const isPasswordInValid = this.state.form.password.touched && !this.state.form.password.valid && this.state.form.password.message !== "";
        const isCpasswordInValid = this.state.form.cpassword.touched && !this.state.form.cpassword.valid && this.state.form.cpassword.message !== "";
        const isNumberInValid = this.state.form.number.touched && !this.state.form.number.valid && this.state.form.number.message !== "";
        const isUserTypeInValid = this.state.form.userType.touched && !this.state.form.userType.valid && this.state.form.userType.message !== "";
        return (
            <div className="add_user page_wrapper_height">
                <div className="title_heading">
                    {this.isEditScreen ? <h2>Edit User</h2>: <h2>Add New User</h2>}
                    
                </div>
                <div className="custom_form inner_content_wrapper">
                {this.state.isLoading? <Loader></Loader> : null}
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <div className="inner_scroll_div">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Name <span className="red_text">*</span></label>
                                            <input type="text" className="form-control" value={this.state.form.name.value}  onChange={(event)=>{this.changeHandler(event, "name")}} />
                                            {isNameInValid ? <p className="errorMsg">{this.state.form.name.message}</p> :null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Email <span className="red_text">*</span></label>
                                            <input type="text" value={this.state.form.email.value}  className="form-control" onChange={(event)=>{this.changeHandler(event, "email")}} disabled={this.state.form.email.isDisabled} />
                                            {isEmailInValid ? <p className="errorMsg">{this.state.form.email.message}</p> :null}
                                        </div>
                                    </div>
                                </div>
    
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Password <span className="red_text">*</span></label>
                                            <Password value={this.state.form.password.value}  changeHandler={this.changeHandler}/>
                                            {isPasswordInValid ? <p className="errorMsg">{this.state.form.password.message}</p> :null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Confrim Password <span className="red_text">*</span></label>
                                            <Password value={this.state.form.cpassword.value} changeHandler={this.changeHandler} type="cpassword"/>
                                            {isCpasswordInValid ? <p className="errorMsg">{this.state.form.cpassword.message}</p> :null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group custom_select">
                                            <label className="mb-2 w-100">User Type <span className="red_text">*</span></label>
                                            <Suggestion value={this.state.form.userType.value} handleChange={this.handleChange} type="userType"
                                                    options={USERTYPE.filter(obj=>{
                                                        return obj.key <= (getRoleKey() - 1 )
                                                    })} isSearchable={true} 
                                                    disabled={this.state.form.userType.isDisabled}/>
                                            {isUserTypeInValid ? <p className="errorMsg">{this.state.form.userType.message}</p> :null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2">Phone Number <span className="red_text">*</span></label>
                                            <input value={this.state.form.number.value}  type="number" className="form-control" onChange={(event)=>{this.changeHandler(event, "number")}}/>
                                            {isNumberInValid ? <p className="errorMsg">{this.state.form.number.message}</p> :null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Company Name</label>
                                            <input type="text" className="form-control" value={this.state.form.companyName.value} onChange={(event)=>{this.changeHandler(event, "companyName")}}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">GST</label>
                                            <input type="text" className="form-control" value={this.state.form.gst.value}  onChange={(event)=>{this.changeHandler(event, "gst")}}/>
                                        </div>
    
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Address Line 1</label>
                                            <input type="text" className="form-control" value={this.state.form.addressLine1.value} onChange={(event)=>{this.changeHandler(event, "addressLine1")}}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Address Line 2</label>
                                            <input type="text" className="form-control" value={this.state.form.addressLine2.value}  onChange={(event)=>{this.changeHandler(event, "addressLine2")}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Country</label>
                                                    <input type="text" className="form-control" value={this.state.form.country.value} onChange={(event)=>{this.changeHandler(event, "country")}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">State</label>
                                                    <input type="text" className="form-control" value={this.state.form.state.value}  onChange={(event)=>{this.changeHandler(event, "state")}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">City</label>
                                                    <input type="text" className="form-control" value={this.state.form.city.value} onChange={(event)=>{this.changeHandler(event, "city")}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Zipcode</label>
                                                    <input type="text" className="form-control" value={this.state.form.zipcode.value} onChange={(event)=>{this.changeHandler(event, "zipcode")}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group custom_select">
                                            <label className="mb-2 w-100">Map Type</label>
                                            
                                            <Suggestion value={this.state.form.mapType.value}  handleChange={this.handleChange} type="mapType"
                                                    options={MAPTYPE} isSearchable={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group custom_select">
                                            <label className="mb-2 w-100">Timezone</label>
                                            
                                            <Suggestion value={this.state.form.timezone.value}  handleChange={this.handleChange} type="timezone"
                                                    options={TIMEZONE} isSearchable={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Notification</label>
                                                    <Suggestion value={this.state.form.notification.value}  handleChange={this.handleChange} type="notification"
                                                    options={NOTIFICATION} isSearchable={false} />
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Fuel Notification</label>
                                                    <Suggestion value={this.state.form.fuelNotification.value}  handleChange={this.handleChange} type="fuelNotification"
                                                    options={NOTIFICATION} isSearchable={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit_btn">
                                    <button type="button" className="btn btn-blue mr-2" onClick={this.submitHandler}>Save</button>
                                    { !this.isEditScreen ? <button className="btn btn-green mr-2">Clear</button>: null}
                                    <button className="btn btn-gray mr-2" onClick={this.cancelHandler} type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </PerfectScrollbar>
    
                </div>
            </div>
        );
    }
}


export default AddUser;