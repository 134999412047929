import React from 'react';
import { convertDateToString, addDays } from '../../util/DateUtil';
import { isUser, isAdmin} from '../../util/Auth';
import { Link } from 'react-router-dom';

const DeviceItem = (props) => {

   

    const {name, serialNo, imei, simNumber,installationDate,activationDay} = props.device;
    let expiryDate= null;
    if(installationDate){
        expiryDate = addDays(new Date(installationDate), activationDay);
    }
    const badgeClasses = ["right_div", "status_badge", "ml-auto"];
    if(expiryDate){
        badgeClasses.push("active");
    }else{
        badgeClasses.push("inactive");
    }

    return (
        <div className="col-xl-6 col-lg-12 col-md-12 col-12 theme_card_margin">
            <div className="list_theme_card">
                <div className="list_heading d-flex align-items-center">
                    <p>{name ? name : serialNo}</p>
                    <div className={badgeClasses.join(" ")}>{expiryDate ? "Active" : "Inactive"}</div>
                </div>
                <div className="details_action d-flex align-items-end">
                    <div className="details">
                        <p><strong>IMEI :</strong> {imei}</p>
                        <p><strong>SR No. :</strong> {serialNo}</p>
                        <p><strong>SIM No. :</strong> {simNumber ? simNumber :  "Not Specified"}</p>
                        <p><strong>Exp. Date :</strong>  {expiryDate ? convertDateToString(expiryDate) : "Not Specified"}</p>
                    </div>
                   
                        <div className="action ml-auto">
                        
                        {!isUser()?  <Link to={{
                                    pathname: "/dashboard/devices/edit-device",
                                    state: props.device
                                }} className="edit_icon_btn icon_btn mr-2"><i className="fas fa-pencil-alt"></i></Link>: null}
                        { isAdmin() ? <button type="button" className="delete_icon_btn icon_btn" onClick={() => props.deleteDeviceHandler(props.device)}><i className="fas fa-trash-alt"></i></button> : null }
                                
                        </div> 
                    
                    
                    
                </div>
            </div>
        </div>
    );

}

export default DeviceItem;