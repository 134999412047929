import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SaleDeviceFilter  from '../Filter/SaleDeviceFilter';
import SaleDeviceItem from '../../../component/SaleDeviceItem/SaleDeviceItem';
import ContentLoader from '../../../component/ContentLoader/ContentLoader';
import { sendAuthRequest } from '../../../util/API';
import {REQUESTURL} from '../../../constant/REQUEST-URL';
import SweetAlert from 'react-bootstrap-sweetalert';
import Alert from '../../../component/Alert/Alert';
const LIMIT_ITEM = 8;
class ManageSaleDevice extends Component{

    state= {
        saleDevices:[],
        displayFilter: window.innerWidth<=991 ? false : true,
        isLoading: true,
        alert:{
            timeout:2000,
            message:"",
            messageType:"",
            isVisible:false
        },
        deleteAlert: false,
        deleteSaleDevice: null,
        currentPage:1,
        totalSaleDevices: 0,
        filterApplied: false
    }
    filterHandler = () => {
        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: !prevState.displayFilter
                }
            })
        }

        
    }

    clearAllHandler = () => {

        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: false
                }
            })
        }

        const data = {
            limit: LIMIT_ITEM,
            currentPage : 1
        };
        this.setState({
            isLoading:true,
            saleDevices:[],
            totalSaleDevices: 0,
            filterApplied:false,
            currentPage:1,
        })
        this.fetchSaleDevices(data);
    }
    applyFilterHandler = (data) => {
        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: false
                }
            })
        } 

        data = {
            limit: LIMIT_ITEM,
            currentPage : this.state.currentPage,
            ...data,
            filterApplied:true
        };
        this.setState({
            isLoading:true,
            saleDevices:[],
            totalSaleDevices: 0,
            filterApplied:true
        })
        this.fetchSaleDevices(data);
    }

    fetchSaleDevices = (data, res) =>{
        sendAuthRequest(REQUESTURL.FETCH_SALEDEVICE, data).then(response => {
            let saleDevices = [];
            if(response.result){
                saleDevices = response.result.saleDevices;
            }
            if(res){
                const updateDevices = [...saleDevices];
                this.setState({
                    isLoading:false,
                    saleDevices: updateDevices,
                    totalSaleDevices: response.result.totalDevices,
                    currentPage: response.result.currentPage,
                    alert:{
                        timeout:2000,
                        message: res.statusMessage,
                        messageType:"SUCCESS",
                        isVisible:true 
                    }
                });
            }else{
                const updateDevices = [...this.state.saleDevices, ...saleDevices];
                this.setState({
                    isLoading:false,
                    saleDevices: updateDevices,
                    totalSaleDevices: response.result.totalDevices,
                    currentPage: response.result.currentPage,
                })
            }
        }).catch(error => {
            if(res){
                throw error;
            }else{
                this.setState({
                    isLoading:false,
                    alert:{
                        timeout:2000,
                        message: error,
                        messageType:"ERROR",
                        isVisible:true
                    }
                })
            }
            
        })
    }

    scrollDownReachLimitHandler = (container) => {
        if((this.state.totalSaleDevices > 0)  &&  (LIMIT_ITEM * this.state.currentPage) < this.state.totalSaleDevices && !this.state.isLoading){
            const data = {
                currentPage: this.state.currentPage + 1,
                limit: LIMIT_ITEM,
                lastItemId: this.state.saleDevices[this.state.saleDevices.length - 1 ].saleId
            }
            this.setState({
                isLoading : true
            })
            this.fetchSaleDevices(data);
        }
    }


    getDeviceList = (DeviceList)=> {
        return (
        <div className="user_list inner_content_list">
            <PerfectScrollbar options={{suppressScrollX:true}} onYReachEnd={this.scrollDownReachLimitHandler}>
                <div className="inner_scroll_div">
                    <div className="row">
                        {DeviceList}
                        {this.state.isLoading? <ContentLoader size={4}  type="USER" />: null}
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
        )
    }
     componentDidMount(){
        const data = {
            limit: LIMIT_ITEM,
            currentPage : this.state.currentPage
        };
        this.fetchSaleDevices(data);
    }
    deleteSaleDeviceHandler = (saleDevice) =>{

        const deviceId = saleDevice.device.deviceId;
        this.setState({
            deleteAlert:true,
            deleteSaleDevice: saleDevice
        });
    }
    deleteFile =()=>{
        this.setState({
            deleteAlert:false,
            isLoading:true
        });
        const data = {
           saleId:this.state.deleteSaleDevice.saleId,
           saleDeviceId:this.state.deleteSaleDevice.saleDeviceId,
           saleSalerId:this.state.deleteSaleDevice.saleSalerId,
           saleUserId:this.state.deleteSaleDevice.saleUserId,
        }
        sendAuthRequest(REQUESTURL.DELETE_SALEDEVICE,data).then(res =>{
            const data = {
                limit: LIMIT_ITEM,
                currentPage : 1
            };
            this.fetchSaleDevices(data, res);          
        }).catch(error => {
            this.setState({
                isLoading:false,
                alert:{
                    timeout:2000,
                    message: error,
                    messageType:"ERROR",
                    isVisible:true 
                }
            })
        });
    }                               
    onCancel =()=>{
        this.setState({
            deleteAlert:false
        })
    }
    onAlertClose = () => {
        this.setState({
            alert:{
               timeout:2000,
               message:"",
               messageType:"",
               isVisible:false
            }
        });
   }
    render(){
        let DeviceList = null;
        if(this.state.saleDevices &&this.state.saleDevices.length>0){
            DeviceList = this.state.saleDevices.map((device)=>{
                return (<SaleDeviceItem key={device.device.deviceId} saleDevice={device} deleteSaleDeviceHandler={this.deleteSaleDeviceHandler}/>)
            });
        }
        
        return (
            <div className="users_page page_wrapper_height">
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onAlertClose}></Alert> : null}
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle= "info"
                    title="Are you sure?"
                    onConfirm={this.deleteFile}
                    onCancel={this.onCancel}
                    btnSize="md"
                    focusCancelBtn
                    show = {this.state.deleteAlert}
                    >
                    You want to delete this device?
                </SweetAlert>
                <div className="title_heading">
                    <h2>Manage Sale Devices</h2>
                    <div className="right_part ml-auto">
                      <button className="btn btn btn-green mr-2 d-inline-block d-lg-none" onClick={this.filterHandler}> <i className="fas fa-filter"></i> </button>
                      {/* <button className="btn btn-blue"><i className="fas fa-plus mr-1"></i> New User</button> */}
                      
                      <Link to={"/dashboard/sale-devices/add-saledevice"} className="btn btn-blue"><i className="fas fa-plus mr-1"></i> New Sale Device</Link>
                    </div>
                </div>
                <div className="user_list_sec inner_content_wrapper">
                    {
                        this.state.displayFilter ? (
                        <div className="filter_sidebar">    
                            <SaleDeviceFilter applyFilter = {this.applyFilterHandler} clearAllHandler ={this.clearAllHandler}/>
                        </div>) :
                        null
                    }
                    {
                        
                        window.innerWidth > 991 ? this.getDeviceList(DeviceList) : (                        
                        !this.state.displayFilter && window.innerWidth <=991 ? this.getDeviceList(DeviceList) : null)
                    }
                    
                </div>
            </div>
        );
    }
}
export default ManageSaleDevice;