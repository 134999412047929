import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './SideDrawer.css';
import getRoutes from '../../Routes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {getLoginType} from '../../util/Auth';

const SideDrawer = (props) =>{
    const type = getLoginType();
    const links = getRoutes(props.match.url).map(ele=>{
        if(ele.access.includes(type)){
            return (
                <li key={ele.to}>
                    <NavLink to={ele.to} onClick={props.toggleHandler}>
                        <i className={ele.classes}></i>
                        <span>{ele.label}</span>
                    </NavLink>
                </li>
            );
        }else {
            return null;
        }
        
    });

    return (
        <aside className="sidebar">
            <PerfectScrollbar options={{suppressScrollX:true}}>
            <ul>
                {links}
            </ul>
            </PerfectScrollbar>
        </aside>
    );
}
   
export default withRouter(SideDrawer);