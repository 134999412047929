import React from 'react';
import './ContentLoader.css';
import ContentLoader from '../../assets/images/UserContentLoader.svg';
const NotificationContentLoader = () =>{
    return(
        
        <div className="col-12 theme_card_margin">
            <div className="list_theme_card svg_img">
                <img src={ContentLoader} alt="" />
            </div>
        </div>
    );
}

export default NotificationContentLoader;