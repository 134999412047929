import React from 'react';

const CalenderInput = (props, openCalendar, closeCalendar) => {
    return (
        <div className="input_date pt-1" onClick={openCalendar}>
            <input {...props} type="text" className="from-control" readOnly/>
            <i className="fas fa-calendar-alt"></i>
        </div>
    )
}
export default CalenderInput;