import React from 'react';
import L, { tileLayer } from "leaflet";
import { Map, TileLayer, Marker, Popup, LayersControl } from "react-leaflet";
import DrawComponent from './DrawComponent';

class GeoFenceMap extends React.Component{
    
    constructor(props){
        super(props);
    }

    render(){
        let centerPosition = [23.0225,72.5714];
        if(this.props.centerPosition){
            centerPosition=[this.props.centerPosition[1], this.props.centerPosition[0]];
        }
        return (
            <div className="live_track_map_block inner_content_wrapper">
            <Map
              className="livetrack_map"
              center={centerPosition}
              zoom={5}>
                   <TileLayer
                      attribution=""
                      url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                      maxZoom={18}
                      subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    />
                <DrawComponent viewOnly={this.props.viewOnly} selectedGeoFence={this.props.selectedGeoFence} onAreaSelected={this.props.onAreaSelected} onAreaDeleted={this.props.onAreaDeleted}></DrawComponent>
            </Map>
          </div>
        )
    }
}

export default GeoFenceMap;