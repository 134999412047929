import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);


export default class SpeedChartReport extends React.Component{

    constructor(props){
        super(props);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.data !== this.props.data) {
            if(this.chart===undefined){
                this.createNewChart();
            }else{
                this.chart.data = this.props.data;
            }
        }

        if(this.props.export===true){
            this.exportChart();
        }
    }

    componentDidMount(){
        if(null!=this.props.data && this.props.data.length>0){
           this.createNewChart();
        }       
    }
    componentWillUnmount() {
        if (this.chart) {
          this.chart.dispose();
        }
    }

    createNewChart(){
        
        let chart = am4core.create("chartdiv", am4charts.PieChart);
        chart.data = this.props.data;
        chart.tapToActivate = true;
        chart.exporting.filePrefix = this.props.fileName;
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        
        pieSeries.dataFields.category = "speedCategory";
        this.chart = chart;
    }

    exportChart(){
        this.chart.exporting.export("png");
    }

    render(){
        return (
            <div id="chartdiv" style={{ width: "100%", height: "450px" }}></div>
        )
    }
}