import React, {Component} from 'react';
import SpeedMeter from '../SpeedMeter/SpeedMeter';
import './Device.css';
import { convertDateTimeToString } from '../../util/DateUtil';
import {  withRouter } from 'react-router-dom';
import mqttService from '../../util/MqttService';
import { getDeviceDate } from '../../util/DateUtil';
import ViewMoreModal from './ViewMoreModal/ViewMoreModal';
import { isUser } from '../../util/Auth';
import { connect } from 'react-redux';
import { updateSelectedDevice } from '../../redux/playback/playback.action';

class Device extends Component{
    state = {
        show:false
    }
    statusOn = "status on";
    statusOff = "status off";
    constructor(props){
        super(props);
        const { battry, lastDeviceData } = this.props.device;
        if(lastDeviceData!=null){
            let { ac, ignition, speed, inputDate } = lastDeviceData;
            const date = inputDate ? new Date(inputDate) : new Date();
            let wifi = true;
            // console.log( this.props.device.name +" "+ date + " ");
            // console.log("Diff " + ((new Date) - date));
            if(((new Date) - date) > 30000){
                wifi = false;
            }

            this.state = {
                speed: speed,
                date: date,
                ignition: ignition,
                ac: ac,
                battryDisconnected: !battry,
                latLongAvailable:false,
                angle:null,
                wifi: wifi,
                latLongAvailable: lastDeviceData != null
            }
        }else{
            this.state = {
                speed: 0,
                date: null,
                ignition: false,
                ac: false,
                battryDisconnected: !battry,
                wifi:false,
                latLongAvailable:false,
                angle:null
            }
        }
        
        
    }
    viewmoreClickHandler = () => {
        this.setState({
            show: true
        })
    }
    hideclickhandler = () => {
        this.setState({
            show: false
        })
    }
    livetrackClickHandler = () => {
        let url = `${this.props.location.pathname}/${this.props.device.deviceId}/live-track`;
        let deviceId = this.props.device.deviceId;
        this.props.history.push({
            pathname: url,
            state: { 
                device: this.props.device,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                angle: this.state.angle,
            }
        });
    }

    playbackClickHandler = () => {
        this.props.updateSelectedDevice(this.props.device);
        let url = `${this.props.location.pathname}/${this.props.device.deviceId}/play-back`;
        let deviceId = this.props.device.deviceId;
        this.props.history.push({
            pathname: url,
            state: { id: deviceId , device: this.props.device,}
        });
    }

    componentDidMount(){
        if(this.props.device!=undefined){
            this.client = mqttService.getClient((error)=>{
                console.log(error);
            });
            mqttService.subscribe(this.client, `hd/${this.props.device.imei}`, (error)=>{
                console.log(error);
            });
            this.client.on("message", (topic, message, packet) => {
                this.messageReceived(new TextDecoder("utf-8").decode(message))
            });
    
            this.dataTimer = setTimeout(()=>{
                this.setState({
                    wifi:false
                });
            }, 60000);
        }
    }

    messageReceived(message){
        console.log(`message ${message}`);
        // 862843049308554,040721103145,23.165548,N,72.523399,E,289,,1,0,0,1,8.695108,0,0,0.00,0.00,1#

        let isLatLongAvailable = false;
        
        const data = message.split(",");
        let latitude = this.state.latitude;
        let longitude = this.state.longitude;
        let angle = this.state.angle;
        if(data[2] && data[4]){
            isLatLongAvailable = true;
            latitude = data[2];//Latitude 
            longitude = data[4];//Longitude
            angle = data[6];//Angle
        }
        const dateString = data[1];//date
        const date = getDeviceDate(dateString);
        const speed = parseInt(data[7]? data[7].trim(): "0");//speed
        const ignition = parseInt(data[8].trim()) == 1 ? true : false;//ignition

        let acStatus = parseInt(data[9].trim()) == 1 ? true : false; // ac

        //const ac = this.props.device.acReverse && this.ignition ? !acStatus : acStatus;
        const ac = this.props.device.acReverse ? (this.ignition ? !acStatus: false) : acStatus;

        const battryDisconnected = parseInt(data[11].trim()) == 1 ? false : true;//battery Disconnected
 
        clearTimeout(this.dataTimer);
        this.dataTimer = setTimeout(()=>{
            console.log("Data timer to off")
            this.setState({
                wifi:false
            });
        }, 60000);
        let wifi = true;
        if(((new Date) - date) > 60000){
            wifi = false;
        }
        let version = data[data.length - 1];
        version = version.substring(0, version.length - 1);
        this.setState({
            speed: speed,
            date: date,
            ignition:ignition,
            ac:ac,
            battryDisconnected: battryDisconnected,
            wifi: wifi,
            latLongAvailable: isLatLongAvailable,
            latitude: latitude,
            longitude: longitude,
            angle: angle,
            version: version
        });
    }

    componentWillUnmount(){
        mqttService.unsubscribe(this.client, `hd/${this.props.device.imei}`);
        mqttService.closeConnection(this.client);
    }
    
    render(){
        const {name, serialNo, installationDate} = this.props.device;

        const date = this.state.date;
        const deviceCardClass = ["device_card"];
        if(!installationDate){
            deviceCardClass.push("disable");
        }
        let battryIcon = null;
        if(this.state.battryDisconnected){
            battryIcon = (<div className="battery icon" title="Battery Disconnected"><i className="fas fa-car-battery"></i></div>);
        }
        
        let wifiMessage = "Network Disconnected";
        const wifiClasses = [];
        if(this.state.wifi){
            wifiClasses.push("wifi-enable");
            wifiMessage = "Network Connected"
        }else{
            wifiClasses.push("wifi-disable");
        }
        wifiClasses.push("icon");

        let map = null;
        if(!this.state.latLongAvailable){
            map = (<div className="warning icon" title="Location Error"><i className="fas fa-map-marked"></i></div>);
        }

        return (
            <div className="col-xl-3 col-lg-6 col-md-6 col-12 device_card_width">
            <div className={deviceCardClass.join(" ")}>
                <div className="device_head">
                    <p className="device_name">{name ? name : serialNo}</p>
                    <div className="device_right_info">
                        {this.state.version && !isUser() ? (<div className="software_version">SV: {this.state.version}</div>) : null
                        }
                        <div className="device_status_icon">
                            {map}
                            {battryIcon}
                            <div className={wifiClasses.join(" ")} title={wifiMessage}><i className="fas fa-wifi"></i></div>
                        </div>
                    </div>
                </div>
                <div className="speedmeter">
                    <SpeedMeter speed={this.state.speed}></SpeedMeter>
                </div>
                <div className="ac_ignition d-flex justify-content-center align-items-center">
                    <div className="ac device_status">
                        <p><i className="fas fa-snowflake"></i> <span>AC</span></p>
                        <div className={this.state.ac? this.statusOn : this.statusOff }>{this.state.ac ? 'ON' : 'OFF'}</div>
                    </div>
                    <div className="ignition device_status">
                         <p><i className="fas fa-bolt"></i> <span>Ignition</span></p>
                         <div className={this.state.ignition? this.statusOn : this.statusOff }>{this.state.ignition ? 'ON' : 'OFF'}</div>
                    </div>
                </div>
                {null==date? (<p className="date">-- ---,---- --:--</p>): (<p className="date">{convertDateTimeToString(date)}</p>)}
                
                <div className="device_action">
                    <button type="button" className="btn btn-green btn-sm text-transform-none" onClick={() => this.setState({show:true})}>View More</button>
                    <button type="button" className="btn btn-green btn-sm text-transform-none" onClick={this.livetrackClickHandler}>Live Track</button>
                    <button type="button" className="btn btn-green btn-sm text-transform-none" onClick={this.playbackClickHandler}>Play Back</button>
                </div>
            </div>
            {this.state.show && (<ViewMoreModal show={this.state.show} device={this.props.device}
                    onHide={() => this.setState({show:false})}></ViewMoreModal>)
                    }
                
            </div>
        )
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
       updateSelectedDevice: (device) => dispatch(updateSelectedDevice(device)),
    };
};
  
export default connect(null, mapDispatchToProps)(withRouter(Device));