import { UPDATE_SELECTED_DEVICE } from './playback.action';

const INITIAL_STATE = {
    device: null
};
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_SELECTED_DEVICE:
           return {
             ...state, 
             device: {...action.obj}
           };
         default: return state;
    }
};
export default reducer;