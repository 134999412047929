import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAuthenticated, getLoginDetails} from '../../util/Auth';

function ProtectedRoute({ map,Component, ...rest }) {
    const user = getLoginDetails();
    if(isAuthenticated && map.access.indexOf(user.type) > -1 ){
        return <Route {...rest} component={Component}></Route>
    }else{
        return  <Redirect to={{pathname: "/login"}}/>
    }
}
export default ProtectedRoute;