import React from 'react';
import DeviceContentLoader from './DeviceContentLoader';
import UserContentLoader from './UserContentLoader';
import GeofenceContentLoader from './GeofenceContentLoader';
import NotificationContentLoader from './NotificationContentLoader';

const ContentLoader = (props) =>{
    
    const {size, type} = props;

    
    
    const list=[];
    for (let i=1;i<=size; i++) {
        let component = <DeviceContentLoader/>
        if(type==="DEVICE"){
            component = <DeviceContentLoader key={i}/>
        }else if(type === "USER"){
            component = <UserContentLoader key={i}/>
        }else if(type === "GEOFENCE"){
            component = <GeofenceContentLoader key={i}/>
        }
        else if(type === "NOTIFICATION"){
            component = <NotificationContentLoader key={i}/>
        }
        list.push(component);
    }
    return list;
}

export default ContentLoader;