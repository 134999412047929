import { SHOW_HIDE_SHARING_POPUP, UPDATE_DEVICE_LIST, UPDATE_FORM_STATE, UPDATE_LIVE_SHARING_DETAILS, UPDATE_LIVE_SHARING_LINKS, UPDATE_LIVE_SHARING_STATUS, UPDATE_LIVE_TRACK_DATA } from './Livetracksetting.type';

const INITIAL_STATE = {
  formState: {},
  deviceList: [],
  visibleSharingPopup: false,
  liveSharingDetails: undefined,
  liveTrackData: undefined,
  status: "LOADING",
  liveSharingLinks: []
};
const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case UPDATE_DEVICE_LIST:
      return {
        ...state,
        deviceList: action.payload
      };
    case UPDATE_FORM_STATE:
      return {
        ...state,
        formState: action.payload
      };
    case SHOW_HIDE_SHARING_POPUP:
      return {
        ...state,
        visibleSharingPopup: action.payload
      }
    case UPDATE_LIVE_SHARING_DETAILS:
      return {
        ...state,
        liveSharingDetails: action.payload
      }
    case UPDATE_LIVE_TRACK_DATA:
      return {
        ...state,
        liveTrackData: action.payload
      }
    case UPDATE_LIVE_SHARING_STATUS:
        return {
          ...state,
          status: action.payload
        }
    case UPDATE_LIVE_SHARING_LINKS:
      return {
        ...state,
        liveSharingLinks: action.payload
      }
    default: return state;
  }
};
export default reducer;