import { combineReducers } from 'redux';
import deviceReducer from './Device/device.reducer'
import playbackReducer from './playback/playback.reducer';
import LiveTrackSetting from './Livetracksetting/Livetracksetting.reducer';

const rootReducer = combineReducers({
    device: deviceReducer,
    playback: playbackReducer,
    LiveTrackSetting: LiveTrackSetting,
});
export default rootReducer;