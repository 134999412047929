import React, { useEffect } from 'react';
import * as moment from 'moment';
import AmCharts from "@amcharts/amcharts3-react";

export const FuelSensorReport = (props) => {


    //Table
    let rowData = null;
    if (props.data && props.data.length > 0) {

        rowData = props.data.map((value, index, arr) => {
            let colData = [];
            // id
            colData.push((<td key="id">{index + 1}</td>));
            // Start Date <> End Date
            colData.push((<td>{`${value.inputDate}`}</td>));

            colData.push((<td>{`${value.fuel} Liter`}</td>));

            if(value.actualFuelDiff){
                const status = value.status && value.status === "FILLING" ? " Filled" : "Theft";
                colData.push((<td>{`${status} : ${value.actualFuelDiff} Liter`}</td>));
            }else{
                
                colData.push((<td></td>));
            }

            return (
                <tr key={index}>
                    {colData}
                </tr>
            )
        });
    } else {
        rowData = (
            <tr>
                <td className="no_data" colSpan="4"> No Data Found! </td>
            </tr>
        )
    }

    return (
        <div>
            {props.data && props.data.length ? <FuelChart data={props.data}></FuelChart> : null}
            <div className="custom_table">
                <div className="table-responsive">
                    <table className="table table-bordered" id="trip_report">
                        <thead>
                            <tr>
                                <th>ID #</th>
                                <th>Date</th>
                                <th>Liter</th>
                                <th>Event</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowData}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}



function FuelChart({data}){
    const newChangeData = () => {
        return data.map(data=>{
            if(data.actualFuelDiff){
                return {
                    date: moment(data.inputDate).format("DD-MMM HH:mm:ss"),
                    fuel: data.fuel,
                    highlighted: true,
                    status: data.status && data.status === "FILLING" ? " Filled" : "Theft",
                    actualFuelDiff: data.actualFuelDiff,
                    balloonText: getBallonText(data)
                }
            }
            return {
                date: moment(data.inputDate).format("DD-MMM HH:mm:ss"),
                fuel: data.fuel,
                balloonText: getBallonText(data)
            }
        });
    }
    //Chart
    let config = {
        "type": "serial",
        "categoryField": "date",
        "categoryAxis": {
            "gridPosition": "start",
            "labelRotation": 90
        },
        "theme": "light",
        "titles": [{
            "text": "Fuel Report"
          }],
        "marginRight": 80,
        "dataProvider": newChangeData(),
        "valueAxes": [{
            "position": "left",
            "title": "Fuel"
        }],
        "graphs": [{
            "id": "g1",
            "fillAlphas": 0.4,
            "valueField": "fuel",
            "type": "smoothedLine",
            "balloonText": "[[balloonText]]",
            "lineThickness": 2,
        }, {
            "id": "bulletGraph",
            "valueField": "fuel",
            "bullet": "round",
            "bulletSize": 15,
            "lineAlpha": 0,
            "fillAlphas": 0,
            "visibleInLegend": false, // To hide in the legend
            "dataProvider": newChangeData().filter(item => item.highlighted === true),
            "bulletColor": "#007BFF", // Set the bullet color to blue
            "bulletBorderColor": "#BFE1FF", 
            "bulletBorderThickness": 4, // Set the bullet border thickness
            "bulletBorderAlpha": 1,
            "showBalloon": false
        }],
        "chartScrollbar": {
            "graph": "g1",
            "scrollbarHeight": 80,
            "backgroundAlpha": 0,
            "selectedBackgroundAlpha": 0.1,
            "selectedBackgroundColor": "#888888",
            "graphFillAlpha": 0,
            "graphLineAlpha": 0.5,
            "selectedGraphFillAlpha": 0,
            "selectedGraphLineAlpha": 1,
            "autoGridCount": true,
            "color": "#D4D4D4",
            "fontSize":0
        },
        "chartCursor": {
            "categoryBalloonDateFormat": "JJ:NN, DD MMMM",
            "cursorPosition": "mouse"
        },
        
        "export": {
            "enabled": true,
            "dateFormat": "YYYY-MM-DD HH:NN:SS"
        }
    };

    

    useEffect(()=>{
        const chart = AmCharts.makeChart("chartdiv", config);
    }, [])
    
    return (
        <div id="chartdiv" style={{ width: "100%", height: "450px" }}></div>
    )

    function getBallonText(item){
        let html = "<div style='margin:5px; font-size:16px; text-align: left;'>";
        const date = `Date:<b>${moment(item.inputDate).format("DD-MMM HH:mm:ss")}</b><br/>`
        const fuel = `Fuel:<b>${item.fuel} Liter</b><br/>`;
        html = html + date + fuel;
        if(item.actualFuelDiff){
            const type = `Type:<b>${item.status && item.status === "FILLING" ? " Filled" : "Theft"}</b><br/>` ;
            const difference = `Fuel ${item.status && item.status === "FILLING" ? " Filled" : "Theft"}:<b>${item.actualFuelDiff} Liter</b><br/>`;
            html = html + type + difference;
        }
        let endHTML = "</div>";
        html = html + endHTML;
        return html;
    }
}