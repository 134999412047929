import React from 'react';
import { convertDateTimeToString, addDays } from '../../util/DateUtil';
import { isUser, isAdmin} from '../../util/Auth';
import { Link } from 'react-router-dom';
import './NotificationItem.css';
const NotificationItem = (props) => {
    return (
        <div className=" col-12 theme_card_margin">
            <div className="list_theme_card notification_card">
                <div className="list_heading d-flex align-items-center">
                    <p>{props.notification.title}</p>
                    <p className="date d-none d-sm-block">{convertDateTimeToString(new Date(props.notification.inputDate))} </p>
                </div>
                <div className="details_action d-flex align-items-end">
                    <div className="details">
                        <p className="desc">{props.notification.message}</p>
                        <p className="date d-block d-sm-none">{convertDateTimeToString(new Date(props.notification.inputDate))} </p>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default NotificationItem;