import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeviceFilter  from '../Filter/DeviceFilter';
import DeviceItem from '../../../component/DeviceItem/DeviceItem';
import { isAdmin } from '../../../util/Auth';
import { sendAuthRequest } from '../../../util/API';
import Alert from '../../../component/Alert/Alert';
import {REQUESTURL} from '../../../constant/REQUEST-URL';
import SweetAlert from 'react-bootstrap-sweetalert';
import ContentLoader from '../../../component/ContentLoader/ContentLoader';
const LIMIT_PAGE = 6;
class ManageDevice extends Component{

    state= {
        devices:[],
        displayFilter: window.innerWidth<=991 ? false : true,
        isLoading: true,
        alert:{
            timeout:2000,
            message:"",
            messageType:"",
            isVisible:false
        },
        deleteAlert: false,
        deleteDeviceId: "",
        currentPage: 1,
        totalDevices:0,
        filterApplied:false,
        filterData:{}
    }
    filterHandler = () => {
        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: !prevState.displayFilter
                }
            })
        } 
    }
    applyFilterHandler = (filterData) => {
        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: false
                }
            }, ()=>{
                this.sendFilterRequest(filterData);
            })
        }else{
            this.sendFilterRequest(filterData);
        }
    }

    sendFilterRequest = (filterData)=>{
        const data = {
            ...filterData,
            limit:LIMIT_PAGE,
            currentPage: 1,
            filterApplied:true
        }
        this.setState({
            isLoading:true,
            devices:[],
            currentPage: 1,
            totalDevices:0,
            filterApplied:true,
            filterData:{
                ...filterData
            }
        })
        this.fetchDevices(data);
    }
    onCloseAlert = ()=>{
        const alert = {
            ...this.state.alert,
            isVisible:false
        }
        this.setState({
            alert: alert
        })
    }


    fetchDevices = (data, res) =>{
        sendAuthRequest(REQUESTURL.DEVICE_LIST, data).then(response => {
            let devices = []
            if(response){
                devices = response.result.devices;
            }
            if(res) {
                const updateDevices = [...devices];
                this.setState({
                    isLoading:false,
                    devices: updateDevices,
                    currentPage: response.result.currentPage,
                    totalDevices : response.result.totalDevices,
                    alert:{
                        timeout:2000,
                        message: res.statusMessage,
                        messageType:"SUCCESS",
                        isVisible:true 
                    }
                });
            }else{
                const updateDevices = [...this.state.devices, ...devices];
                this.setState({
                    isLoading:false,
                    devices: updateDevices,
                    currentPage: response.result.currentPage,
                    totalDevices : response.result.totalDevices
                })
            }
        }).catch(error => {
            if(res){
                throw error;
            }else{
                this.setState({
                    isLoading:false,
                    alert:{
                        timeout:2000,
                        message: error,
                        messageType:"ERROR",
                        isVisible:true
                    }
                })
            }
        })
    }

    componentDidMount(){
        const data = {
            limit:LIMIT_PAGE,
            currentPage: this.state.currentPage
        }
        this.fetchDevices(data);
    }

    scrollDownReachLimitHandler = (container) => {
        if((this.state.totalDevices > 0) && (LIMIT_PAGE * this.state.currentPage) < this.state.totalDevices && !this.state.isLoading){
            const data = {
                currentPage: this.state.currentPage + 1,
                limit: LIMIT_PAGE,
                lastItemId: this.state.devices[this.state.devices.length - 1 ].deviceId,
                filterApplied:this.state.filterApplied,
                ...this.state.filterData
            }
            this.setState({
                isLoading : true
            })
            this.fetchDevices(data);
        }
    }

    getDeviceList = (DeviceList)=> {
        return (
        <div className="user_list inner_content_list">
            <PerfectScrollbar options={{suppressScrollX:true}}  onYReachEnd={this.scrollDownReachLimitHandler}>
                <div className="inner_scroll_div">
                    <div className="row">
                        {DeviceList}
                        {this.state.isLoading? <ContentLoader size={4}  type="USER" />: null}
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
        )
    }
    deleteDeviceHandler = (device) =>{

        const deviceId = device.deviceId;
        this.setState({
            deleteAlert:true,
            deleteDeviceId: deviceId
        });
    }
    deleteFile =()=>{
        this.setState({
            deleteAlert:false,
            isLoading:true
        });
        const data = {
            deviceId:this.state.deleteDeviceId
        }
      
        // console.log(data);
        sendAuthRequest(REQUESTURL.DELETE_DEVICE,data).then(res =>{
            const data = {
                currentPage: 1,
                limit: LIMIT_PAGE
            }
            this.fetchDevices(data, res); 
        }).catch(error => {
            this.setState({
                isLoading:false,
                alert:{
                    timeout:2000,
                    message: error,
                    messageType:"ERROR",
                    isVisible:true 
                }
            })
        });
    }
    onCancel =()=>{
        this.setState({
            deleteAlert:false
        })
    }

    clearAllHandler = () =>{
        const data = {
            limit:LIMIT_PAGE,
            currentPage: 1,
            filterApplied:false
        }
        console.log(data);
        this.setState({
            isLoading:true,
            devices:[],
            currentPage: 1,
            totalDevices:0,
            filterApplied:false,
            filterData:{}
        });
        this.fetchDevices(data);
    }
    render(){
        const DeviceList = this.state.devices.map((device)=>{
            return (<DeviceItem device={device} key={device.deviceId} deleteDeviceHandler={this.deleteDeviceHandler}/>)
        });
        return (
            <div className="device_page page_wrapper_height">
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle= "info"
                    title="Are you sure?"
                    onConfirm={this.deleteFile}
                    onCancel={this.onCancel}
                    btnSize="md"
                    focusCancelBtn
                    show = {this.state.deleteAlert}
                    >
                    You want to delete this device?
                </SweetAlert>
                <div className="title_heading">
                    <h2>Manage Devices</h2>
                    <div className="right_part ml-auto">
                      <button className="btn btn btn-green mr-2 d-inline-block d-lg-none" onClick={this.filterHandler}> <i className="fas fa-filter"></i> </button>
                      {/* <button className="btn btn-blue"><i className="fas fa-plus mr-1"></i> New User</button> */}
                      
                      {isAdmin()? <Link to={"/dashboard/devices/add-device"} className="btn btn-blue"><i className="fas fa-plus mr-1"></i> New Device</Link> : null }
                    </div>
                </div>
                <div className="user_list_sec inner_content_wrapper">
                    {
                        this.state.displayFilter ? (
                        <div className="filter_sidebar">    
                            <DeviceFilter applyFilter = {this.applyFilterHandler} clearAllHandler={this.clearAllHandler}/>
                        </div>) :
                        null
                    }
                    {
                        
                        window.innerWidth > 991 ? this.getDeviceList(DeviceList) : (                        
                        !this.state.displayFilter && window.innerWidth <=991 ? this.getDeviceList(DeviceList) : null)
                    }
                    
                </div>
            </div>
        ); 
    }
}
export default ManageDevice;