export const validateEmail = (email) =>{
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
}

export const validateCpassword = (cpassword,password) =>{
    return cpassword === password;
}

export const validateNumber = (number) =>{
    return number.length >=10 && number.length <= 16 ;
}