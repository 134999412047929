import React, {Component} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {sendAuthRequest} from '../../../util/API';
import { REQUESTURL } from '../../../constant/REQUEST-URL';
import Loader from '../../../component/Loader/Loader';
import Alert from '../../../component/Alert/Alert';
class AddDevice extends Component {

    state = {
       form:{
        serialNo:{
            value:"",
            message:undefined,
            touched: false,
            valid: false,
            label: "Serial No."
        },
        imeiNo:{
            value:"",
            message:undefined,
            touched: false,
            valid: false,
            label: "IMEI No."
        },
       },
       isLoading: false,
        alert:{
            timeout:2000,
            message:"",
            messageType:"",
            isVisible:false
        }
        
    }
    
    checkValidation = (updatedstate,type) =>{
        updatedstate[type].touched = true;
            if(updatedstate[type].value === ""){
                updatedstate[type].valid = false;
                updatedstate[type].message = "Please enter " +  updatedstate[type].label + ".";
            }else{
                updatedstate[type].message = "";
                updatedstate[type].valid=true;
            }
    }
    changeHandler = (event, type) =>{
        const updatedstate = {...this.state.form};
        updatedstate[type].value = event.target.value;
        this.checkValidation(updatedstate,type);
        this.setState({
            ...updatedstate
        })
    }
    onCloseAlert = ()=>{
        const alert = {
            ...this.state.alert,
            isVisible:false
        }
        this.setState({
            alert: alert
        })
    }
    addNewDevice = () => {
        const data = {
            serialNo: this.state.form.serialNo.value,
            imei: this.state.form.imeiNo.value
        }
        console.log(data);
        this.setState({
            isLoading:true
        })
        sendAuthRequest(REQUESTURL.ADD_DEVICE, data).then(response => {
            console.log(response);
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"SUCCESS",
                    timeout:2000,
                    message: response.statusMessage,
                    isVisible:true
                },
                form:{
                    serialNo:{
                        value:"",
                        message:undefined,
                        touched: false,
                        valid: false,
                        label: "Serial No."
                    },
                    imeiNo:{
                        value:"",
                        message:undefined,
                        touched: false,
                        valid: false,
                        label: "IMEI No."
                    },
                   },
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"ERROR",
                    timeout:2000,
                    message: error,
                    isVisible:true
                }
            });
        });
    }
    submitHandler = () => {
        console.log(this.state)
        let isValidType = true;
        const updatedstate = { ...this.state.form };
        for (let type in updatedstate) {
            if (updatedstate[type].valid === false) {
                isValidType = false;
                break;
            }
        }
        if(isValidType){
           this.addNewDevice();
        }else{
            const updatedstate = {...this.state.form};
            for(let type in updatedstate){
                this.checkValidation(updatedstate,type);
            }
            this.setState({
                ...updatedstate
            });
        }
    }
    cancelHandler = () => {
       this.props.history.goBack();
    }
    render(){
       
        const isImeiNoInvalid = this.state.form.imeiNo.message !== "" && this.state.form.imeiNo.touched && !this.state.form.imeiNo.valid;
        const isSerialNoInvalid = this.state.form.serialNo.message !== "" && this.state.form.serialNo.touched && !this.state.form.serialNo.valid;
        return(
        <div className="add_device page_wrapper_height">
            <div className="title_heading">
                <h2>Add New Device</h2>
            </div>
            <div className="custom_form inner_content_wrapper">
                  {this.state.isLoading? <Loader></Loader> : null}
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <div className="inner_scroll_div">
                        <form>
                            

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label className="mb-2 w-100">Serial No. <span className="red_text">*</span></label>
                                        <input type="number" value={this.state.form.serialNo.value} className="form-control" onChange={(event)=>{this.changeHandler(event, "serialNo")}} />
                                        {isSerialNoInvalid ? <p className="errorMsg">{this.state.form.serialNo.message}</p>: null}
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 w-100">IMEI No.<span className="red_text">*</span></label>
                                        <input type="number" value={this.state.form.imeiNo.value} className="form-control" onChange={(event)=>{this.changeHandler(event, "imeiNo")}}/>
                                        {isImeiNoInvalid ? <p className="errorMsg">{this.state.form.imeiNo.message}</p>: null}
                                    </div>
                                </div>
                            </div>
                           
                         
                            <div className="submit_btn">
                                <button className="btn btn-blue mr-2" onClick={this.submitHandler} type="button">Save</button>
                                <button className="btn btn-green mr-2" type="button">Clear</button>
                                <button className="btn btn-gray mr-2" onClick={this.cancelHandler} type="button">Cancel</button>
                            </div>
                        </form>
                    </div>
                </PerfectScrollbar>

            </div>
        </div>
        );
    }
}


export default AddDevice;