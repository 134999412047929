import React from 'react';
import { convertDateTimeToString } from '../../util/DateUtil';
import { Link } from 'react-router-dom';

const UserItem = (props) => {


   const {username,email,inputDate} = props.user;

    return (
        <div className="col-xl-6 col-lg-12 col-md-12 col-12 theme_card_margin">
            <div className="list_theme_card">
                <div className="list_heading d-flex align-items-center">
                    <p>{username}</p>
                </div>
                <div className="details_action d-flex align-items-end">
                    <div className="details">
                        <p>{email}</p>
                        <p>{convertDateTimeToString(new Date(inputDate))}</p>
                    </div>
                    <div className="action ml-auto">
                        <Link to={{
                                    pathname: "/dashboard/users/edit-user",
                                    state: props.user
                                }} className="edit_icon_btn icon_btn mr-2"><i className="fas fa-pencil-alt"></i></Link>
                        {/* <a href="/" className="edit_icon_btn icon_btn mr-2"><i className="fas fa-pencil-alt"></i></a> */}
                        <button type="button" className="delete_icon_btn icon_btn" onClick={() => props.deleteUserHandler(props.user)}><i className="fas fa-trash-alt"></i></button>
                    </div>  
                </div>
            </div>
        </div>
    );

}

export default UserItem;