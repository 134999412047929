import React from 'react';
import { connect } from 'react-redux';

const FleetDeviceItem = (props) =>{
  let latitude = undefined;
  let longitude = undefined;
  if(props.device.lastDeviceData!=undefined){
    let { location } = props.device.lastDeviceData;
    latitude = location.coordinates[1];
    longitude = location.coordinates[0];
  }
  

    const deviceItemClickHandler = () =>{
        if(props.message!=undefined){
          const data = props.message.split(",");
          const latitude = data[2]; //Latitude
          const longitude = data[4]; //Longitude
          props.setCenterPosition([latitude,longitude]);
        }else{
          if(latitude!=undefined && longitude!=undefined){
            props.setCenterPosition([latitude,longitude]);
          }
        }
    }
    return (
        <li className={(props.message!=undefined || (latitude!=undefined && longitude!=undefined))?"active":""} onClick={deviceItemClickHandler}>{props.device.name}</li>
    );
}

export default connect((state, ownProps)=>{
  return {
    message: state.device[ownProps.device.imei]
  };
})(FleetDeviceItem);