
import React from 'react';
import FuelChartReport from './FuelChartReport';
import SpeedChartReport  from './SpeedChartReport';
import { FuelSensorReport } from '../fuel-sensor-report';

export const ChartReport = (props) =>{

    let chart = null;

    if(props.reportType==="SPEED_REPORT" && props.data!=null && props.data.length>0){
        chart = (<SpeedChartReport reportType={props.reportType} data={props.data} export={props.export} fileName={props.fileName}></SpeedChartReport>);
    }else if(props.reportType==="FUEL_REPORT" && props.data!=null && props.data.length>0){
        chart = (<FuelChartReport reportType={props.reportType} data={props.data} export={props.export} fileName={props.fileName}></FuelChartReport>);
    }else if(props.reportType === "FUEL_SENSOR_REPORT") {
        chart = <FuelSensorReport reportType={props.reportType} data={props.data}></FuelSensorReport>
    }
    
    return (
        <div>
            {chart}
        </div>
    )   
}