import React, { Component } from 'react';
import Datetime from "react-datetime";
import CalenderInput from "../../component/CalenderInput/CalenderInput";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { sendAuthRequest } from '../../util/API';
import { REQUESTURL } from '../../constant/REQUEST-URL';
import Suggestion from '../../component/Suggestion/Suggestion';
import {
    convertDateToString,
    getNewDateWithZeroTime,
    getDifferenceDays,
  } from "../../util/DateUtil";
import './Reports.css';
import { ReportTable } from '../../component/report/report-table';
import { ChartReport } from '../../component/report/chart/ChartReport';
import { ReportTypes, isTable, getReportApiEndPoint, isFuelReport } from '../../util/ReportUtil';
import Loader from '../../component/Loader/Loader';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import Alert from '../../component/Alert/Alert';

const REPORT_TYPE = ReportTypes;
class Reports extends Component {
    state = {
        devicesSuggestion:[],
        alert: {
            timeout: 2000,
            message: "",
            messageType: "",
            isVisible: false
        },
        form: {
          startDate: {
            value: getNewDateWithZeroTime(),
            message: undefined,
            touched: false,
            valid: true,
            isValidationRequire: true,
            label: "Start Date",
          },
          endDate: {
            value: new Date(),
            message: undefined,
            touched: false,
            valid: true,
            isValidationRequire: true,
            label: "End Date",
          },
          device:{
            value:"",
            message:undefined,
            touched: false,
            valid: false,
            label: "Device"
          },
          reportType:{
            value:"",
            message:undefined,
            touched: false,
            valid: false,
            label: "Report Type"
          },
          overSpeed: {
            value: 70,
            message: undefined,
            touched: false,
            valid: false,
            isValidationRequire: false,
            label: "Over Speed",
          },
          stopMin: {
            value: 5,
            message: undefined,
            touched: false,
            valid: true,
            isValidationRequire: false,
            label: "Stop Minute",
          },
          min: {
            value: 5,
            message: undefined,
            touched: false,
            valid: true,
            isValidationRequire: false,
            label: "Minute",
          },
        },
        data:[],
        export:false
      };
      fetchSuggestion = async () =>{
        try{
            const deviceSuggestionResponse = await sendAuthRequest(REQUESTURL.DEVICE_SUGGESTION);
            let deviceSuggestion = [];
            if(deviceSuggestionResponse){
                deviceSuggestion = deviceSuggestionResponse.result.map(device=>{
                    return { value: device.deviceId, label: device.name ? device.name: device.serialNo}
                });
            }
            
            this.setState({
                devicesSuggestion:deviceSuggestion
            });

        }catch (error){
            console.log(error);
        }
    }

    componentDidMount(){
        this.fetchSuggestion();
    }
    startDateChangeHandler = (moment) => {
        const updatedstate = { ...this.state.form };
        updatedstate["startDate"].value = convertDateToString(moment.toDate());
        this.checkValidation(updatedstate, 'startDate');
        this.setState({
            form: updatedstate,
        });
    };
    endDateChangeHandler = (moment) => {
        const updatedstate = { ...this.state.form };
        updatedstate["endDate"].value = convertDateToString(moment.toDate());
        this.checkValidation(updatedstate, 'endDate');
        this.setState({
            form: updatedstate,
        });
    };
    deviceSelectHandleChange =(selectedOption,type) => {
        let updatedMultiSelect = {...this.state.form}; 
        updatedMultiSelect[type].value = selectedOption;
        this.checkValidation(updatedMultiSelect,type);
        this.setState(
            {...updatedMultiSelect}
        );
    }
    checkValidation = (updatedstate,type) =>{
        updatedstate[type].touched = true;
        if(updatedstate[type].value === "" || updatedstate[type].value.length === 0){
            updatedstate[type].valid = false;
            updatedstate[type].message = "Please select " + updatedstate[type].label + ".";
        }else{
            updatedstate[type].message = "";
            updatedstate[type].valid=true;
        }
    }
    reportChangeHandler =(selectedOption,type) => {
        let updatedMultiSelect = {...this.state.form}; 
        updatedMultiSelect[type].value = selectedOption;
        this.checkValidation(updatedMultiSelect,type);

        

        this.setState(
            {
                ...updatedMultiSelect,
                data:[],
                export:false
            },
        );
    }
    doSubmit(){
        const startDate = new Date(this.state.form.startDate.value);
        const endDate = new Date(this.state.form.endDate.value);
        if(getDifferenceDays(startDate, endDate) <= 7){
            let data = {
                deviceId: this.state.form.device.value.value,
                startDate: convertDateToString(new Date(this.state.form.startDate.value)),
                endDate: convertDateToString(new Date(this.state.form.endDate.value)),
                type: this.state.form.reportType.value.value,
                stopMin: this.state.form.stopMin.value,
                minute: this.state.form.min.value
            };
            this.setState({
                loading:true
            });
            this.callApi(data);
            console.log("Call the Web API", data);
        }else{
            const alert = {
                ...this.state.alert,
                message: "Please select date range between 7 days",
                messageType: "ERROR",
                isVisible: true
            };
            this.setState({
                alert: alert
            })
        }
    }

    onCloseAlert = () => {
        const alert = {
            ...this.state.alert,
            isVisible: false
        }
        this.setState({
            alert: alert
        })
    }

    callApi(data){
        let url=getReportApiEndPoint(data.type);
        if(url!=""){
            sendAuthRequest(url, data)
            .then((response) => {
              if(response.status==1){
                  let data = response.result;
                  this.setState({
                      loading:false,
                      data:data
                  });
              }
            })
            .catch((error) => {
              this.setState({
                loading:false
              });
              console.log(error);
            });
        }
    }




    submitHandler = () => {
       
        if(this.state.form.reportType.valid && this.state.form.device.valid && this.state.startDate.valid && this.state.endDate.valid && this.checkReportTypeValidation()){
           this.doSubmit();
        }else{
            const updatedstate = {...this.state.form};
            for(let type in updatedstate){
                this.checkValidation(updatedstate,type);
            }
            this.setState({
                ...updatedstate
            });
        }
    }

    checkReportTypeValidation(){
        
         if(this.state.form.reportType.value.value === "STOP_REPORT"){
            return this.state.form.stopMin.valid;
        }

        return true;
    }


    stopMinChangeHandler = (event) =>{
        let updatedMultiSelect = {...this.state.form}; 
        let type= event.target.name;
        updatedMultiSelect[type].value = event.target.value;
        this.checkValidation(updatedMultiSelect,type);
        this.setState(
            {...updatedMultiSelect}
        );
    }

    exportChartHandler = (event) =>{
        this.setState({
            export:true
        })
    }

    
render() {
    const isDeviceInvalid = this.state.form.device.message !== "" && this.state.form.device.touched && !this.state.form.device.valid;
    const isReportInvalid = this.state.form.reportType.message !== "" && this.state.form.reportType.touched && !this.state.form.reportType.valid;
    const isStopMinInvalid = this.state.form.stopMin.message !== "" && this.state.form.stopMin.touched && !this.state.form.stopMin.valid;
    return (
            <div className="reports_page page_wrapper_height">
                {this.state.loading ? <Loader></Loader> : null} 
                <div className="title_heading">
                    <h2>Reports</h2>
                    <div className="right_part ml-auto">
                        {(this.state.data != null && this.state.data.length>0) && isTable(this.state.form.reportType.value.value) && (
                            <ReactHTMLTableToExcel
                                className="btn btn-blue mr-2"
                                table={this.state.reportType.value.value.toLowerCase()}
                                filename={`${this.state.form.device.value.label} - ${this.state.reportType.value.label} - (${convertDateToString(new Date(this.state.form.startDate.value))} - ${convertDateToString(new Date(this.state.form.endDate.value))})`}
                                sheet={this.state.reportType.value.label}
                                buttonText="Export"/>

                        )}
                        {
                            (this.state.data != null && this.state.data.length>0) 
                            && !isTable(this.state.form.reportType.value.value)
                            && !isFuelReport(this.state.form.reportType.value.value)
                            && (<button className="btn btn-blue mr-2" type="button" onClick={this.exportChartHandler} >Export</button>)
                        }
                        <button className="btn btn-blue" type="button" onClick={this.submitHandler} >Submit</button>
                    </div>
                </div>
                
                <div className="custom_form inner_content_wrapper">
                {this.state.alert.isVisible ? <Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                {/* {this.state.isLoading? <Loader></Loader> : null}
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null} */}
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                            <div className="inner_scroll_div">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Report type</label>
                                                    <Suggestion
                                                        handleChange={this.reportChangeHandler}
                                                        options={REPORT_TYPE}
                                                        isSearchable={true}
                                                        type="reportType"
                                                    />
                                                    {isReportInvalid ? <p className="errorMsg">{this.state.form.reportType.message}</p>: null}
                                                </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100"> Select Device </label>
                                                    <Suggestion   handleChange={this.deviceSelectHandleChange}
                                                options={this.state.devicesSuggestion} isMulti={false} type="device"  value={this.state.form.device.value}></Suggestion>
                                                 {isDeviceInvalid ? <p className="errorMsg">{this.state.form.device.message}</p>: null}
                                                </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Start Date</label>
                                                    <Datetime
                                                        onChange={this.startDateChangeHandler}
                                                        dateFormat={"DD MMM, YYYY"}
                                                        closeOnSelect={true}
                                                        timeFormat={"HH:mm:ss"}
                                                        inputProps={{ placeholder: "Start Date" }}
                                                        renderInput={CalenderInput}
                                                        value={new Date(this.state.form.startDate.value)}
                                                    />
                                                </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">End Date</label>
                                                    <Datetime
                                                        onChange={this.endDateChangeHandler}
                                                        dateFormat={"DD MMM, YYYY"}
                                                        closeOnSelect={true}
                                                        timeFormat={"HH:mm:ss"}
                                                        inputProps={{ placeholder: "End Date" }}
                                                        renderInput={CalenderInput}
                                                        value={new Date(this.state.form.endDate.value)}
                                                    />
                                                </div>
                                        </div>
                                        {this.state.form.reportType.value.value === "STOP_REPORT" && (
                                        <div className="col-md-6 col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Stop Minute</label>
                                                    <input 
                                                        className="from-control" 
                                                        type="number" 
                                                        name="stopMin"
                                                        value={this.state.form.stopMin.value}
                                                        onChange={this.stopMinChangeHandler}
                                                    />
                                                    {isStopMinInvalid ? <p className="errorMsg">{this.state.form.stopMin.message}</p>: null}
                                                </div>
                                        </div>
                                        )} 
                                        {this.state.form.reportType.value.value === "FUEL_SENSOR_REPORT" && (
                                        <div className="col-md-6 col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Minute</label>
                                                    <input 
                                                        className="from-control" 
                                                        type="number" 
                                                        name='min'
                                                        min="1"
                                                        max={60}
                                                        value={this.state.form.min.value}
                                                        onChange={this.stopMinChangeHandler}
                                                    />
                                                </div>
                                        </div>
                                        )} 
                                    </div>
                                </form>
                                {
                                    (this.state.form.reportType.value.value!=null || this.state.form.reportType.value.value!='') 
                                    && isTable(this.state.form.reportType.value.value) 
                                    && (<ReportTable reportType={this.state.form.reportType.value.value} data={this.state.data}></ReportTable>)
                                }
                                {
                                    (this.state.form.reportType.value.value!=null || this.state.form.reportType.value.value!='') 
                                    && !isTable(this.state.form.reportType.value.value)
                                    && (
                                        <ChartReport 
                                            reportType={this.state.form.reportType.value.value} 
                                            data={this.state.data} 
                                            export={this.state.export}
                                            fileName={`${this.state.form.device.value.label} - ${this.state.reportType.value.label} - (${convertDateToString(new Date(this.state.form.startDate.value))} - ${convertDateToString(new Date(this.state.form.endDate.value))})`}
                                        ></ChartReport>
                                    )
                                }
                                
                            </div>
                        </PerfectScrollbar>
                </div>
            </div>
    );
}
}
export default Reports;