import React from 'react';
import { getColumn } from '../../util/ReportUtil';

export const IgnitionReport = (props) =>{

    const columns = getColumn(props.reportType);

    const reportKeys = ["startDate","stopDate","totalStopDuration"];
    
    let col = null;

    if(columns.length>0){
        col = columns.map(column=>{
            return (
                <th key={column.value} className={column.class}>{column.label}</th>
            )
        })
    }
    let rowData = null; 
    if(props.data && props.data.length>0){
        const reportData = props.data;
        reportData.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a[reportKeys[0]]) - new Date(b[reportKeys[0]]);
        });

        rowData = reportData.map((value, index, arr)=>{
            let colData = [];
            // id
            colData.push((<td key="id">{index+1}</td>));
            // Start Date <> End Date
            colData.push((<td key={reportKeys[0]}>{ `${value[reportKeys[0]]}` }</td>));
            colData.push((<td key={reportKeys[1]}>{ `${value[reportKeys[1]]}` }</td>));
            // Total Stop Duration
            let timeMinute = +value[reportKeys[2]];
            let totalHours = 0;
            while(timeMinute>=60){
                totalHours = totalHours + 1;
                timeMinute = timeMinute - 60;
            }
            colData.push((<td key={reportKeys[2]} >{totalHours + " Hours " + timeMinute + " Min"}</td>));
        
            return (
                <tr key={index}>
                    {colData}    
                </tr>
            )
        });
    }else{
        rowData= (
            <tr>
                <td className="no_data" colSpan="4"> No Data Found! </td>
            </tr>
        )
    }

    return (                                        
        <table className="table table-bordered" id="ignition_report">
            <thead>
                <tr>
                    {col}
                </tr>
            </thead>
            <tbody>
                
                    {rowData}
                    
            </tbody>
        </table>
    )

}