import React, {useState, useEffect} from 'react';

const Radio = (props) => {
   
    const [activeValue, setActiveValue] = useState({
        value: props.selectedValue
    });

    useEffect(() => {
        // Update the document title using the browser API
        if(props.onSelection && activeValue.value){
            props.onSelection(activeValue.value);
        }
    },[activeValue]);

    const clickHandler = (item) =>{
        setActiveValue({
                value:item
        }); 
    }

    const list = props.list.map((item)=>{
            if(activeValue.value === item){
                return <div className="status active" key={item} onClick={()=>{clickHandler(item)}}>{item}</div>
            }else{
                return <div className="status" key={item} onClick={()=>{clickHandler(item)}}>{item}</div>
            }
    });

    return list

}

export default Radio;