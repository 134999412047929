import React from 'react';
import {  withRouter } from 'react-router-dom';
const GeofenceItem = (props) => {
        
    return (
        <div className="col-xl-4 col-lg-4 col-md-6 col-12 theme_card_margin">
            <div className="list_theme_card">
                <div className="list_heading d-flex align-items-center">
                    <p>{props.geofence.title}</p>
                    {/* <div className="right_div status_badge ml-auto active">OUT</div> */}
                </div>
                <div className="details_action d-flex align-items-end">
                    <div className="details">
                        <p><strong>Devices:</strong></p>
                        <p className="geo_device_name">{props.geofence.deviceNames.join(", ")}</p>
                    </div>
                    <div className="action ml-auto">
                        <button type="button" className="view_icon_btn icon_btn mr-2" onClick={(event)=>{props.viewClickHandler(props.geofence);}}><i className="fas fa-eye"></i></button>
                        <button type="button" className="delete_icon_btn icon_btn" 
                        onClick={(event)=>{props.deleteGeofenceHandler(props.geofence);}}><i className="fas fa-trash-alt"></i></button>
                    </div>  
                </div>
            </div>
        </div>
    );

}

export default withRouter(GeofenceItem);