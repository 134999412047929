import React from "react";
import { Map, TileLayer, LayersControl, Polyline } from "react-leaflet";
import mqttService from '../../../util/MqttService';
import './LiveTrack.css';
import MarkerPopup from "../../../component/MarkerPopup/MarkerPopup";
import { getDeviceDate, convertDateTimeToString } from '../../../util/DateUtil';
import { sendAuthRequest } from "../../../util/API";
import { REQUESTURL } from "../../../constant/REQUEST-URL";
import { connect } from "react-redux";
import { showHideSharingPopup } from "../../../redux/Livetracksetting/Livetracksetting.actions";
import LiveSharingModal from "../../LiveTrackSetting/LiveSharingModal/LiveSharingModal";
import { Connect } from "react-redux";
class LiveTrack extends React.Component {

  redOptions = {color: '#0273CF',opacity:1.0};

  constructor(props) {
    super(props);
    let {latitude, longitude, angle, device} = props.location.state;

    if(device.lastDeviceData){
      let { location, courseAngle, inputDate, speed } = device.lastDeviceData;
      latitude = location.coordinates[1];
      longitude = location.coordinates[0];
      this.state = {
        latitude:latitude,
        longitude:longitude,
        markerPosition:[latitude, longitude],
        zoom: 16,
        angle: angle !=null ? angle : courseAngle,
        device: props.location.state.device,
        speed: speed,
        time: convertDateTimeToString(new Date(inputDate)),
        showLine: false,
        showMarker: false
      }
    }else{
      latitude = latitude!=null ? latitude : 23.259365;
      longitude = longitude!=null ? longitude : 72.637951;
      this.state = {
        latitude: latitude,
        longitude: longitude,
        markerPosition:[latitude, longitude],
        zoom: 16,
        angle: angle !=null ? angle : 236,
        device: props.location.state.device,
        showLine: false,
        showMarker: false
      }
    }
  }

  componentDidMount() {

    this.client = mqttService.getClient((error) => {
      console.log(error);
    });
    mqttService.subscribe(this.client, `hd/${this.props.location.state.device.imei}`, (error) => {
      console.log(error);
    });
    this.client.on("message", (topic, message, packet) => {
      this.messageReceived(new TextDecoder("utf-8").decode(message))
    });
  }


  messageReceived(message) {
    console.log(message);
    const data = message.split(",");
    if(data[2] && data[4]){

      const time  = convertDateTimeToString(getDeviceDate(data[1]));//Date
      const latitude = data[2];//Latitude 
      const longitude = data[4];//Longitude
      const angle = data[6];//Angle
      const speed  = data[7];//speed
      if((speed == 0 || speed==undefined || speed=="") && this.state.showMarker){
        const data = {
          "deviceId": this.props.location.state.device.deviceId
        }
        sendAuthRequest(REQUESTURL.GET_LAST_PARKING_DATA, data).then(res=>{
          if(res.status==1){
             this.setState({
               parkingTime: res.result.differenceInMinute
             });
          }
        }, err=>{
  
        })
      }
      
      this.setState({
        markerPosition:[latitude, longitude],
        angle:angle,
        latitude:latitude,
        longitude:longitude,
        speed:speed,
        time:time
      });
    }
  }


  componentWillUnmount() {
    mqttService.unsubscribe(this.client, `hd/${this.props.location.state.device.imei}`);
    mqttService.closeConnection(this.client);
  }

  backClickHandler = () => {
    this.props.history.goBack();
  }

  liveShareClickHandler = () => {
    this.props.showHideSharingPopup(!this.props.visibleSharingPopup);
  }

  getPastRoute = () =>{
    const data = {
      "deviceId": this.props.location.state.device.deviceId
    }

    sendAuthRequest(REQUESTURL.LIVE_TRACK, data).then((res)=>{

      if(!this.state.showLine){
        console.log(res);
        let polyLineData = res.result.map(data=> [data.location.coordinates[1], data.location.coordinates[0]]);
        this.setState({
          showLine: true,
          polyLineData: polyLineData
        });
      }else{
       
        this.setState({
          showLine: false,
          polyLineData: []
        });
      }
      

    },(err)=>{
      console.log(err);
    })

  }

  markerClickHandler = () =>{
    if(!this.state.showMarker){
      
      this.setState({
        showMarker: true
      })
    }else{
      this.setState({
        showMarker: false
      })
    }
  }
  render() {
    return (
      <div className="live_track_page page_wrapper_height">
        <div className="title_heading">
          <h2>Live Track</h2>
          <div className="right_part ml-auto">
            <button className="btn btn-blue mr-2" type="button" onClick={this.liveShareClickHandler}>Live Share</button>
            <button className="btn btn-gray" type="button" onClick={this.backClickHandler}> Back</button>
          </div>
        </div>
        <div className="live_track_map_block inner_content_wrapper">
          <Map
            className="livetrack_map"
            center={this.state.markerPosition}
            zoom={this.state.zoom}
            onzoom={(event)=>{this.setState({zoom:event.target._zoom})}} 
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Google Map">
                <TileLayer
                  attribution=""
                  url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                  maxZoom={18}
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Settelite">
                <TileLayer
                  attribution=""
                  url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                  maxZoom={18}
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Open Street Map">
                <TileLayer
                  attribution=""
                  url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxZoom={18}
                  subdomains={["a", "b", "c"]}
                />
              </LayersControl.BaseLayer>

              {/* <Marker position={this.state.markerPosition} icon={icon}>
                <Popup> */}
                  <MarkerPopup
                    click={this.markerClickHandler} 
                    device={this.state.device} 
                    speed={this.state.speed ? this.state.speed : 0} 
                    latitude={this.state.latitude? this.state.latitude : ""} 
                    longitude={this.state.longitude? this.state.longitude: ""} 
                    time={this.state.time? this.state.time : ""}
                    position={this.state.markerPosition}
                    angle={this.state.angle}
                    liveTrackParking={this.state.parkingTime!=undefined} 
                    parkingTime= {this.state.parkingTime}>
                  </MarkerPopup>
                {/* </Popup>
              </Marker> */}
            </LayersControl>
            {this.state.showLine && (<Polyline pathOptions={this.redOptions} positions={this.state.polyLineData} />) }
            
          </Map>
          <div className="button-wrapper"> 
            <button type="button" className="btn btn-play" onClick={this.getPastRoute}>
            
              <i className="fas fa-route" aria-hidden="true"></i>
             
            </button>
         </div>
        </div>
        {this.props.visibleSharingPopup && (
          <LiveSharingModal onHide={this.liveShareClickHandler} device={this.state.device}/>
        )}
        
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    visibleSharingPopup: state.LiveTrackSetting.visibleSharingPopup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showHideSharingPopup: (action) => dispatch(showHideSharingPopup(action))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveTrack);