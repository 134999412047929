import React, {useState} from 'react';

const Password = (props) => {
    const [ state, setEyeVisible ] = useState({
        eyeVisible:false
    });

     const displayPasswordHandler = ()=>{
        setEyeVisible((prevState)=>{
            return {
                eyeVisible: !prevState.eyeVisible
            }
        });
    }

    const eyeClasses = ["fas"];

    if(state.eyeVisible){
        eyeClasses.push("fa-eye-slash");
    }else{
        eyeClasses.push("fa-eye");
    }

    const passwordClasses = ["from-control"];
    if(props.isPasswordInValid){
        passwordClasses.push("invalid");
    }
    return(
            <div className="pwd position-relative">
                
                {props.onKeyDownCapture && (<input type={state.eyeVisible ? "text" : "password"} 
                    className={passwordClasses.join(" ")} 
                    placeholder={props.placeholder} 
                    onKeyDownCapture={(event)=>{props.onKeyDownCapture(event,props.type ? props.type : "password")}}
                    onChange={(event)=>{props.changeHandler(event,props.type ? props.type : "password")}}
                    value={props.value}
                />)}

                {!props.onKeyDownCapture&& (<input type={state.eyeVisible ? "text" : "password"} 
                    className={passwordClasses.join(" ")} 
                    placeholder={props.placeholder} 
                   
                    onChange={(event)=>{props.changeHandler(event,props.type ? props.type : "password")}}
                    value={props.value}
                />)}
                
                
                <i className={eyeClasses.join(" ")} onClick={displayPasswordHandler}></i>
            </div>
    );
   
}



export default Password;