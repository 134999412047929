import React, { Component } from 'react';
import Datetime from 'react-datetime';
import Radio from '../../../component/Radio/Radio';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Accordion, useAccordionToggle } from 'react-bootstrap';
import CalenderInput from '../../../component/CalenderInput/CalenderInput';
import { convertDateToString } from '../../../util/DateUtil';

const CustomToggle = (props) => {
    const decoratedOnClick = useAccordionToggle(props.eventKey);
    return (
        <div className="ml-auto dropdown_btn" onClick={decoratedOnClick}>
            {props.children}
        </div>
    );
}
class DeviceFilter extends Component{
    constructor(props){
        super(props);
        this.state = {
            deviceName:"",
            expireDate:"",
            simNo:"",
            srNo:"",
            status:""
        }
    }

    statusSelectionHandler = (item) =>{
        this.setState({
            status:item
        });
    }

    expireDateChangeHandler = (moment) =>{
        this.setState({
            expireDate: convertDateToString(moment.toDate())
        });
    }

    clearAllHandler = () =>{
        this.setState({
            deviceName:"",
            expireDate:"",
            simNo:"",
            srNo:"",
            status:""
        }, ()=>{
            this.props.clearAllHandler();
        });

    }


    applyFilterHandler = () =>{
    
        if(this.state.deviceName || this.state.expireDate || this.state.simNo || this.state.srNo || this.state.status){

            let data = {
                "deviceName":this.state.deviceName,
                "expireDate":this.state.expireDate,
                "simNo":this.state.simNo,
                "srNo":this.state.srNo,
                "status":this.state.status
            }
            this.props.applyFilter(data);
        }
    }

    render(){
        return(
        <div className="filter_sidebar_scroll">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <div className="filter_sidebar_padding">
                    
                    <div className="filter_heading d-flex">
                        <h4>Filters</h4>
                        <button className="label_button ml-auto teal_text" onClick={this.clearAllHandler}>Clear all</button>
                    </div>
                    <hr />
                    <div className="filters_accordian">
                        <Accordion defaultActiveKey="0">
                            <div className="filter_row">
                                <div className="filter_head d-flex mb-3">
                                    <p className="title">Device Name</p>
                                    <CustomToggle eventKey="0"><i className="fas fa-chevron-down"></i></CustomToggle>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <div className="filter_body">
                                        <input type="text"
                                            placeholder="Search by name" 
                                            className="form-control"
                                            value={this.state.deviceName} 
                                            onChange={(event)=> this.setState({deviceName:event.target.value})}
                                         />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="filter_row">
                                <div className="filter_head d-flex mb-3">
                                    <p className="title">Expire Date</p>
                                    <CustomToggle eventKey="1"><i className="fas fa-chevron-down"></i></CustomToggle>
                                </div>
                                <Accordion.Collapse eventKey="1">
                                    <div className="filter_body date_filter">
                                        <Datetime dateFormat={"DD MMM, YYYY"} 
                                            value={this.state.expireDate}
                                            closeOnSelect={true} 
                                            timeFormat={false} 
                                            inputProps={{ placeholder: "DD/MM/YYYY" }} 
                                            renderInput={CalenderInput} 
                                            onChange={this.expireDateChangeHandler} />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="filter_row">
                                <div className="filter_head d-flex mb-3">
                                    <p className="title">SIM No.</p>
                                    <CustomToggle eventKey="2"><i className="fas fa-chevron-down"></i></CustomToggle>
                                </div>
                                <Accordion.Collapse eventKey="2">
                                <div className="filter_body">
                                        <input type="text" placeholder="Search by SIM No." className="form-control" 
                                            value={this.state.simNo} 
                                            onChange={(event)=> this.setState({simNo:event.target.value})}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="filter_row">
                                <div className="filter_head d-flex mb-3">
                                    <p className="title">SR No.</p>
                                    <CustomToggle eventKey="3"><i className="fas fa-chevron-down"></i></CustomToggle>
                                </div>
                                <Accordion.Collapse eventKey="3">
                                <div className="filter_body">
                                        <input type="text" placeholder="Search by SR No." className="form-control" 
                                            value={this.state.srNo} 
                                            onChange={(event)=> this.setState({srNo:event.target.value})}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            <div className="filter_row">
                                <div className="filter_head d-flex mb-3">
                                    <p className="title">Status</p>
                                    <CustomToggle eventKey="4"><i className="fas fa-chevron-down"></i></CustomToggle>
                                </div>
                                <Accordion.Collapse eventKey="4">
                                    <div className="filter_body status_filter">
                                        <Radio list={["Active", "Inactive"]} selectedValue={this.state.status} onSelection={this.statusSelectionHandler} />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </PerfectScrollbar>
            <div className="apply_btn">
                <button className="btn btn-blue btn-block" type="button" onClick={this.applyFilterHandler}>Apply</button>
            </div>
        </div>
        )
    }
}

export default DeviceFilter;