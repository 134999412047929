import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Header.css";
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/images/logo_light.png";
import logoMobile from "../../assets/images/logo_dark.png";
import userPlaceholder from "../../assets/images/user_placeholder.png";
import { getLoginDetails } from "../../util/Auth";
import { messaging } from "../../util/init-fcm";
import { REQUESTURL } from "../../constant/REQUEST-URL";
import { sendAuthRequest } from "../../util/API";
import NotificationBox from "../NotificationBox/NotificationBox";
import GoogleAd from "../GoogleAd/GoogleAd";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    // this.subscribeFirebase();
  }

  subscribeFirebase() {
    if (!localStorage.getItem("fire_token")) {
      messaging
        .requestPermission()
        .then(async function () {
          const token = await messaging.getToken();
          // console.log(token);
          localStorage.setItem("fire_token", token);
          //Send the Firebase token to server
          sendAuthRequest(REQUESTURL.ADD_NOTIFICATION_TOKEN, token).then(
            (result) => {
              // console.log("Token is registerd");
            }
          );
        })
        .catch(function (err) {
          // console.log("Unable to get permission to notify.", err);
        });
    }

    navigator.serviceWorker.addEventListener("message", (message) => {
      //TODO display the notification
      let title = message.data.notification.title;
      let body = message.data.notification.body;
      let messageType = "";

      if (body.startsWith("Over Speed")) {
        messageType = "Over Speed";
      } else if (body.startsWith("Geofence")) {
        messageType = "Geofence";
      } else if (body.startsWith("Battery")) {
        messageType = "Battery";
      }
      let msg = {
        timeout: 4000,
        title: title,
        message: body,
        messageType: messageType,
        isVisible: true,
      };
      this.addNotification(msg);
    });
  }

  addNotification(message) {
    message.id = new Date().getTime();
    message.timerId = setTimeout(() => {
      this.removeNotification(message);
    }, message.timeout);

    let messagesList = this.state.messages;
    messagesList.push(message);

    this.setState({
      messages: messagesList,
    });
  }

  removeNotification(msg) {
    // Remove Message from Array
    let messageList = this.state.messages;
    let storeMessages = messageList.filter((message) => {
      return message.id == msg.id;
    });

    storeMessages.forEach((smsg) => {
      clearTimeout(smsg.timerId);
    });

    messageList = messageList.filter((message) => {
      return message.id != msg.id;
    });

    this.setState({
      messages: messageList,
    });
  }

  logoutClickHandler() {
    if (localStorage.getItem("fire_token")) {
      messaging
        .deleteToken(localStorage.getItem("fire_token"))
        .then(() => {
          sendAuthRequest(
            REQUESTURL.REMOVE_NOTIFICATION_TOKEN,
            localStorage.getItem("fire_token")
          ).then((result) => {});
          localStorage.clear();
          this.props.history.replace("/login");
        })
        .catch((err) => {
          // console.log("Error unsubscribing", err);
          localStorage.clear();
          this.props.history.replace("/login");
        });
    } else {
      localStorage.clear();
      this.props.history.replace("/login");
    }
  }

  render() {
    let notifications = this.state.messages.map((message) => {
      return (
        <NotificationBox
          key={message.id}
          notification={message}
        ></NotificationBox>
      );
    });

    return (
      <header>
        <div className="notification_block">{notifications}</div>
        <div className="logo">
          <img src={logo} alt="" className="img-fluid d-none d-md-block" />
          <img
            src={logoMobile}
            alt=""
            className="img-fluid d-block d-md-none"
          />
          <span>Triangle</span>
        </div>
        <div className="navigation">
          <div className="navbar_icon" onClick={this.props.toggleHandler}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <GoogleAd slot="6234876600" classNames="mx-auto"></GoogleAd>
          <div className="right_navigation">
            <Dropdown className="user_dropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="user_img">
                  <img
                    src={userPlaceholder}
                    className="img-fluid rounded-circle"
                    alt=""
                  />{" "}
                  <span>{getLoginDetails().username}</span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    this.logoutClickHandler();
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
