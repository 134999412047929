import ManageSaleDevice from "./ManageSaleDevice/ManageSaleDevice";
import AddSaleDevice from './AddSaleDevice/AddSaleDevice';
const getRoutes = (url) => {
    return  [
        {
            to: url + "/add-saledevice",
            label:"Add Sale Device",
            component: AddSaleDevice,
            access: [ "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/manage-saledevice",
            label:"Sale Device",
            component: ManageSaleDevice,
            access: ["ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        }
    ]
}
export default getRoutes;