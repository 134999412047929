import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Suggestion from "../../../component/Suggestion/Suggestion";
import { sendAuthRequest } from "../../../util/API";
import { REQUESTURL } from "../../../constant/REQUEST-URL";
import Loader from "../../../component/Loader/Loader";
import Alert from "../../../component/Alert/Alert";
import GeoFenceMap from "../../../component/Map/GeoFenceMap";

class AddGeofence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersSuggestion: [],
      devicesSuggestion: [],
      form: {
        title: {
          value: "",
          message: undefined,
          touched: false,
          valid: false,
          label: "Title",
        },
        device: {
          value: [],
          message: undefined,
          touched: false,
          valid: false,
          label: "Device",
        },
        map: {
          value: undefined,
          message: undefined,
          valid: false,
          label: "Map",
        },
      },
      isLoading: false,
      alert: {
        timeout: 2000,
        message: "",
        messageType: "",
        isVisible: false,
      },
    };
  }

  fetchSuggestion = async () => {
    try {
      const [deviceSuggestionResponse] = await Promise.all([
        sendAuthRequest(REQUESTURL.DEVICE_SUGGESTION),
      ]);
      let deviceSuggestion = [];
      if (deviceSuggestionResponse) {
        deviceSuggestion = deviceSuggestionResponse.result.map((device) => {
          return {
            value: device.deviceId,
            label: device.name ? device.name : device.serialNo,
          };
        });
      }

      this.setState({
        devicesSuggestion: deviceSuggestion,
      });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.fetchSuggestion();
  }
  multiSelectHandleChange = (selectedOption, type) => {
    let updatedMultiSelect = { ...this.state.form };
    console.log(selectedOption);
    updatedMultiSelect[type].value = selectedOption;
    this.checkValidation(updatedMultiSelect, type);
    this.setState({ ...updatedMultiSelect });
  };
  checkValidation = (updatedstate, type) => {
    updatedstate[type].touched = true;
    if (
      updatedstate[type].value === null ||
      updatedstate[type].value === undefined ||
      updatedstate[type].value === "" ||
      updatedstate[type].value.length === 0
    ) {
      updatedstate[type].valid = false;
      updatedstate[type].message =
        "Please select " + updatedstate[type].label + ".";
    } else {
      updatedstate[type].message = "";
      updatedstate[type].valid = true;
    }
  };
  doSubmit = () => {
    this.setState({
      isLoading: true,
    });
    const deviceIds = this.state.form.device.value.map((device) => {
      return device.value;
    });

    const deviceNames = this.state.form.device.value.map((device) => {
      return device.label;
    });

    const title = this.state.form.title.value;

    const geoData = this.state.form.map.value;

    const coordinates = [];
    geoData.features[0].geometry.coordinates[0].forEach((value) => {
      coordinates.push(value);
    });

    const data = {
      title: title,
      devices: deviceIds,
      area: {
        type: "Polygon",
        coordinates: [...coordinates],
      },
      deviceNames: deviceNames,
    };
    const clearForm = this.clearForm();
    sendAuthRequest(REQUESTURL.ADD_GEOFENCE, data)
      .then((response) => {
        console.log(response);
        this.setState({
          ...clearForm,
          isLoading: false,
          alert: {
            timeout: 2000,
            message: response.statusMessage,
            messageType: "SUCCESS",
            isVisible: true,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          alert: {
            timeout: 2000,
            message: error,
            messageType: "ERROR",
            isVisible: true,
          },
        });
      });
  };
  submitHandler = () => {
    if (
      this.state.form.device.valid &&
      this.state.form.title.valid &&
      this.state.form.map.valid
    ) {
      this.doSubmit();
    } else {
      const updatedstate = { ...this.state.form };
      for (let type in updatedstate) {
        this.checkValidation(updatedstate, type);
      }
      this.setState({
        ...updatedstate,
      });
    }
  };
  clearHandler = () => {
    const clearForm = this.clearForm();
    this.setState({
      ...clearForm,
    });
  };

  cancelHandler = () => {
    this.props.history.goBack();
  };
  clearForm = () => {
    const updatedForm = { ...this.state.form };
    for (let type in updatedForm) {
      if (type === "map") {
        updatedForm[type].value = null;
      } else {
        updatedForm[type].value = "";
      }

      updatedForm[type].message = undefined;
      updatedForm[type].valid = false;
      updatedForm[type].touched = false;
    }
    return updatedForm;
  };
  onAlertClose = () => {
    this.setState({
      alert: {
        timeout: 2000,
        message: "",
        messageType: "",
        isVisible: false,
      },
    });
  };

  onAreaSelected = (geoData) => {
    console.log("Area Selected", geoData);
    const updatedstate = { ...this.state.form };
    updatedstate["map"].value = geoData;
    this.checkValidation(updatedstate, "map");
    this.setState({
      ...updatedstate,
    });
  };

  onAreaDeleted = () => {
    console.log("Area Deleted");
    const updatedstate = { ...this.state.form };
    updatedstate["map"].value = null;
    this.checkValidation(updatedstate, "map");
    this.setState({
      ...updatedstate,
    });
  };

  onInputChangeHandler(event, type) {
    const updatedstate = { ...this.state.form };
    updatedstate[type].value = event.target.value;
    this.checkValidation(updatedstate, type);
    this.setState({
      ...updatedstate,
    });
  }

  render() {
    const isDeviceInvalid =
      this.state.form.device.message !== "" &&
      this.state.form.device.touched &&
      !this.state.form.device.valid;
    const isTitleInvalid =
      this.state.form.title.message !== "" &&
      this.state.form.title.touched &&
      !this.state.form.title.valid;
    const isMapInvalid =
      this.state.form.map.message !== "" && !this.state.form.map.valid;
    return (
      <div className="add_geofence page_wrapper_height">
        {this.state.isLoading ? <Loader></Loader> : null}
        {this.state.alert.isVisible ? (
          <Alert alert={this.state.alert} onClose={this.onAlertClose}></Alert>
        ) : null}
        <div className="title_heading">
          <h2>Add New Geofence</h2>
          <div class="right_part ml-auto">
            <button
              className="btn btn-blue mr-2"
              onClick={this.submitHandler}
              type="button"
            >
              Save
            </button>
            <button className="btn btn-green mr-2" type="button" onClick={this.clearHandler}>
              Clear
            </button>
            <button
              className="btn btn-gray mr-2"
              type="button"
              onClick={this.cancelHandler}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="custom_form inner_content_wrapper">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <div className="inner_scroll_div">
              <form className="geofence_form">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">
                        Geofence Title <span className="red_text">*</span>
                      </label>
                      <input
                        type="text"
                        className="from-control"
                        value={this.state.form.title.value}
                        onChange={(event) => {
                          this.onInputChangeHandler(event, "title");
                        }}
                      />
                      {isTitleInvalid ? (
                        <p className="errorMsg">
                          {this.state.form.title.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">
                        Devices <span className="red_text">*</span>
                      </label>
                      <Suggestion
                        handleChange={this.multiSelectHandleChange}
                        options={this.state.devicesSuggestion}
                        isMulti={true}
                        type="device"
                        value={this.state.form.device.value}
                      ></Suggestion>
                      {isDeviceInvalid ? (
                        <p className="errorMsg">
                          {this.state.form.device.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="mb-2 w-100">
                    Map <span className="red_text">*</span>
                    {isMapInvalid ? (
                    <p className="errorMsg map_error">{this.state.form.map.message}</p>
                    ) : null}
                  </label>
                  <GeoFenceMap
                    selectedGeoFence={this.state.form.map.value}
                    onAreaSelected={this.onAreaSelected}
                    onAreaDeleted={this.onAreaDeleted}
                  ></GeoFenceMap>
                 
                </div>

                {/* <div className="submit_btn">
                  <button
                    className="btn btn-blue mr-2"
                    onClick={this.submitHandler}
                    type="button"
                  >
                    Save
                  </button>
                  <button className="btn btn-green mr-2" type="button" onClick={this.clearHandler}>
                    Clear
                  </button>
                  <button
                    className="btn btn-gray mr-2"
                    type="button"
                    onClick={this.cancelHandler}
                  >
                    Cancel
                  </button>
                </div> */}
              </form>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}
export default AddGeofence;
