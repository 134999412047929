import L from "leaflet";
import carIdeal from '../assets/images/vehicles/new/CAR_IDEAL.svg'
import carRun from '../assets/images/vehicles/new/CAR_RUN.svg'
import ambulanceIdeal from '../assets/images/vehicles/new/AMBULANCE_IDEAL.svg'
import ambulanceRun from '../assets/images/vehicles/new/AMBULANCE_RUN.svg'
import bikeIdeal from '../assets/images/vehicles/new/BIKE_IDEAL.svg'
import bikeRun from '../assets/images/vehicles/new/BIKE_RUN.svg'
import busIdeal from '../assets/images/vehicles/new/BUS_IDEAL.svg'
import busRun from '../assets/images/vehicles/new/BUS_RUN.svg'
import mixtureIdeal from '../assets/images/vehicles/new/MIXTURE_IDEAL.svg'
import mixtureRun from '../assets/images/vehicles/new/MIXTURE_RUN.svg'
import truckIdeal from '../assets/images/vehicles/new/TRUCK_IDEAL.svg'
import truckRun from '../assets/images/vehicles/new/TRUCK_RUN.svg'
import truckLongIdeal from '../assets/images/vehicles/new/TRUCK_LONG_IDEAL.svg'
import truckLongRun from '../assets/images/vehicles/new/TRUCK_LONG_RUN.svg'
import vanIdeal from '../assets/images/vehicles/new/VAN_IDEAL.svg'
import vanRun from '../assets/images/vehicles/new/VAN_RUN.svg'
import parking from '../assets/images/vehicles/parking3.svg'


export const getIcon = (angle, type,speed) =>{
    const anglecss = `${angle}deg`;
    if(speed>0){
        type=type + "_RUN";
    }else{
        type=type + "_IDEAL";
    }
    const icon = new L.DivIcon({
      className: "carIcon",
      html: `<div style="transform:rotate(${anglecss});"><img src=${getIconImage(type)}></div>`,
      iconSize: [50, 50],
      iconAnchor:   [35, 35],
      popupAnchor:  [0, -20]
    });
    return icon;
}
export const getParkingIcon = () =>{
    const icon = new L.DivIcon({
        className: "parkingIcon",
        html: `<div><img src=${parking}></div>`,
        iconSize: [50, 50],
        iconAnchor:   [12, 25],
        popupAnchor:  [0, -20]
      });
      return icon;
}

export const getStartIcon = () =>{
    const icon = new L.DivIcon({
        className: "parkingIcon",
        html: `<div><img src=${parking}></div>`,
        iconSize: [50, 50],
        iconAnchor:   [12, 25],
        popupAnchor:  [0, -20]
      });
      return icon;
}

export const getEndtIcon = () =>{
    const icon = new L.DivIcon({
        className: "parkingIcon",
        html: `<div><img src=${parking}></div>`,
        iconSize: [50, 50],
        iconAnchor:   [12, 25],
        popupAnchor:  [0, -20]
      });
      return icon;
}

const getIconImage = (type) =>{
    if("CAR_IDEAL" === type){
        return carIdeal;
    }else if("CAR_RUN" === type){
        return carRun;
    }else if("AMBULANCE_IDEAL" === type){
        return ambulanceIdeal;
    }else if("AMBULANCE_RUN" === type){
        return ambulanceRun;
    }else if("BIKE_IDEAL" === type){
        return bikeIdeal;
    }else if("BIKE_RUN" === type){
        return bikeRun;
    }else if("BUS_IDEAL" === type){
        return busIdeal;
    }else if("BUS_RUN" === type){
        return busRun;
    }else if("MIXTURE_IDEAL" === type){
        return mixtureIdeal;
    }else if("MIXTURE_RUN" === type){
        return mixtureRun;
    }else if("TRUCK_IDEAL" === type){
        return truckIdeal;
    }else if("TRUCK_RUN" === type){
        return truckRun;
    }else if("TRUCK_LONG_IDEAL" === type){
        return truckLongIdeal;
    }else if("TRUCK_LONG_RUN" === type){
        return truckLongRun;
    }else if("VAN_IDEAL" === type){
        return vanIdeal;
    }else if("VAN_RUN" === type){
        return vanRun;
    }else if(type.endsWith("IDEAL")){
        return carIdeal;
    }else {
        return carRun;
    }
}