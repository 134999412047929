import React from 'react';

const googleAdId = 'ca-pub-9339956930867613';

export default class GoogleAd extends React.Component{
    googleInit = null;
    
    componentDidMount() {
        let { timeout } = this.props;
        if(timeout==undefined){
            this.timeout=200;
        }
        this.googleInit = setTimeout(() => {
          if (typeof window !== 'undefined')
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }, timeout);
    }

    componentWillUnmount() {
        if (this.googleInit) clearTimeout(this.googleInit);
    }

    render(){
        let { classNames, slot } = this.props;
        if(classNames==undefined){
            classNames="";
        }
        if(slot==undefined){
            slot="";
        }
        return(
            <div className={classNames}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client={googleAdId}
                    data-ad-slot={slot}
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        )
    }
}