import ManageSaleDevice from "./ManageGeofence/ManageGeofence";
import AddSaleDevice from './AddGeofence/AddGeofence';
import ViewMore from './ViewMore/ViewMore';
const getRoutes = (url) => {
    return  [
        {
            to: url + "/add-geofence",
            label:"Add Sale Device",
            component: AddSaleDevice,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/view-more",
            label:"View More",
            component: ViewMore,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/manage-geofence",
            label:"Sale Device",
            component: ManageSaleDevice,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        }
    ]
}
export default getRoutes;
