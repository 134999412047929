import React, { Component, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import viewMore from '../../../assets/images/view_more.svg';
import { REQUESTURL } from '../../../constant/REQUEST-URL';
import { sendAuthRequest } from '../../../util/API';



const ViewMoreModal = (props) => {


    const [ loading, setLoading ] = useState(true);
    const [ result, setResult ] = useState(null);

    useEffect(() => {
        // Run! Like go get some data from an API.
        getViewMoreData();
    }, []);

    const calculateFuel= (fuelReading) =>{
        let y1 = props.device.fuelPercentage1;
        let y2 = props.device.fuelPercentage2;

        let x1 = props.device.fuelReading1;
        let x2 = props.device.fuelReading2;

        if(props.device.fuelData && props.device.fuelData.length){
           const index = props.device.fuelData.findIndex((item)=> item.reading > fuelReading);
           if(index == 0){
                // it's lowest
                return props.device.fuelData[0].liter;
           }else if(index == (props.device.fuelData.length-1)){
                // it's highest
                return props.device.fuelData[index].liter;
           }else if(index>=0){
                // It's in between
                const data1 = props.device.fuelData[index];
                const data2 = props.device.fuelData[index+1];
                y1 = data1.liter;
                y2 = data2.liter;
                x1 = data1.reading;
                x2 = data2.reading;
           }
        }

        
        
        // Find A B 

        let a = (y1 - y2)/(x1-x2);
        let b = (y1 - (a * (x1)));
        
        return Math.round((a*fuelReading) + b);
    }
    
    const getViewMoreData = ()=>{
        const data = {
            "deviceId":props.device.deviceId
        }
        sendAuthRequest(REQUESTURL.VIEWMORE_V2,data).then(response=>{
            if(response.status==1){
                setResult(response.result);
                setLoading(false);
            }else{
                setLoading(false);
            }
        }).catch(error =>{
            setLoading(false);
        })
    }

    let displayLoading = null;

    if(loading){
        
        displayLoading = (
            <div className="row">
                <div className="col-xl-6 col-md-6 col-12 ">
                    <img src={viewMore}></img>
                </div>
                <div className="col-xl-6 col-md-6 col-12 ">
                    <img src={viewMore}></img>
                </div>
                <div className="col-xl-6 col-md-6 col-12 ">
                    <img src={viewMore}></img>
                </div>
                <div className="col-xl-6 col-md-6 col-12 ">
                    <img src={viewMore}></img>
                </div>
        </div>
        )
    }
    let totalStopHours = 0;
    let totalStopDurationMinute=0;
    let totalRunningMinute = 0;
    let totalRunningHours = 0;
    let totalRunningKM = 0;
    let lastFuelReading;
    let fuelPercentage = 0;
    if(result){
        totalRunningKM = result.totalRunningKM;
        totalRunningMinute = +result.totalRunningDuration;
        totalRunningHours = 0;
        while(totalRunningMinute>=60){
            totalRunningHours = totalRunningHours+ 1;
            totalRunningMinute = totalRunningMinute - 60;
        }

        totalStopDurationMinute = +result.totalStopDuration;
        totalStopHours = 0;
        while(totalStopDurationMinute>=60){
            totalStopHours = totalStopHours+ 1;
            totalStopDurationMinute = totalStopDurationMinute - 60;
        }

        lastFuelReading = result.lastFuelReading;

        fuelPercentage = calculateFuel(result.lastFuelReading);
    }

    const isFuelSensorData = () => {
        return props.device.fuelData && props.device.fuelData.length;
    }
    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter">
                    {props.device.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="view_more_cards">
                    {displayLoading}
                    {!loading && (
                            <div className="row">
                                <div className="col-xl-6 col-md-6 col-12 ">
                                    <div className="card_theme total_running d-flex">
                                        <div className="icon"> <i className="fas fa-tachometer-alt"></i></div>
                                        <div className="details">
                                            <div className="name">Total Running</div>
                                            <div className="value">{totalRunningKM}<span>Km</span></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-12 ">
                                    <div className="card_theme running_duration d-flex">
                                        <div className="icon"> <i className="fas fa-chart-bar"></i></div>
                                        <div className="details">
                                            <div className="name"> Total Running Duration </div>
                                            <div className="value">{totalRunningHours} <span>hours</span> {totalRunningMinute} <span>min</span></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-12 ">
                                    <div className="card_theme stop_duration d-flex">
                                        <div className="icon"> <i className="fas fa-hourglass-end"></i></div>
                                        <div className="details">
                                            <div className="name"> Total Stop Duration </div>
                                            <div className="value">{totalStopHours} <span>hours</span> {totalStopDurationMinute} <span>min</span></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-12 ">
                                    <div className=" card_theme fuel d-flex">
                                        <div className="icon"><i className="fas fa-gas-pump"></i></div>
                                        <div className="details">
                                            <div className="name"> Fuel </div>
                                            <div className="value">  {fuelPercentage}{isFuelSensorData() ? ' ltr' : ' %' }  <span className="es"> { (lastFuelReading!=undefined ? `(${lastFuelReading})`: ``)} </span></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide} type="button" className="btn btn-gray">Close</button>
            </Modal.Footer>
        </Modal>
    )

}


export default ViewMoreModal;