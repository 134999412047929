import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import GeofenceItem from "../../../component/GeofenceItem/GeofenceItem";
import ContentLoader from "../../../component/ContentLoader/ContentLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../../../component/Alert/Alert";
import { REQUESTURL } from "../../../constant/REQUEST-URL";
import { sendAuthRequest } from "../../../util/API";
class ManageGeofence extends Component {
  state = {
    geofence: [],
    displayFilter: window.innerWidth <= 991 ? false : true,
    isLoading: true,
    alert: {
      timeout: 2000,
      message: "",
      messageType: "",
      isVisible: false,
    },
    deleteAlert: false,
    deleteGeofence: null,
  };

  deleteGeofenceHandler = (geofence) => {
    const geofenceId = geofence.geofenceId;
        this.setState({
            deleteAlert:true,
            deleteGeofenceId: geofenceId
        });
  };


  viewClickHandler = (geofence) => {
    let url = `/dashboard/geofence/view-more`;
    this.props.history.push({
      pathname: url,
      state: geofence
    });
  };

  componentDidMount() {
    this.fetchGeoFenceData();
  }

  fetchGeoFenceData = (res) => {
    sendAuthRequest(REQUESTURL.FETCH_GEOFENCE)
      .then((response) => {
        let geofence = [];
        if(response.result){
            geofence = response.result
        }
        if(res) {
          this.setState({
            isLoading:false,
            geofence: geofence,
            alert:{
              timeout:2000,
              message: res.statusMessage,
              messageType:"SUCCESS",
              isVisible:true 
            }
          })
        }else{
          this.setState({
            isLoading:false,
            geofence: geofence
          })
        }
        
      }).catch((error) => {
        if(res){
          throw error
        }else{
          this.setState({
            isLoading: false,
            alert: {
              timeout: 2000,
              message: error,
              messageType: "ERROR",
              isVisible: true,
            },
          });
        }
      });
  };
  onCloseAlert = () => {
    const alert = {
      ...this.state.alert,
      isVisible: false,
    };
    this.setState({
      alert: alert,
    });
  };

  onCancel =()=>{
    this.setState({
        deleteAlert:false
    })
  }

  deleteFile =()=>{
    this.setState({
        deleteAlert:false,
        isLoading:true
    });
    const data = {
        geofenceId:this.state.deleteGeofenceId
    }
  
    // console.log(data);
    sendAuthRequest(REQUESTURL.DELETE_GEOFENCE,data).then(res =>{
      this.fetchGeoFenceData(res);
    }).catch(error => {
        this.setState({
            isLoading:false,
            alert:{
                timeout:2000,
                message: error,
                messageType:"ERROR",
                isVisible:true 
            }
        })
    });
  }

  render() {
    let geofenceItemList = null;
    if (this.state.geofence && this.state.geofence.length > 0) {
      geofenceItemList = this.state.geofence.map((geofenceItem) => {
        return (
          <GeofenceItem
            key={geofenceItem.geofenceId}
            deleteGeofenceHandler={this.deleteGeofenceHandler}
            viewClickHandler={this.viewClickHandler}
            geofence={geofenceItem}
          />
        );
      });
    }

    return (
      <div className="geofence_page page_wrapper_height">
        {this.state.alert.isVisible ? (
          <Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert>
        ) : null}
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          title="Are you sure?"
          onConfirm={this.deleteFile}
          onCancel={this.onCancel}
          btnSize="md"
          focusCancelBtn
          show={this.state.deleteAlert}
        >
          You want to delete this Geofence?
        </SweetAlert>
        <div className="title_heading">
          <h2>Manage Geofence</h2>
          <div className="right_part ml-auto">
            <Link
              to={"/dashboard/geofence/add-geofence"}
              className="btn btn-blue"
            >
              <i className="fas fa-plus mr-1"></i> New Geofence
            </Link>
          </div>
        </div>
        <div className="geofence inner_content_wrapper">
          <div className="geofence_list inner_content_list">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              <div className="inner_scroll_div">
                <div className="row">
                  {geofenceItemList}
                  {this.state.isLoading ? (
                    <ContentLoader size={3} type="GEOFENCE" />
                  ) : null}
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageGeofence;
