import React from "react";
import { Map, TileLayer, Marker, Popup, LayersControl, Polyline } from "react-leaflet";
import MarkerPopup from "../../../component/MarkerPopup/MarkerPopup";
import { convertDateTimeToString } from "../../../util/DateUtil";
class PlayBackMap extends React.Component {
  constructor(props) {
    super(props);
    let latitude =  props.data[0].location.coordinates[1];
    let longitude = props.data[0].location.coordinates[0];
    
    let polyLineData = props.data.map(data=>{
        return [data.location.coordinates[1], data.location.coordinates[0]];
    });

    
    let markerDataArray = props.data.map(data=>{
        return {
            location:[data.location.coordinates[1], data.location.coordinates[0]],
            angle:data.courseAngle,
            speed:data.speed,
            time: data.inputDate,
            parking: data.parking,
            id: data.id.timestamp,
            diff: data.differenceInMinute
        }
    });
    let parkingData = this.calculateParking(markerDataArray);

    this.state = {
      lat: latitude,
      lng: longitude,
      markerPosition: [latitude, longitude],
      zoom: 14,
      angle:  236,
      device: props.device,
      polyLineData: polyLineData,
      markerData: markerDataArray,
      index:0,
      parkingMarkerData:parkingData,
      play:-1
    };
  }

  calculateParking(data){
    let parkings = [];
    for(let i=0; i<data.length;i++){

      if(data[i].speed == 0){
				let difference_In_Minutes = data[i].diff;

        if (difference_In_Minutes >= this.props.holdTime) {
          // this is parking

          parkings.push({
            markerPosition:data[i].location,
            latitude:data[i].location[0],
            longitude:data[i].location[1],
            angle:data[i].angle,
            time: convertDateTimeToString(new Date(data[i].time)),
            id:data[i].id,
            parkingTime: difference_In_Minutes
          });
        }
      }
    }
    return parkings;
  }

  componentDidMount(){
  }

  startPlay(){
    let time = 1000;
    if(this.props.showSpeed=="FAST"){
      time = 100;
    }else if(this.props.showSpeed=="MEDIUM"){
      time = 200;
    }else if(this.props.showSpeed=="SLOW"){
      time = 300;
    }
    if(this.state.markerData.length>1){
       let intervalId = setInterval(()=>{
            this.changeMarkerPosition();
        },time);
        this.setState({ intervalId: intervalId })
    }
  }

  pausePlay(){
    clearInterval(this.state.intervalId);
  }

  changeMarkerPosition(){
    
    if(this.state.markerData.length > this.state.index){


        let didFind = false;
        let index = this.state.index;
        for(let i = this.state.index+1; i < this.state.markerData.length; i++){
          if(this.state.markerData[i].speed > 0){
            index=i;
            didFind = true;
            break;
          }
        }
        if(!didFind){
          index = this.state.markerData.length - 1;
        }


        let data = this.state.markerData[this.state.index];
        // let polyLineData = [...this.state.polyLineData];
        
        // let parkingData = [...this.state.parkingMarkerData];

        this.setState({
            markerPosition:data.location,
            latitude:data.location[0],
            longitude:data.location[1],
            angle:data.angle,
            time: convertDateTimeToString(new Date(data.time)),
            index: index,
            // polyLineData: polyLineData,
            // parkingMarkerData: parkingData,
            currentSpeed: data.speed
        });
    }else{
        clearInterval(this.state.intervalId);
        this.setState({
          play:-1,
          index:0,
        })
    }
  }

  componentWillUnmount(){
    clearInterval(this.state.intervalId);
  }

  playClickHandler = () =>{

    let polylineData = [...this.state.polyLineData];
    let parkingMarkerData = [...this.state.parkingMarkerData];

    let play = 1;
    if(this.state.play==0){
      play = 1
    }else if(this.state.play==1){
      play = 0
    }else if(this.state.play==-1){
      play = 1;
      // polylineData=[];
      // parkingMarkerData=[];
    }

    
    this.setState({
      play: play,
      polyLineData:polylineData,
      parkingMarkerData:parkingMarkerData
    },()=>{
      if(this.state.play==1){
        this.startPlay();
      }else if(this.state.play==0){
        this.pausePlay();
      }
    });
  }



  render() {

    let markerArray = this.state.parkingMarkerData.map(marker=>{
        return (
            <MarkerPopup
                key={marker.id}
                device={this.state.device}
                speed={marker.speed ? marker.speed : 0}
                latitude={marker.latitude ? marker.latitude : ""}
                longitude={marker.longitude ? marker.longitude : ""}
                time={marker.time ? marker.time : ""}
                position={marker.markerPosition}
                angle={marker.angle}
                parking={true}
                parkingTime={marker.parkingTime}
                type={"PLAYBACK"}
          ></MarkerPopup>
        );
    })

    return (
      <div className="playback_map_block inner_content_wrapper">
        <Map
          className="playback_map"
          center={this.state.markerPosition}
          zoom={this.state.zoom}
          onzoom={(event) => {
            this.setState({ zoom: event.target._zoom });
          }}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Google Map">
              <TileLayer
                attribution=""
                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                maxZoom={18}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google Settelite">
              <TileLayer
                attribution=""
                url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                maxZoom={18}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Open Street Map">
              <TileLayer
                attribution=""
                url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxZoom={18}
                subdomains={["a", "b", "c"]}
              />
            </LayersControl.BaseLayer>
            <MarkerPopup
              device={this.state.device}
              speed={this.state.currentSpeed ? this.state.currentSpeed : 0}
              latitude={this.state.latitude ? this.state.latitude : ""}
              longitude={this.state.longitude ? this.state.longitude : ""}
              time={this.state.time ? this.state.time : ""}
              position={this.state.markerPosition}
              angle={this.state.angle}
              duration={0}
              type={"PLAYBACK"}
            ></MarkerPopup>
            {markerArray}
            <Polyline positions={this.state.polyLineData}></Polyline>
          </LayersControl>
        </Map>

        <div className="button-wrapper"> 
            <button type="button" className="btn btn-play" onClick={this.playClickHandler}>
              {(this.state.play==0 || this.state.play==-1) && (<i className="fa fa-play-circle" aria-hidden="true"></i>) }
              {this.state.play==1 && ( <i className="fa fa-pause-circle" aria-hidden="true"></i>) }
             
            </button>
         </div> 
      </div>
    );
  }
}

export default PlayBackMap;
