import ManageUser from './ManageUser/ManageUser';
import AddUser from './AddUser/AddUser';

const getRoutes = (url) => {
    return  [
        {
            to: url + "/add-user",
            label:"Add User",
            component: AddUser,
            access: [ "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/manage-user",
            label:"User",
            component: ManageUser,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/edit-user",
            label:"Edit User",
            component: AddUser,
            access: ["ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
    ]
}

export default getRoutes;