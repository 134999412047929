import React from 'react';
import { getLiveSharingDetails, setLiveTrackData } from '../../redux/Livetracksetting/Livetracksetting.actions';
import { Map, TileLayer, LayersControl } from "react-leaflet";
import { connect } from 'react-redux';
import mqttService from '../../util/MqttService';
import { convertDateTimeToString, getDeviceDate } from '../../util/DateUtil';
import MarkerPopup from '../../component/MarkerPopup/MarkerPopup';
import { UPDATE_LIVE_SHARING_STATUS } from '../../redux/Livetracksetting/Livetracksetting.type';
import Loader from '../../component/Loader/Loader';
import logo from '../../assets/images/logo_light.png';
import './LiveShare.css';

class LiveShare extends React.Component {

    constructor(props) {
        super(props);
        this.mqttClient = undefined;
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.props.getLiveSharingDetails(id);
        }
    }

    getLastDeviceLocation() {
        if (this.props.liveSharingDetails.deviceBean && this.props.liveSharingDetails.deviceBean.lastDeviceData && this.props.liveSharingDetails.deviceBean.lastDeviceData.location) {
            const location = this.props.liveSharingDetails.deviceBean.lastDeviceData.location;
            return [location.coordinates[1], location.coordinates[0]];
        }
        return [21.912883, 70.039771];
    }

    connectMqtt(deviceBean) {
        console.log("Connect MQTT");
        this.mqttClient = mqttService.getClient((error) => {
            console.log(error);
        });
        mqttService.subscribe(this.mqttClient, `hd/${deviceBean.imei}`, (error) => {
            console.log(error);
        });
        this.mqttClient.on("message", (topic, message, packet) => {
            this.messageReceived(new TextDecoder("utf-8").decode(message))
        });
    }

    messageReceived(message) {
        console.log(message);
        const data = message.split(",");
        if (data[2] && data[4]) {

            this.props.setLiveTrackData(data);

        }
    }

    componentWillUnmount() {
        if (this.mqttClient) {
            mqttService.unsubscribe(this.mqttClient, `hd/${this.props.liveSharingDetails.deviceBean.imei}`);
            mqttService.closeConnection(this.mqttClient);
        }
    }

    render() {
        if (this.props.liveSharingDetails && this.props.liveSharingDetails.deviceBean && !this.mqttClient) {
            this.connectMqtt(this.props.liveSharingDetails.deviceBean);
        }

        if (this.props.status === "LOADING") {
            return <Loader></Loader>
        }

        if (this.props.status === "NO_LINK") {
            return (
                <div className='live_share_wrapper'>
                    <div className='live_share_header'>
                        <div className="logo">
                            <img src={logo} className="img-fluid" alt="" />
                            <h1>Triangle</h1>
                        </div>
                    </div>
                    <div className='live_share_content'>
                        <div className='center_block'>
                            <h2>Oops!</h2>
                            <p>The Link is not valid.</p>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.status === "LINK_EXPIRED") {
            return (
                <div className='live_share_wrapper'>
                    <div className='live_share_header'>
                        <div className="logo">
                            <img src={logo} className="img-fluid" alt="" />
                            <h1>Triangle</h1>
                        </div>
                    </div>
                    <div className='live_share_content'>
                        <div className='center_block'>
                            <h2>Oops!</h2>
                            <p>The Link is no longer active.</p>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className='live_share_wrapper'>

                <div className='live_share_header'>
                    <div className="logo">
                        <img src={logo} className="img-fluid" alt="" />
                        <h1>Triangle</h1>
                    </div>
                </div>
                <div className=''>
                    <div className="live_track_page page_wrapper_height">
                        <div className="live_track_map_block inner_content_wrapper">
                            {this.props.liveSharingDetails && (
                                <Map
                                    className="livetrack_map"
                                    center={this.getLastDeviceLocation()}
                                    zoom={18}
                                >
                                    <LayersControl position="topright">
                                        <LayersControl.BaseLayer checked name="Google Map">
                                            <TileLayer
                                                attribution=""
                                                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                                                maxZoom={18}
                                                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                                            />
                                        </LayersControl.BaseLayer>
                                        <LayersControl.BaseLayer name="Google Settelite">
                                            <TileLayer
                                                attribution=""
                                                url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                                                maxZoom={18}
                                                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                                            />
                                        </LayersControl.BaseLayer>

                                        <LayersControl.BaseLayer name="Open Street Map">
                                            <TileLayer
                                                attribution=""
                                                url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                maxZoom={18}
                                                subdomains={["a", "b", "c"]}
                                            />
                                        </LayersControl.BaseLayer>
                                    </LayersControl>
                                    {this.props.liveTrackData && (
                                        <MarkerPopup
                                            // click={this.markerClickHandler}
                                            device={this.props.liveSharingDetails.deviceBean}
                                            speed={this.props.liveTrackData.speed ? this.props.liveTrackData.speed : 0}
                                            latitude={this.props.liveTrackData.location[0] ? this.props.liveTrackData.location[0] : ""}
                                            longitude={this.props.liveTrackData.location[1] ? this.props.liveTrackData.location[1] : ""}
                                            time={this.props.liveTrackData.time ? this.props.liveTrackData.time : ""}
                                            position={this.props.liveTrackData.location}
                                            angle={this.props.liveTrackData.angle}>
                                        </MarkerPopup>
                                    )}

                                </Map>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        liveSharingDetails: state.LiveTrackSetting.liveSharingDetails,
        liveTrackData: state.LiveTrackSetting.liveTrackData,
        status: state.LiveTrackSetting.status
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLiveSharingDetails: (id) => dispatch(getLiveSharingDetails(id)),
        setLiveTrackData: (data) => dispatch(setLiveTrackData(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveShare);