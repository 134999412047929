import * as moment from 'moment';
const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const convertDateTimeToString = (date) =>{
    let formatted_date = ("0" + date.getDate()).slice(-2) + " " + months[date.getMonth()] + ", " + date.getFullYear() + " " + formatAMPM(date);
    return formatted_date;
}

export const convertDateToString = (date) =>{
    let formatted_date = ("0" + date.getDate()).slice(-2) + " " + months[date.getMonth()] + ", " + date.getFullYear() + " " + ("0" +  date.getHours()).slice(-2)  + ":" + ("0" +  date.getMinutes()).slice(-2) + ":" + ("0" +  date.getSeconds()).slice(-2);
    return formatted_date;
}
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}
export function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}


export function getDeviceDate(dateString){
    // let hour = parseInt(dateString.substring(0, 2));
    // let min = parseInt(dateString.substring(2, 4));
    // const sec = parseInt(dateString.substring(4, 6));
    // const date = new Date();
    // min = min + 30;
    // if(min>=60){
    //     min = min - 60;
    //     hour = hour + 1;
    // }
    // hour = hour + 5;
    // if(hour>=24){
    //     hour = hour - 24;
    // }

    return (moment.utc(dateString, "DDMMYYHHmmss").toDate());
}


export function getDates(selectDay){
    var from = new Date();
    if(selectDay==="YESTERDAY"){
        from.setDate(from.getDate()-1);
        return {
            from : from,
            to: new Date()
        }
    }else if(selectDay === "LAST_WEEK"){
        from.setDate(from.getDate()-6);
        return {
            from: from,
            to: new Date(),
        }
    }else if(selectDay === "LAST_MONTH"){
        from.setDate(from.getDate()-29);
        return {
            from: from,
            to: new Date(),
        }
    }else{
        return {
            from: new Date(),
            to: new Date(),
        }
    }
}

export function getNewDateWithZeroTime(){
    let startDate = new Date();
    startDate.setHours(0,0,0,0);
    return startDate;
}

export function getDifferenceDays(date1, date2){
    const mDate1 = moment(date1);
    const mDate2 = moment(date2);
    return mDate2.diff(mDate1, 'days') 
}