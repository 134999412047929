import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import NootificationFilter from "./Filter/NootificationFilter";
import NotificationItem from "../../component/NotificationItem/NotificationItem";
import ContentLoader from "../../component/ContentLoader/ContentLoader";
import { sendAuthRequest } from "../../util/API";
import { REQUESTURL } from "../../constant/REQUEST-URL";
const LIMIT = 10;
class Notifications extends Component {
  state = {
    notifications: [],
    displayFilter: window.innerWidth <= 991 ? false : true,
    isLoading: true,
    // alert:{
    //     timeout:2000,
    //     message:"",
    //     messageType:"",
    //     isVisible:false
    // },
    // deleteAlert: false,
    // deleteDeviceId: "",
    currentPage: 1,
    totalNotification: 0,
    filterApplied: false,
    filterData: {},
  };
  filterHandler = () => {
    if (window.innerWidth <= 991) {
      this.setState((prevState) => {
        return {
          displayFilter: !prevState.displayFilter,
        };
      });
    }
  };
  applyFilterHandler = (filterData) => {
    if (window.innerWidth <= 991) {
      this.setState(
        (prevState) => {
          return {
            displayFilter: false,
          };
        },
        () => {
          this.sendFilterRequest(filterData);
        }
      );
    } else {
      this.sendFilterRequest(filterData);
    }
  };
    clearAllHandler = () => {
        const data = {
            limit: LIMIT,
            currentPage : 1
        };
        if(window.innerWidth<=991){
            this.setState({
                isLoading:true,
                notifications:[],
                totalNotification: 0,
                filterApplied:false,
                currentPage:1,
                displayFilter: false
            })
        }else{
            this.setState({
                isLoading:true,
                notifications:[],
                totalNotification: 0,
                filterApplied:false,
                currentPage:1,
            })
        }
        this.fetchNotifications(data);
    }
  sendFilterRequest = (filterData) => {
    const data = {
      ...filterData,
      limit: LIMIT,
      currentPage: 1,
      filterApplied: true,
    };
    this.setState({
      isLoading: true,
      notifications: [],
      currentPage: 1,
      totalNotification: 0,
      filterApplied: true,
      filterData: {
        ...filterData,
      },
    });
    this.fetchNotifications(data);
  };

  componentDidMount() {
    let data = {
      currentPage: 1,
      limit: LIMIT,
    };
    this.fetchNotifications(data);
  }

  fetchNotifications(data, res) {
    sendAuthRequest(REQUESTURL.NOTIFICATION_FETCH, data)
      .then((response) => {
        let notifications = [];
        if (response.result) {
          notifications = response.result.notificationBeans;
        }

        const notificationsdata = [
          ...this.state.notifications,
          ...notifications,
        ];
        this.setState({
          isLoading: false,
          notifications: notificationsdata,
          totalNotification: response.result.totalNotification,
          currentPage: response.result.currentPage,
          lastItemId: response.result.lastItemId,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          alert: {
            timeout: 2000,
            message: error,
            messageType: "ERROR",
            isVisible: true,
          },
        });
      });
  }

  scrollDownReachLimitHandler = (container) => {
      console.log( this.state.totalNotification);
      console.log( LIMIT * this.state.currentPage < this.state.totalNotification);
      console.log(!this.state.isLoading);
    if (
      this.state.totalNotification > 0 &&
      LIMIT * this.state.currentPage < this.state.totalNotification &&
      !this.state.isLoading
    ) {
      const data = {
        currentPage: this.state.currentPage + 1,
        limit: LIMIT,
        lastItemId: this.state.notifications[this.state.notifications.length - 1]
          .notificationId,
      };
      this.setState({
        isLoading: true,
      });
      this.fetchNotifications(data);
    }
  };

  getNotificationList = (NotificationList) => {
    return (
      <div className="user_list inner_content_list">
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          onYReachEnd={this.scrollDownReachLimitHandler}
        >
          <div className="inner_scroll_div">
            <div className="row">
              {NotificationList}
              {this.state.isLoading ? (
                <ContentLoader size={4} type="NOTIFICATION" />
              ) : null}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  };
  render() {
    const NotificationList = this.state.notifications.map((notification) => {
      return (
        <NotificationItem
          notification={notification}
          key={notification.notificationId}
        />
      );
    });
    return (
      <div className="notification_page page_wrapper_height">
        <div className="title_heading">
          <h2>Manage Notification</h2>
          <div className="right_part ml-auto">
            <button
              className="btn btn btn-green mr-2 d-inline-block d-lg-none"
              onClick={this.filterHandler}
            >
              {" "}
              <i className="fas fa-filter"></i>{" "}
            </button>
          </div>
        </div>
        <div className="notification_sec inner_content_wrapper">
          {this.state.displayFilter ? (
            <div className="filter_sidebar">
              <NootificationFilter
                applyFilter={this.applyFilterHandler}
                clearAllHandler={this.clearAllHandler}
              />
            </div>
          ) : null}
          {window.innerWidth > 991
            ? this.getNotificationList(NotificationList)
            : !this.state.displayFilter && window.innerWidth <= 991
            ? this.getNotificationList(NotificationList)
            : null}
        </div>
      </div>
    );
  }
}

export default Notifications;
