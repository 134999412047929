import axios from 'axios';
import {REQUESTURL} from '../constant/REQUEST-URL';

export const API = axios.create({
    baseURL:"https://triangle.net.in/api",
    timeout:60000
});

// export const API = axios.create({
//     baseURL:"http://localhost:8080/api",
//     timeout:60000
// });

export const EXTERNAL_API = axios.create({
    timeout:30000
});

API.interceptors.request.use(request=>{
    return request;
}, error=>{
    console.log(error);
    throw error;
});

API.interceptors.response.use(response=>{
    return response;
}, error=>{
    console.log(error);
    throw error;
})

export const sendAuthRequest = async (url, data, configuration) => {
    const config = {
        headers:{
            "Content-Type":"application/json",
            "Authorization": `hdrmaojhit ${localStorage.getItem("_token")}`,
        },
        ...configuration
    }
    try {
        const response = await API.post(url,data, config);
        if (response.data.status === 0) {
            return Promise.reject(response.data.statusMessage);
        }else if(response.data.status === 500) {
            return Promise.reject(response.data.message);
        }
        return Promise.resolve(response.data);
    }
    catch (error) {
        if(error.response && error.response.data.status===999){
            localStorage.clear();
            window.location.replace(window.location.href);
            return Promise.reject(error.response.data.message);
        }
        return Promise.reject(error.message);
    }
}


export const sendRequest = async (url, data, configuration) => {
    try {
        const config = {
            headers:{
                "Content-Type":"application/json",
                ...configuration
            }
            
        }
        const response = await API.post(url, data, config);
        if (response.data.status === 0) {
            return Promise.reject(response.data.statusMessage);
        }
        return Promise.resolve(response.data);
    }
    catch (error) {
        return Promise.reject(error.message);
    }
}



export const sendLoginRequest = async (data) => {
    
    try {
        const config = {
            headers:{
                "Content-Type":"application/json"
            }
        }
        const response = await API.post(REQUESTURL.AUTHENTICATION, data, config);
        if (response.data.status === 0) {
            return Promise.reject(response.data.statusMessage);
        }
        localStorage.setItem("_token", response.data.result.authorizationToken);
        localStorage.setItem("_USER", JSON.stringify(response.data.result.userBean));
        localStorage.setItem("_ROLE", response.data.result.userBean.type);
        return Promise.resolve(response.data);
    }
    catch (error) {
        return Promise.reject(error.message);
    }
}
