import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Datetime from 'react-datetime';
import CalenderInput from '../../../component/CalenderInput/CalenderInput';
import Suggestion from '../../../component/Suggestion/Suggestion';
import { convertDateToString } from '../../../util/DateUtil';
import { sendAuthRequest } from '../../../util/API';
import Loader from '../../../component/Loader/Loader';
import Alert from '../../../component/Alert/Alert';
import { REQUESTURL } from '../../../constant/REQUEST-URL';

const validateDate = (currentDate) => {
    return currentDate && new Date().getTime() >= currentDate.toDate().getTime();
}
const deviceType = [
    { value: 'CAR', label: 'Car' },
    { value: 'BIKE', label: 'Bike' },
    { value: 'BUS', label: 'Bus' },
    { value: 'MIXTURE', label: 'Mixture' },
    { value: 'TRUCK', label: 'Truck' },
    { value: 'TRUCK_LONG', label: 'Truck Long' },
    { value: 'AMBULANCE', label: 'Ambulance' }
];

class EditDevice extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.location.state);
        const { name, type, tag, serialNo, imei, simNumber, installationDate, activationDay, speedLimit, backupDay, renualAmount, holdTime, fuelPercentage1, fuelPercentage2, fuelReading1, fuelReading2, imsiNo, acReverse, fuelData } = this.props.location.state;

        const deviceTypeArray = deviceType.filter((obj) => {
            return obj.value === type;
        });
        this.state = {
            form: {
                deviceType: {
                    value: deviceTypeArray ? deviceTypeArray[0] : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "Device Type"
                },
                tag: {
                    value: tag ? tag : "",
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Tag"
                },
                deviceName: {
                    value: name ? name : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "Device Name"
                },
                serialNo: {
                    value: serialNo,
                    message: undefined,
                    touched: false,
                    valid: false,
                    label: "Serial No.",
                    isValidationRequire: true,
                    isDisabled: serialNo ? true : false
                },
                imeiNo: {
                    value: imei,
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "IMEI No.",
                    isDisabled: imei ? true : false

                },
                speedlimit: {
                    value: speedLimit ? speedLimit : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "Speed Limit"
                },
                holdTime: {
                    value: holdTime ? holdTime : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "Hold Time"
                },
                installationDate: {
                    value: installationDate ? convertDateToString(new Date(installationDate)) : convertDateToString(new Date()),
                    message: undefined,
                    touched: false,
                    valid: false,
                    label: "Installation Date",
                    isValidationRequire: true,
                    isDisabled: installationDate ? true : false
                },
                activeDays: {
                    value: activationDay ? activationDay : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "Active Days"
                },
                backupDays: {
                    value: backupDay ? backupDay : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "Backup Days"
                },
                imsiNo: {
                    value: imsiNo? imsiNo: "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "IMSI No."
                },
                simNo: {
                    value: simNumber ? simNumber : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    isValidationRequire: true,
                    label: "SIM No."
                },
                fuelReading1: {
                    value: fuelReading1 ? fuelReading1 : "",
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Fuel Reading 1"
                },
                percentage1: {
                    value: fuelPercentage1 ? fuelPercentage1 : "",
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Percentage 1"
                },
                fuelReading2: {
                    value: fuelReading2 ? fuelReading2 : "",
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Fuel Reading 2"
                },
                percentage2: {
                    value: fuelPercentage2 ? fuelPercentage2 : "",
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Percentage 2"
                },
                acReverse: {
                    value: acReverse ? acReverse : false,
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "AC Reverse"
                },
                amount: {
                    value: renualAmount ? renualAmount : "",
                    message: undefined,
                    touched: false,
                    valid: false,
                    label: "Renewal Amount",
                    isValidationRequire: true,
                    isDisabled: renualAmount ? true : false
                },
                fuelSensor: {
                    value: fuelData && fuelData.length,
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Fuel Sensor"
                },
                fuelData: {
                    value: fuelData && fuelData.length ? fuelData : [],
                    message: undefined,
                    touched: false,
                    valid: false,
                    label: "Fuel Data",
                    isValidationRequire: true,
                },
                fillingThreshold: {
                    value: 10,
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Fillnng Threshold"
                },
                theftThreshold: {
                    value: 5,
                    message: undefined,
                    touched: false,
                    valid: true,
                    label: "Theft Threshold"
                },
            },
            isLoading: false,
            alert:{
                timeout:2000,
                message:"",
                messageType:"",
                isVisible:false
            }
        }
    }

    onCloseAlert = ()=>{
        const alert = {
            ...this.state.alert,
            isVisible:false
        }
        this.setState({
            alert: alert
        })
    }
    componentDidMount() {

    }
    handleChange = (selectedOption, type) => {
        let updatedSelect = { ...this.state.form };
        updatedSelect[type].value = selectedOption;
        this.checkValidation(updatedSelect, type);
        this.setState(
            { form: updatedSelect }
        );
    };
    checkValidation = (updatedstate, type) => {
        updatedstate[type].touched = true;
        if (updatedstate[type].value === "" && updatedstate[type].isValidationRequire) {
            updatedstate[type].valid = false;
            updatedstate[type].message = "Please enter " +  updatedstate[type].label + ".";
        } else {
            updatedstate[type].message = "";
            updatedstate[type].valid = true;
        }
    }
    changeHandler = (event, type) => {
        const updatedstate = { ...this.state.form };
        updatedstate[type].value = event.target.value;
        this.checkValidation(updatedstate, type);
        this.setState({
            form: updatedstate
        })
    }

    fuelDataChangeHandler = (event, index, type) => {
        const updatedstate = { ...this.state.form };
        updatedstate.fuelData.value[index][type] = event.target.value;
        this.setState({
            form: updatedstate
        })
    }

    checkBoxChangeHandler = (event, type) => {
        const updatedstate = { ...this.state.form };
        updatedstate[type].value = event.target.checked;
        this.checkValidation(updatedstate, type);
        if(type === "fuelSensor" && event.target.checked){
            updatedstate.fuelData.value = [{liter: '', reading: ''}];
        }else{
            updatedstate.fuelData.value = [];
        }
        this.setState({
            form: updatedstate
        })
    }


    dateChangeHandler = (moment) => {
        if (!this.state.form.installationDate.isDisabled) {
            const updatedstate = { ...this.state.form };
            updatedstate['installationDate'].value = convertDateToString(moment.toDate());
            this.checkValidation(updatedstate, 'installationDate');

            this.setState({
                form: updatedstate
            })
        }

    }
    doUpdateDevice = () => {
        this.setState({
            isLoading:true
        });
        const form = { ...this.state.form };


        const data = {
            name: form.deviceName.value,
            tag: form.tag.value,
            serialNo: form.serialNo.value,
            imei: form.imeiNo.value,
            speedLimit: form.speedlimit.value,
            holdTime: form.holdTime.value,
            type: form.deviceType.value.value,
            installationDate: form.installationDate.value,
            activationDay: form.activeDays.value,
            simNumber: form.simNo.value,
            fuelReading1: form.fuelReading1.value,
            fuelPercentage1: form.percentage1.value,
            fuelReading2: form.fuelReading2.value,
            fuelPercentage2: form.percentage2.value,
            backupDay: form.backupDays.value,
            renualAmount: form.amount.value,
            deviceId: this.props.location.state.deviceId,
            usersId: this.props.location.state.usersId,
            imsiNo: form.imsiNo.value,
            acReverse: form.acReverse.value,
            fillingThreshold: form.fillingThreshold.value,
            theftThreshold: form.theftThreshold.value,
        }
        
        if(form.fuelSensor.value && form.fuelData.value && form.fuelData.value.length){
            data.fuelData = form.fuelData.value.filter(item=> item.liter && item.reading);
        }
        console.log(data);
        
        sendAuthRequest(REQUESTURL.EDIT_DEVICE,data).then(response=>{
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"SUCCESS",
                    timeout:2000,
                    message: response.statusMessage,
                    isVisible:true
                }
            });
        }).catch(error => {
            this.setState({
                isLoading:false,
                alert:{
                    messageType:"ERROR",
                    timeout:2000,
                    message: error,
                    isVisible:true
                }
            });
        })
    }
    submitHandler = () => {

        let isValidType = true;
        const updatedstate = { ...this.state.form };
        for (let type in updatedstate) {
            this.checkValidation(updatedstate, type);
            if (updatedstate[type].valid === false) {
                isValidType = false;
                break;
            }
        }
        if (isValidType) {
            this.doUpdateDevice();
        } else {
            for (let type in updatedstate) {
                this.checkValidation(updatedstate, type);
            }
            this.setState({
                form: updatedstate
            });
        }
    }
    cancelHandler = () => {
        this.props.history.goBack();
    }

    fuelButtonHandler = (type, index) => {
        const updatedstate = { ...this.state.form };
        if(type === "plus"){
            updatedstate.fuelData.value.push({liter: '', reading: ''}); 
        }else{
            console.log(updatedstate.fuelData.value);
            updatedstate.fuelData.value.splice(index, 1);
            console.log(updatedstate.fuelData.value);
        }

        this.setState({
            form: updatedstate
        })
    }

    fuelDataRender(){
        if(this.state.form.fuelSensor.value){
            return (
                <div>
                    <div className='row'>
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Fuel Filling Threshold(per min)</label>
                                        <input type="number" value={this.state.form.fillingThreshold.value} className="form-control" onChange={(event) => { this.changeHandler(event, "fillingThreshold") }} />
                                        {/* {isFuelReading2Invalid ? <p className="errorMsg">{this.state.form.fuelReading2.message}</p> : null} */}
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Fuel Theft Threshold(per min)</label>
                                        <input type="number" value={this.state.form.theftThreshold.value} className="form-control" onChange={(event) => { this.changeHandler(event, "theftThreshold") }} />
                                        {/* {isPercentage2Invalid ? <p className="errorMsg">{this.state.form.percentage2.message}</p> : null} */}
                                    </div>
                                </div>
                        </div>
                        {this.state.form.fuelData.value.map((fuelReading, index, arr)=>{
                            return (
                                <div className='row'>
                                    <div className='col-lg-7 col-md-12 col-12'>
                                        <div className="form-group">
                                            <label className="mb-2 w-100">{`Fuel Reading ${index+1}`}</label>
                                            <div className='row row_margin_5px'>
                                                <div className='col-lg-5 col-md-5 col-5 col_padding_5px'>
                                                    <input type="number" placeholder='liter' value={fuelReading.liter} className="form-control" onChange={(event) => { this.fuelDataChangeHandler(event, index, "liter") }} />
                                                </div>
                                                <div className='col-lg-5 col-md-5 col-5 col_padding_5px'>
                                                    <input type="number" placeholder="reading" value={fuelReading.reading} className="form-control" onChange={(event) => { this.fuelDataChangeHandler(event, index, "reading") }} />
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-2 col_padding_5px'>
                                                    {(index < arr.length - 1) &&  
                                                        (
                                                            <button className='btn btn-secondary' onClick={(event)=> this.fuelButtonHandler("minus", index)}><i className={'fa fa-minus'}></i></button>
                                                        )
                                                    }
                                                    {!(index < arr.length - 1) &&  
                                                        (
                                                            <button className='btn btn-primary' onClick={(event)=> this.fuelButtonHandler("plus", index)}><i className={'fa fa-plus'}></i></button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
                
            )
        }
    }

    render() {
        const isDeviceNameInvalid = this.state.form.deviceName.message !== "" && this.state.form.deviceName.touched && !this.state.form.deviceName.valid;
        const isSerialNoInvalid = this.state.form.serialNo.message !== "" && this.state.form.serialNo.touched && !this.state.form.serialNo.valid;
        const isImeiNoInvalid = this.state.form.imeiNo.message !== "" && this.state.form.imeiNo.touched && !this.state.form.imeiNo.valid;
        const isSpeedlimitInvalid = this.state.form.speedlimit.message !== "" && this.state.form.speedlimit.touched && !this.state.form.speedlimit.valid;
        const isHoldTimeInvalid = this.state.form.holdTime.message !== "" && this.state.form.holdTime.touched && !this.state.form.holdTime.valid;
        const isInstallationDateInvalid = this.state.form.installationDate.message !== "" && this.state.form.installationDate.touched && !this.state.form.installationDate.valid;
        const isActiveDaysInvalid = this.state.form.activeDays.message !== "" && this.state.form.activeDays.touched && !this.state.form.activeDays.valid;
        const isBackupDaysInvalid = this.state.form.backupDays.message !== "" && this.state.form.backupDays.touched && !this.state.form.backupDays.valid;
        const isImsiNoInvalid = this.state.form.imsiNo.message !== "" && this.state.form.imsiNo.touched && !this.state.form.imsiNo.valid;
        const isSimNoInvalid = this.state.form.simNo.message !== "" && this.state.form.simNo.touched && !this.state.form.simNo.valid;
        const isAmountInvalid = this.state.form.amount.message !== "" && this.state.form.amount.touched && !this.state.form.amount.valid;
        const isDeviceTypeInvalid = this.state.form.deviceType.message !== "" && this.state.form.deviceType.touched && !this.state.form.deviceType.valid;
        return (
            <div className="add_device page_wrapper_height">
                <div className="title_heading">
                    <h2>Edit Device</h2>
                </div>
                <div className="custom_form inner_content_wrapper">
                {this.state.isLoading? <Loader></Loader> : null}
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <div className="inner_scroll_div">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Device Name <span className="red_text">*</span></label>
                                            <input type="text" value={this.state.form.deviceName.value} className="form-control" onChange={(event) => { this.changeHandler(event, "deviceName") }} />
                                            {isDeviceNameInvalid ? <p className="errorMsg">{this.state.form.deviceName.message}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Tag</label>
                                            <input type="text" className="form-control" onChange={(event) => { this.changeHandler(event, "tag") }} value={this.state.form.tag.value} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Serial No. <span className="red_text">*</span></label>
                                            <input type="number" value={this.state.form.serialNo.value} className="form-control" onChange={(event) => { this.changeHandler(event, "serialNo") }} disabled={this.state.form.serialNo.isDisabled} />
                                            {isSerialNoInvalid ? <p className="errorMsg">{this.state.form.serialNo.message}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">IMEI No.<span className="red_text">*</span></label>
                                            <input type="number" value={this.state.form.imeiNo.value} className="form-control" onChange={(event) => { this.changeHandler(event, "imeiNo") }} disabled={this.state.form.imeiNo.isDisabled} />
                                            {isImeiNoInvalid ? <p className="errorMsg">{this.state.form.imeiNo.message}</p> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Over Speed Limit(kmh) <span className="red_text">*</span></label>
                                                    <input type="number" value={this.state.form.speedlimit.value} className="form-control" onChange={(event) => { this.changeHandler(event, "speedlimit") }} />
                                                    {isSpeedlimitInvalid ? <p className="errorMsg">{this.state.form.speedlimit.message}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">Hold Time(minutes) <span className="red_text">*</span></label>
                                                    <input type="number" value={this.state.form.holdTime.value} className="form-control" onChange={(event) => { this.changeHandler(event, "holdTime") }} />
                                                    {isHoldTimeInvalid ? <p className="errorMsg">{this.state.form.holdTime.message}</p> : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Type <span className="red_text">*</span></label>
                                                    <Suggestion handleChange={this.handleChange} value={this.state.form.deviceType.value}
                                                        options={deviceType} isSearchable={true} type="deviceType" />
                                                    {isDeviceTypeInvalid ? <p className="errorMsg">{this.state.form.deviceType.message}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">Installation Date <span className="red_text">*</span></label>
                                                    <Datetime
                                                        onChange={this.dateChangeHandler}
                                                        dateFormat={"DD MMM, YYYY"}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        inputProps={{ readOnly: true, placeholder: "DD/MM/YYYY", disabled: this.state.form.installationDate.isDisabled }}
                                                        renderInput={CalenderInput}
                                                        isValidDate={validateDate}
                                                        value={new Date(this.state.form.installationDate.value)} open={this.state.form.installationDate.isDisabled ? false : undefined} />
                                                    {isInstallationDateInvalid ? <p className="errorMsg">{this.state.form.installationDate.message}</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Active Days <span className="red_text">*</span></label>
                                                    <input type="number" value={this.state.form.activeDays.value} className="form-control" onChange={(event) => { this.changeHandler(event, "activeDays") }} />
                                                    {isActiveDaysInvalid ? <p className="errorMsg">{this.state.form.activeDays.message}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">Backup Days <span className="red_text">*</span></label>
                                                    <input type="number" value={this.state.form.backupDays.value} className="form-control" onChange={(event) => { this.changeHandler(event, "backupDays") }} />
                                                    {isBackupDaysInvalid ? <p className="errorMsg">{this.state.form.backupDays.message}</p> : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">IMSI Number <span className="red_text">*</span></label>
                                                    <input type="number" value={this.state.form.imsiNo.value} className="form-control" onChange={(event) => { this.changeHandler(event, "imsiNo") }} />
                                                    {isImsiNoInvalid ? <p className="errorMsg">{this.state.form.imsiNo.message}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">SIM Number <span className="red_text">*</span></label>
                                                    <input type="number" value={this.state.form.simNo.value} className="form-control" onChange={(event) => { this.changeHandler(event, "simNo") }} />
                                                    {isSimNoInvalid ? <p className="errorMsg">{this.state.form.simNo.message}</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2 w-100">Fuel Reading 1</label>
                                                    <input type="number" value={this.state.form.fuelReading1.value} className="form-control" onChange={(event) => { this.changeHandler(event, "fuelReading1") }} />
                                                    {/* {isFuelReading1Invalid ? <p className="errorMsg">{this.state.form.fuelReading1.message}</p> : null} */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">Percentage 1</label>
                                                    <input type="number" value={this.state.form.percentage1.value} className="form-control" onChange={(event) => { this.changeHandler(event, "percentage1") }} />
                                                    {/* {isPercentage1Invalid ? <p className="errorMsg">{this.state.form.percentage1.message}</p> : null} */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">Fuel Reading 2</label>
                                                    <input type="number" value={this.state.form.fuelReading2.value} className="form-control" onChange={(event) => { this.changeHandler(event, "fuelReading2") }} />
                                                    {/* {isFuelReading2Invalid ? <p className="errorMsg">{this.state.form.fuelReading2.message}</p> : null} */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <label className="mb-2">Percentage 2</label>
                                                    <input type="number" value={this.state.form.percentage2.value} className="form-control" onChange={(event) => { this.changeHandler(event, "percentage2") }} />
                                                    {/* {isPercentage2Invalid ? <p className="errorMsg">{this.state.form.percentage2.message}</p> : null} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2"> Settings</label>
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    <label className="check_container"> AC Reverse
                                                        <input type="checkbox" checked={this.state.form.acReverse.value} onChange={(event) => { this.checkBoxChangeHandler(event, "acReverse") }}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <label className="check_container"> Fuel Sensor
                                                        <input type="checkbox" checked={this.state.form.fuelSensor.value} onChange={(event) => { this.checkBoxChangeHandler(event, "fuelSensor") }}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2"> Renewal Amount <span className="red_text">*</span></label>
                                            <input type="number" value={this.state.form.amount.value} className="form-control" onChange={(event) => { this.changeHandler(event, "amount") }} disabled={this.state.form.amount.isDisabled} />
                                            {isAmountInvalid ? <p className="errorMsg">{this.state.form.amount.message}</p> : null}
                                        </div>
                                    </div>
                                </div>
                                {this.fuelDataRender()}
                                <div className="submit_btn">
                                    <button className="btn btn-blue mr-2" onClick={this.submitHandler} type="button">Save</button>
                                    <button className="btn btn-gray mr-2" onClick={this.cancelHandler} type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </PerfectScrollbar>

                </div>
            </div>
        );
    }
}


export default EditDevice;