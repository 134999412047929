import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import loginLock from '../../assets/images/login.png';
import logo from '../../assets/images/logo_light.png';
import Password from '../../component/PasswordComponent/Password';
import './Login.css';
import { validateEmail } from '../../util/Validation';
import Loader from '../../component/Loader/Loader';
import Alert from '../../component/Alert/Alert';
import { sendLoginRequest } from '../../util/API';
const Login = (props) => {


    const [ state, setState ] = useState({
        email:{
            value:"",
            valid:false,
            touched:false,
            message:undefined
        },
        password:{
            value:"",
            valid:false,
            touched:false,
            message:undefined
        }
    });

    const [alert, setAlert] = useState({
       timeout:2000,
       message:"",
       messageType:"",
       isVisible:false
    });
    const [loading, setLoading] = useState({
        isVisible:false
     });

    const checkValidation = (updatedState, type) =>{
        //Update the Touched as true
        updatedState[type].touched = true;
        //Logic to check validation
        if(updatedState[type].value===""){
            //Value is in valid
            updatedState[type].valid=false;
            if(type === "email"){
                updatedState[type].message = "Please enter email address.";
            }else if(type === "password"){
                updatedState[type].message = "Please enter password.";
            }
        } else if(type==="email" && !validateEmail(updatedState[type].value)){
                updatedState[type].message = "Please enter valid email address.";
                updatedState[type].valid=false;
            
        }else{
            //Value is valid
            updatedState[type].message = "";
            updatedState[type].valid=true;
        }
    }

    const changeHandler = (event, type) => {
        //Copy the state
        const updatedState = {...state};
        // Fetch the value 
        const value = event.target.value;
        //Upudate the value in copy state
        updatedState[type].value = value;
        checkValidation(updatedState, type);
        setState(updatedState);
    }


    const doLogin = () => {
        setLoading({
            isVisible: true
        });
        const data = {
            username: state.email.value,
            password: state.password.value
        }
        sendLoginRequest(data).then(response=>{
            setLoading({
                isVisible: false
            });
            props.history.replace("/dashboard");
        }).catch(error=>{
            setLoading({
                isVisible: false
            });
            showAlert(error, 2000, "ERROR");
        });
    }

    const showAlert = (message, timeout, type)=>{
        setAlert({
            message:message,
            messageType: type,
            timeout: timeout,
            isVisible: true
        })
    }

    const loginHandler = () =>{
        if(state.email.valid && state.password.valid){
            //Call the server
            doLogin();
            // props.history.replace("/dashboard");
        }else{
            const updatedState = {...state};
            for(let type in updatedState){
                checkValidation(updatedState, type);
            }
            setState(updatedState);
        }
    }

    const onKeyPressed = (event, type) =>{
        if(event.keyCode === 13){
            loginHandler();
        }
    }
    const isEmailInValid = state.email.touched && !state.email.valid && state.email.message !== "";
    const isPasswordInValid = state.password.touched  && !state.password.valid && state.password.message !== "";

    const emailClasses = ["from-control"]

    if(isEmailInValid){
        emailClasses.push("invalid");
    }


    const onAlertClose = () => {
        setAlert({
            isVisible : false
        });
    }

    return(
        <div className="login">
          {loading.isVisible ? <Loader></Loader> : null} 
          {alert.isVisible ?  <Alert alert={alert} onClose={onAlertClose}></Alert> : null}
          <div className="bg_blue"></div>
          <div className="container">
            <div className="login_box">
                <div className="table_cell">
                    <div className="center_div">
                    <div className="row">
                        <div className="col-md-6 pr-0 align-self-center">
                            <div className="logo text-center">
                            <img src={logo} className="img-fluid" alt=""/>
                                <h1>Triangle</h1>
                            </div>
                        </div>
                        <div className="col-md-6 pl-0">
                            <div className="white_box text-center">
                                <img src={loginLock} className="img-fluid lock" alt=""/>
                                <h2 className="mt-4 mb-5">Sign in</h2>
                                <form>
                                    <div className="form-group mb-4">
                                        <input type="text" className={emailClasses.join(" ")} placeholder="Email address" onChange={(event)=>{changeHandler(event, "email")}} />
                                        { isEmailInValid ? <p className="errorMsg">{state.email.message}</p>: null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <Password placeholder="Password" onKeyDownCapture={onKeyPressed} changeHandler={changeHandler} isPasswordInValid={isPasswordInValid}/>
                                        { isPasswordInValid ? <p className="errorMsg">{state.password.message}</p> : null}
                                    </div>
                                    <Link to="/forgot-password" className="forgot_link">Forgot Password?</Link>
                                    <button type="button" className="btn btn-primary btn-lg btn-block" onClick={loginHandler}>Sign In</button>
                                </form>
                            </div>
                        </div>
                    </div>  
                    </div>
                </div>
                </div>
           </div>
        </div>
    );
}

export default Login;