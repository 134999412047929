import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Alert from '../../component/Alert/Alert';
import Loader from '../../component/Loader/Loader';
import mqttService from '../../util/MqttService';

class FactorySetting extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            topic: "",
            message: "",
            alert:{
                timeout:2000,
                message:"",
                messageType:"",
                isVisible:false
            }
        }
    }

    submitHandler = ()=>{
        this.setState({
            isLoading: true
        })
        console.log(this.state.topic);
        console.log(this.state.message);
        if(this.state.topic!="" && this.state.message!=""){
            let client = mqttService.getClient((error)=>{
                console.log(error);
            });
            client.stream.on("connect", (err) => {
                mqttService.publishMessage(this.state.topic, this.state.message, client);
                this.setState({
                    isLoading: false,
                    alert:{
                        messageType:"SUCCESS",
                        timeout:2000,
                        message: "Message Sent",
                        isVisible:true
                    }
                });
            });
        }
    }
    
    onCloseAlert = ()=>{
        this.setState({
            alert:{
                isVisible:false
            }
        })
    }
    cancelHandler=()=>{
        this.setState({
            topic: "",
            message: "",
        });
    }
    render(){
        return(
            <div className="reports_page page_wrapper_height">
                <div className="title_heading">
                    <h2>Factory Setting</h2>
                </div>
                {this.state.isLoading? <Loader></Loader> : null}
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                <div className="custom_form inner_content_wrapper">
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <div className="inner_scroll_div">
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100"> Topic</label>
                                                <input 
                                                    className="from-control" 
                                                    type="text" 
                                                    value={this.state.topic}
                                                    onChange={(event)=> this.setState({ topic: event.target.value })}
                                                />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100"> Message </label>
                                                <textarea 
                                                    className="from-control"
                                                    value={this.state.message}
                                                    onChange={(event)=> this.setState({ message: event.target.value })}
                                                >
                                                </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit_btn">
                                    <button className="btn btn-blue mr-2" onClick={this.submitHandler} type="button">Send</button>
                                    <button className="btn btn-gray mr-2" onClick={this.cancelHandler} type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>

        )
    }
}

export default FactorySetting;