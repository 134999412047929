import React, { Component } from 'react';
import './User.css';
import { Redirect, Switch } from 'react-router-dom';
import getRoutes from './UserRoute';
import  ProtectedRoute from '../../component/Route/ProtectedRoute';
class Users extends Component{
    
    render(){
        const UserRoute = getRoutes(this.props.match.url).map(ele=>{
            return <ProtectedRoute map={ele} key={ele.label} path={ele.to} Component={ele.component}/>
        })
          return  (
                <Switch>
                    {UserRoute}
                    <Redirect from={`${this.props.match.url}/`}  to={`${this.props.match.url}/manage-user`}></Redirect>
                </Switch>
          );
        
    }
}
export default Users;