import React, {Component} from 'react';
import  {Switch, Redirect } from 'react-router-dom';
import getRoutes from './SaleDeviceRoute';
import  ProtectedRoute from '../../component/Route/ProtectedRoute';

class SaleDevices extends Component{
    render(){
        const deviceSaleRoute = getRoutes(this.props.match.url).map(ele=>{
            return <ProtectedRoute map={ele} key={ele.label} path={ele.to} Component={ele.component}/>
        })
    return (
        <Switch>
            {deviceSaleRoute}
            <Redirect from={`${this.props.match.url}/`}  to={`${this.props.match.url}/manage-saledevice`}></Redirect>
        </Switch>
    );
}
}

export default SaleDevices;

