import mqtt from "mqtt";
const websocketUrl = "wss://triangle.net.in:9001/mqtt";
const apiEndpoint = "";
const username="device";

function getClient(errorHandler) {
  const client = mqtt.connect(websocketUrl,{username:username,password:username});
  client.stream.on("error", (err) => {
    errorHandler(`Connection to ${websocketUrl} failed`);
    client.end();
  });
  return client;
}

function subscribe(client, topic, errorHandler) {
  const callBack = (err, granted) => {
    if (err) {
      errorHandler("Subscription request failed");
    }
  };
  return client.subscribe(apiEndpoint + topic, callBack);
}

function onMessage(client, callBack) {
  client.on("message", (topic, message, packet) => {
    callBack(new TextDecoder("utf-8").decode(message));
  });
}

function unsubscribe(client, topic) {
  client.unsubscribe(apiEndpoint + topic);
}

function closeConnection(client) {
  client.end();
}

function publishMessage(topic, message, client){
  client.publish(topic, message, null, (err)=>{
    console.log("Message Sent");
  });
}

const mqttService = {
  getClient,
  subscribe,
  onMessage,
  unsubscribe,
  closeConnection,
  publishMessage
};
export default mqttService;
