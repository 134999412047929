import React from 'react';
import './ContentLoader.css';
import ContentLoader from '../../assets/images/ContentLoader.svg';
const DeviceContentLoader = () =>{
    return(
        
        <div className="col-xl-3 col-lg-6 col-md-6 col-12 device_card_width">
            <div className="device_card svg_img">
                <img src={ContentLoader} alt="" />
            </div>
        </div>
    );
}

export default DeviceContentLoader;