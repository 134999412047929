import React, { Component } from 'react';
import Datetime from 'react-datetime';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Accordion, useAccordionToggle } from 'react-bootstrap';
import CalenderInput from '../../../component/CalenderInput/CalenderInput';
import { convertDateToString } from '../../../util/DateUtil';

const CustomToggle = (props) => {
    const decoratedOnClick = useAccordionToggle(props.eventKey);
    return (
        <div className="ml-auto dropdown_btn" onClick={decoratedOnClick}>
            {props.children}
        </div>
    );
}

class UserFilter extends Component {
    
    constructor(props){
        super(props);
        this.state={
            email:"",
            username:"",
            fromDate:"",
            toDate:"",
        }
    }

    fromDateChangeHandler = (moment) =>{
        if(moment){
            this.setState({
                fromDate: convertDateToString(moment.toDate())
            });
        }
        
    }
    toDateChangeHandler = (moment) =>{
        this.setState({
            toDate: convertDateToString(moment.toDate())
        });
    }

    validateDate = (date) =>{
        return date && (new Date(this.state.fromDate)).getTime() < date.toDate().getTime();
    }


    applyFilterHandler = (event) =>{
        if(this.state.email!="" || this.state.username!="" || this.state.fromDate!="" || this.state.toDate !=""){
            let data = {
                "email":this.state.email,
                "username":this.state.username,
                "fromDate":this.state.fromDate,
                "toDate":this.state.toDate
            }
            this.props.applyFilter(data);
        }
    }

    clearAllHandler = () =>{
        this.setState({
            email:"",
            username:"",
            fromDate:"",
            toDate:"",
        }, ()=>{
            this.props.clearAllHandler();
        })
        
    }

    render(){
        return (
            <div className="filter_sidebar_scroll">
                <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <div className="filter_sidebar_padding">
                        
                        <div className="filter_heading d-flex">
                            <h4>Filters</h4>
                            <button className="label_button ml-auto teal_text" onClick={this.clearAllHandler}>Clear all</button>
                        </div>
                        <hr />
                        <div className="filters_accordian">
                            <Accordion defaultActiveKey="0">
                                <div className="filter_row">
                                    <div className="filter_head d-flex mb-3">
                                        <p className="title">Name</p>
                                        <CustomToggle eventKey="0"><i className="fas fa-chevron-down"></i></CustomToggle>
                                    </div>
                                    <Accordion.Collapse eventKey="0">
                                        <div className="filter_body">
                                            <input type="text" value={this.state.username} onChange={(event)=> this.setState({username:event.target.value})} placeholder="Search by name" className="form-control" />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="filter_row">
                                    <div className="filter_head d-flex mb-3">
                                        <p className="title">Date</p>
                                        <CustomToggle eventKey="3"><i className="fas fa-chevron-down"></i></CustomToggle>
                                    </div>
                                    <Accordion.Collapse eventKey="3">
                                        <div className="filter_body date_filter">
                                            <Datetime dateFormat={"DD MMM, YYYY"}
                                                value={this.state.fromDate} 
                                                closeOnSelect={true} 
                                                onChange={this.fromDateChangeHandler} 
                                                timeFormat={false} 
                                                inputProps={{ placeholder: "From Date" }} 
                                                renderInput={CalenderInput} 
                                                />
                                            <Datetime dateFormat={"DD MMM, YYYY"} 
                                                value={this.state.toDate}
                                                closeOnSelect={true} 
                                                timeFormat={false} 
                                                inputProps={{ placeholder: "To Date" }} 
                                                isValidDate={this.validateDate} 
                                                renderInput={CalenderInput} 
                                                onChange={this.toDateChangeHandler} 
                                                />
    
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="filter_row">
                                    <div className="filter_head d-flex mb-3">
                                        <p className="title">Email</p>
                                        <CustomToggle eventKey="1"><i className="fas fa-chevron-down"></i></CustomToggle>
                                    </div>
                                    <Accordion.Collapse eventKey="1">
                                        <div className="filter_body">
                                            <input type="text" 
                                                placeholder="Search by email" 
                                                value={this.state.email} 
                                                onChange={(event)=> this.setState({email:event.target.value})} 
                                                className="form-control" />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
    
                        </div>
                    
                    </div>
                    
                </PerfectScrollbar>
                <div className="apply_btn">
                    <button className="btn btn-blue btn-block" type="button" onClick={this.applyFilterHandler}>Apply</button>
                </div>
                </div>
        );
    }
    
}

export default UserFilter;