import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './expiredDeviceModal.css';


const ExpiredDeviceModal = (props) => {

    return (
        <Modal
            show="true"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton onHide={props.onHide}>
             <Modal.Title id="contained-modal-title-vcenter">
                Service will be suspended for non-payments
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-2'>
                    <p className="text-danger">Expired devices will be disconnected automatically by system.</p>
                </div>
                <div className='expiredDeviceTableContainer'>
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Expiry Date</th>
                                <th>Days Left</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.deviceList.map((device, index)=>{
                                    return(
                                        <tr key={index} className={device.days<0? 'expiredDeviceRow': ''}>
                                            <td>{device.name}</td>
                                            <td>{device.expirationDate}</td>
                                            <td>{device.days}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide} type="button" className="btn btn-gray">Close</button>
            </Modal.Footer>
        </Modal>
    )

}

export default ExpiredDeviceModal;