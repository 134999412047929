import React from 'react';
import Device from '../../../component/DeviceComponent/Device';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Alert from '../../../component/Alert/Alert';
import { sendAuthRequest } from '../../../util/API';
import { REQUESTURL } from '../../../constant/REQUEST-URL';
import ContentLoader from '../../../component/ContentLoader/ContentLoader';
import ExpiredDeviceModal from '../../../component/DeviceComponent/ExpiredDeviceModal/ExpiredDeviceModal';
const LIMIT_DEVICE = 8;
class ManageHomeDevice extends React.Component {

    state = {
        deviceList: [],
        isLoading: true,
        alert: {
            timeout: 2000,
            message: "",
            messageType: "",
            isVisible: false
        },
        currentPage: 1,
        totalDevice: 0,
        searchText:"",
        isFilterApplied: false,
        expiredDeviceList: []
    }

    onCloseAlert = () => {
        const alert = {
            ...this.state.alert,
            isVisible: false
        }
        this.setState({
            alert: alert
        })
    }

    sendRequestData = (isNext) => {
        const data = {
            currentPage: isNext ? this.state.currentPage + 1 : this.state.currentPage - 1,
            limit: LIMIT_DEVICE,
            nextPage: isNext,
            lastItemId: isNext ? this.state.deviceList[this.state.deviceList.length - 1].deviceId : this.state.deviceList[0].deviceId,
            activeDevice:true
        }
        this.setState({
            isLoading: true,
            deviceList: [],
        });
        this.fetchDevices(data)
    }

    fetchDevices = (data) => {
        sendAuthRequest(REQUESTURL.FETCH_DEVICES_V2, data).then(response => {
            console.log(response);
            if (response.result) {
                this.setState({
                    isLoading: false,
                    deviceList: response.result.devices,
                    totalDevice: response.result.totalDevices,
                    currentPage: response.result.currentPage
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }

        }).catch(error => {
          
            const alert = {
                ...this.state.alert,
                message: error,
                messageType: "ERROR",
                isVisible: true
            };
            this.setState({
                isLoading: false,
                alert: alert
            })
        });
    }

    fetchExpiredDeviceList(){
        sendAuthRequest(REQUESTURL.FETCH_EXPIRED_DEVICE_LIST, {}).then(response => {
            if (response.result && response.result.length>0) {
                this.setState({expiredDeviceList: response.result});
            } 
        }).catch(error => {
        });
    }


    componentDidMount() {

        const data = {
            currentPage: this.state.currentPage,
            limit: LIMIT_DEVICE,
            activeDevice:true
        }
        this.fetchDevices(data);
        this.fetchExpiredDeviceList();
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          this.searchDevices();
        }
      }
      
    searchDevices = (event)=>{
        const data = {
            currentPage: 1,
            limit: LIMIT_DEVICE,
            searchText: this.state.searchText,
            filterApplied:true,
            activeDevice:true
        }
        this.setState({
            currentPage: 1,
            isLoading: true,
            deviceList: [],
            isFilterApplied:true
        });
        this.fetchDevices(data);

    }

    resetFilter = () =>{
        const data = {
            currentPage: 1,
            limit: LIMIT_DEVICE,
            activeDevice:true
        }
        this.setState({
            currentPage: 1,
            isLoading: true,
            deviceList: [],
            isFilterApplied:false,
            searchText:""
        });
        this.fetchDevices(data);
    }

    render() {
        let devices = [];
        if (this.state.deviceList.length > 0) {
            devices = this.state.deviceList.map(device => {
                return <Device key={device.deviceId} device={device}></Device>
            });
        }

        const disableClasses = "pagination_btn btn disable";
        const enableClasses = "pagination_btn btn";

        return (

            <div className="dashboard_page page_wrapper_height">
                <div className="search_pagination">
                    <div className="dashboard_search">
                        <input type="text" className="form-control mr-2" placeholder="Search..." value={this.state.searchText} onChange={ (event)=>{this.setState({searchText:event.target.value})} } onKeyPress={this.handleKeyPress}/>
                        <button type="button" className="btn btn-green mr-2" onClick={this.searchDevices}><span className="d-none d-sm-block">Search</span> <i className="fa fa-search d-block d-sm-none" aria-hidden="true"></i></button>
                        <button type="button" className="btn btn-gray" onClick={this.resetFilter}><span className="d-none d-sm-block">Reset</span> <i className="fa fa-undo d-block d-sm-none" aria-hidden="true"></i></button>
                    </div>
                    <div className="pagination ml-auto">
                        <button type="button" className={this.state.currentPage === 1 ? disableClasses : enableClasses} disabled={this.state.currentPage === 1} onClick={() => { this.sendRequestData(false) }}>Previous</button>
                        <button type="button" className={(this.state.currentPage * LIMIT_DEVICE) < this.state.totalDevice ? enableClasses : disableClasses} disabled={!((this.state.currentPage * LIMIT_DEVICE) < this.state.totalDevice)} onClick={() => { this.sendRequestData(true) }}>Next</button>
                    </div>
                </div>
                {this.state.alert.isVisible ? <Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
                <div className="inner_content_wrapper">
                    <div className="dashboard_home inner_content_list">
                        <PerfectScrollbar options={{ suppressScrollX: true }}>
                            <div className="inner_scroll_div">
                                <div className="row">
                                    {this.state.isLoading ? <ContentLoader size={4} type="DEVICE" /> : null}

                                    {devices}
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
                {this.state.expiredDeviceList.length>0 && (
                    <ExpiredDeviceModal deviceList={this.state.expiredDeviceList} onHide={() => this.setState({expiredDeviceList:[]})}/>
                )}
            </div>

        )
    }
}
const checkForRender = (prevProps, nextProps) => {
    return true;
}
export default React.memo(ManageHomeDevice, checkForRender);