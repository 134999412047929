import React, { Component } from "react";
import Datetime from "react-datetime";
import PerfectScrollbar from "react-perfect-scrollbar";
import CalenderInput from "../../../component/CalenderInput/CalenderInput";
import Suggestion from "../../../component/Suggestion/Suggestion";
import Loader from '../../../component/Loader/Loader';
import { REQUESTURL } from "../../../constant/REQUEST-URL";
import { sendAuthRequest } from "../../../util/API";
import {
  getDates,
  convertDateToString,
  getNewDateWithZeroTime,
} from "../../../util/DateUtil";
import PlayBackMap from "./PlayBackMap";
import { connect } from "react-redux";
const SELECTDAY = [
  { value: "TODAY", label: "Today" },
  { value: "YESTERDAY", label: "Yesterday" },
  { value: "LAST_WEEK", label: "Last Week" },
  { value: "LAST_MONTH", label: "Last Month" },
];
const SPEED = [
  { value: "FAST", label: "Fast" },
  { value: "MEDIUM", label: "Medium" },
  { value: "SLOW", label: "Slow" },
];
class Playback extends Component {
  state = {
    form: {
      selectDay: {
        value: SELECTDAY[0],
        message: undefined,
        touched: false,
        valid: false,
        isValidationRequire: false,
        label: "Select Day",
      },
      speed: {
        value: SPEED[0],
        message: undefined,
        touched: false,
        valid: false,
        isValidationRequire: false,
        label: "Speed",
      },
      startDate: {
        value: getNewDateWithZeroTime(),
        message: undefined,
        touched: false,
        valid: false,
        isValidationRequire: false,
        label: "Start Date",
      },
      endDate: {
        value: new Date(),
        message: undefined,
        touched: false,
        valid: false,
        isValidationRequire: false,
        label: "End Date",
      },
      hold: {
        value: 5,
        message: undefined,
        touched: false,
        valid: false,
        isValidationRequire: false,
        label: "Hold",
      },
      overSpeed: {
        value: 70,
        message: undefined,
        touched: false,
        valid: false,
        isValidationRequire: false,
        label: "Over Speed",
      },
    },
    showMap:false,
    data:[],
    loading:false,
  };
  cancelHandler = () => {
    this.props.history.goBack();
  };
  handleChange = (selectedOption, type) => {
    let updatedSelect = { ...this.state.form };
    updatedSelect[type].value = selectedOption;
    if ("selectDay" === type) {
      updatedSelect["startDate"].value = getDates(selectedOption.value).from;
      updatedSelect["endDate"].value = getDates(selectedOption.value).to;
    }
    this.setState({ form: updatedSelect });
  };
  startDateChangeHandler = (moment) => {
    const updatedstate = { ...this.state.form };
    updatedstate["startDate"].value = convertDateToString(moment.toDate());
    // this.checkValidation(updatedstate, 'installationDate');
    this.setState({
      form: updatedstate,
    });
  };
  endDateChangeHandler = (moment) => {
    const updatedstate = { ...this.state.form };
    updatedstate["endDate"].value = convertDateToString(moment.toDate());
    // this.checkValidation(updatedstate, 'installationDate');
    this.setState({
      form: updatedstate,
    });
  };

  validateDate = (date) => {
    var fromDate = new Date(this.state.form.startDate.value);
    fromDate.setDate(fromDate.getDate() - 1);
    var endDate = new Date(this.state.form.startDate.value);
    endDate.setDate(endDate.getDate() + 30);
    return (
      date &&
      fromDate.getTime() < date.toDate().getTime() &&
      date.toDate().getTime() < endDate.getTime()
    );
  };

  submitHandler = (event) => {
      let data = {
        deviceId: this.props.device.deviceId,
        startDate: convertDateToString(new Date(this.state.form.startDate.value)),
        endDate: convertDateToString(new Date(this.state.form.endDate.value)),
      };
      this.setState({
        loading:true
      });
      this.fetchPlayBackData(data);
  }

  fetchPlayBackData(data) {
   
    sendAuthRequest(REQUESTURL.PLAYBACK, data)
      .then((response) => {
        
        if(response.status==1){
            let data = response.result;
            this.setState({
                loading:false,
                showMap:true,
                data:data,
                submitBtn:"Play"
            });
        }
      })
      .catch((error) => {
        this.setState({
          loading:false
        });
        console.log(error);
      });
  }

  holdChangeHandler = (event) =>{
    let value = event.target.value;
    if(value<1){
      value=1;
    }
    const updatedstate = { ...this.state.form };
    updatedstate["hold"].value = value;
    this.setState({
      form: updatedstate,
    })
  }

  overSpeedChangeHandler = (event) =>{
    const updatedstate = { ...this.state.form };
    updatedstate["overSpeed"].value = event.target.value;
    this.setState({
      form: updatedstate,
    });
  }

  clearClickHandler= ()=>{
    this.setState({
      form: {
        selectDay: {
          value: SELECTDAY[0],
          message: undefined,
          touched: false,
          valid: false,
          isValidationRequire: false,
          label: "Select Day",
        },
        speed: {
          value: SPEED[0],
          message: undefined,
          touched: false,
          valid: false,
          isValidationRequire: false,
          label: "Speed",
        },
        startDate: {
          value: getNewDateWithZeroTime(),
          message: undefined,
          touched: false,
          valid: false,
          isValidationRequire: false,
          label: "Start Date",
        },
        endDate: {
          value: new Date(),
          message: undefined,
          touched: false,
          valid: false,
          isValidationRequire: false,
          label: "End Date",
        },
        hold: {
          value: 5,
          message: undefined,
          touched: false,
          valid: false,
          isValidationRequire: false,
          label: "Hold",
        },
        overSpeed: {
          value: 70,
          message: undefined,
          touched: false,
          valid: false,
          isValidationRequire: false,
          label: "Over Speed",
        },
      },
      showMap:false,
      data:[]
    })
  }

  render() {
    return (
      <div className="playback page_wrapper_height">
        {this.state.loading ? <Loader></Loader> : null} 
        <div className="title_heading">
          <h2>Playback</h2>
          <div className="right_part ml-auto">
          <button
                    className="btn btn-blue mr-2"
                    type="button"
                    onClick={this.submitHandler}
                  >
                    Submit
                  </button>
                  <button className="btn btn-green mr-2" type="button" onClick={this.clearClickHandler}>
                    Clear
                  </button>
                  <button
                    className="btn btn-gray mr-2"
                    onClick={this.cancelHandler}
                    type="button"
                  >
                    Cancel
                  </button>
          </div>
        </div>
        <div className="custom_form inner_content_wrapper">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <div className="inner_scroll_div">
              <form>
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">
                        Start Date <span className="red_text">*</span>
                      </label>
                      <Datetime
                        onChange={this.startDateChangeHandler}
                        dateFormat={"DD MMM, YYYY"}
                        closeOnSelect={true}
                        timeFormat={"HH:mm:ss"}
                        inputProps={{ placeholder: "Start Date" }}
                        renderInput={CalenderInput}
                        value={new Date(this.state.form.startDate.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4  col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">
                        End Date <span className="red_text">*</span>
                      </label>
                      <Datetime
                        onChange={this.endDateChangeHandler}
                        dateFormat={"DD MMM, YYYY"}
                        closeOnSelect={true}
                        timeFormat={"HH:mm:ss"}
                        inputProps={{ placeholder: "End Date" }}
                        renderInput={CalenderInput}
                        isValidDate={this.validateDate}
                        value={new Date(this.state.form.endDate.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4  col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">Select Day</label>
                      <Suggestion
                        handleChange={this.handleChange}
                        options={SELECTDAY}
                        isSearchable={true}
                        value={this.state.form.selectDay.value}
                        type="selectDay"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4  col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">Speed</label>
                      <Suggestion
                        handleChange={this.handleChange}
                        options={SPEED}
                        isSearchable={true}
                        value={this.state.form.speed.value}
                        type="speed"
                        disabled={this.state.showMap}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4  col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">Over Speed (km/h)</label>
                      <input
                        type="number"
                        placeholder=""
                        className="form-control"
                        value={this.state.form.overSpeed.value}
                        onChange={this.overSpeedChangeHandler}
                        disabled={this.state.showMap}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <div className="form-group">
                      <label className="mb-2 w-100">Hold (min)</label>
                      <input
                        type="number"
                        placeholder=""
                        className="form-control"
                        value={this.state.form.hold.value}
                        onChange={this.holdChangeHandler}
                        disabled={this.state.showMap}
                      />
                    </div>
                  </div>
                </div>
                {this.state.showMap && (
                  <PlayBackMap 
                    showSpeed={this.state.form.speed.value.value} 
                    device={this.props.device} data={this.state.data} 
                    holdTime={this.state.form.hold.value}>
                  </PlayBackMap>)}
                
              </form>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}
export default connect((state, ownProps)=>{
  return {
    device: state.playback.device
  };
})(Playback);
