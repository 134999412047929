import Home from './view/Home/Home'
import FleetTracking from './view/FleetTracking/FleetTracking';
import Geofence from './view/Geofence/Geofence';
import Users from './view/Users/Users';
import Devices from './view/Devices/Devices';
import SaleDevices from './view/SaleDevices/SaleDevices';
import Reports from './view/Reports/Reports';
import Notifications from './view/Notifications/Notifications';
import Payments from './view/Payments/Payments';
import FactorySetting from './view/FactorySetting/FactorySetting';
import LiveTrackSharingSetting from './view/LiveTrackSetting/LiveTrackSharingSetting';


const getRoutes = (url) => {
    return  [
        {
            to: url + "/home",
            classes:"fas fa-tachometer-alt",
            label:"Dashboard",
            component:Home,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/fleet-tracking",
            classes:"fas fa-eye",
            label:"Fleet Tracking",
            component:FleetTracking,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER","ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/geofence",
            classes:"fas fa-map-marked-alt",
            label:"Geofence",
            component:Geofence,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/users",
            classes:"fas fa-user",
            label:"Users",
            component:Users,
            access: [ "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/devices",
            classes:"fas fa-mobile",
            label:"Devices",
            component:Devices,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/sale-devices",
            classes:"fas fa-layer-group",
            label:"Sale Devices",
            component:SaleDevices,
            access: ["ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/reports",
            classes:"fas fa-chart-pie",
            label:"Reports",
            component:Reports,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/livetracksharingsetting",
            classes:"fa fa-location-arrow",
            label:"Live Sharing Setting",
            component: LiveTrackSharingSetting,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/notifications",
            classes:"fas fa-bell",
            label:"Notifications",
            component:Notifications,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/payments",
            classes:"far fa-credit-card",
            label:"Payments",
            component:Payments,
            access: ["ROLE_USER", "ROLE_RETAILER", "ROLE_DEALER", "ROLE_ADMIN","ROLE_MEGA_DEALER"]
        },
        {
            to: url + "/factory-setting",
            classes:"fas fa-tools",
            label:"Factory Setting",
            component:FactorySetting,
            access: ["ROLE_ADMIN"]
        },
    ]
}
export default getRoutes;