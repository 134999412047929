import { cloneDeep } from "lodash";
import { REQUESTURL } from "../../constant/REQUEST-URL";
import { sendAuthRequest, sendRequest } from "../../util/API";
import { UPDATE_FORM_STATE, SHOW_HIDE_SHARING_POPUP, UPDATE_LIVE_SHARING_DETAILS, UPDATE_LIVE_TRACK_DATA, UPDATE_LIVE_SHARING_STATUS, UPDATE_LIVE_SHARING_LINKS } from "./Livetracksetting.type";
import { convertDateTimeToString, convertDateToString, getDeviceDate } from "../../util/DateUtil";
import mqttService from "../../util/MqttService";


export function initializeFormState(device) {
    return async (dispatch) => {
        try {
            const data = {
                deviceId: device.deviceId
            }
            const response = await sendAuthRequest(REQUESTURL.GET_LIVE_SHARING_LINK, data);
            if (response && response.status) {
                dispatch({
                    type: UPDATE_FORM_STATE,
                    payload: response.result
                });
            }
        } catch (err) {
            const date = new Date();
            date.setDate(date.getDate() + 1);

            const formState = {
                startDate: new Date(),
                endDate: date
            }
            dispatch({
                type: UPDATE_FORM_STATE,
                payload: formState
            });
        }

    }


}

export function generateLiveSharingLink(device, formState) {
    const updatedFormState = cloneDeep(formState);
    return async (dispatch) => {
        try {
            const data = {
                deviceId: device.deviceId,
                startDate: convertDateToString(new Date(formState.startDate)),
                endDate: convertDateToString(new Date(formState.endDate)),
            }
            const response = await sendAuthRequest(REQUESTURL.GENERATE_LIVE_SHARING_LINK, data);
            if (response && response.status) {
                updatedFormState["link"] = response.result.link;
            }
            dispatch({
                type: UPDATE_FORM_STATE,
                payload: updatedFormState
            });
        } catch (err) {
        }
    }

}

export function updateFormState(type, value, formState) {
    return async (dispatch) => {

        const updatedFormState = cloneDeep(formState);
        updatedFormState[type] = value;

        dispatch({
            type: UPDATE_FORM_STATE,
            payload: updatedFormState
        });
    }
}

export function showHideSharingPopup(action) {
    return {
        type: SHOW_HIDE_SHARING_POPUP,
        payload: action
    }
}


export function getLiveSharingDetails(id) {
    return async (dispatch) => {
        try {
            dispatch({
                type: UPDATE_LIVE_SHARING_STATUS,
                payload: "LOADING"
            })
            const data = {
                id
            };
            const response = await sendRequest(REQUESTURL.GET_LIVE_SHARING_DETAILS, data);
            if (response.result) {
                dispatch({
                    type: UPDATE_LIVE_SHARING_DETAILS,
                    payload: response.result
                })
                dispatch({
                    type: UPDATE_LIVE_SHARING_STATUS,
                    payload: "SUCCESS"
                })
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: UPDATE_LIVE_SHARING_STATUS,
                payload: err
            })
        }
    }
}

export function setLiveTrackData(data){
    const time = convertDateTimeToString(getDeviceDate(data[1]));//Date
    const latitude = data[2];//Latitude 
    const longitude = data[4];//Longitude
    const angle = data[6];//Angle
    const speed = data[7];//speed

    const liveTrackData = {
        time, location: [latitude, longitude], angle, speed
    }

    return {
        type: UPDATE_LIVE_TRACK_DATA,
        payload: liveTrackData
    }
}

export function getLiveSharingLinks(){
    return async (dispatch) => {
        try{
            const response = await sendAuthRequest(REQUESTURL.GET_LIVE_SHARING_LINKS, {});
            if(response && response.result){
                dispatch({
                    type: UPDATE_LIVE_SHARING_LINKS,
                    payload: response.result
                })
            }
        }catch(err){
            dispatch({
                type: UPDATE_LIVE_SHARING_LINKS,
                payload: []
            })
        }
    }
}

export function updateLiveShareItem(item, action){
    return async (dispatch) => {
        try{
            if(action == "DELETE"){
                await sendAuthRequest(REQUESTURL.DELETE_LIVE_SHARING_LINK, item);
            }else{
                await sendAuthRequest(REQUESTURL.UPDATE_LIVE_SHARING_LINK, item);
            }  
        }catch(err){
        }finally{
            dispatch(getLiveSharingLinks())
        }
    }
}