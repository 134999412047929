import React from 'react';
import { getColumn } from '../../util/ReportUtil';

export const RunningReport = (props) =>{

    const reportKeys = ["startDate","totalRunningDuration","tripDistance"];

    const columns = getColumn(props.reportType);
    
    let col = null;

    if(columns.length>0){
        col = columns.map(column=>{
            return (
                <th key={column.value}>{column.label}</th>
            )
        })
    }

    let rowData = null; 
    let totalRunningDuration = 0;
    let totalTripDistance = 0;
    let totalHours = 0;
    let totalDay = 0;
    let totalResult = "";
    if(props.data && props.data.length>0){

        const reportData = props.data;
        reportData.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a[reportKeys[0]]) - new Date(b[reportKeys[0]]);
        });

        

        rowData = reportData.map((value, index, arr)=>{
            let colData = [];
            // id
            colData.push((<td key="id">{index+1}</td>));
            // Start Date
            colData.push((<td key={reportKeys[0]}>{value[reportKeys[0]]}</td>));
            // Total Running Duration
            let timeMinute = +value[reportKeys[1]];
            totalRunningDuration = totalRunningDuration + timeMinute;
            let totalHours = 0;
            while(timeMinute>=60){
                totalHours = totalHours + 1;
                timeMinute = timeMinute - 60;
            }
            colData.push((<td key={reportKeys[1]}>{totalHours + " Hours " + timeMinute + " Min"}</td>));
            // Total Trip Distance
            colData.push((<td key={reportKeys[2]}>{value[reportKeys[2]] + " Km"}</td>));
            totalTripDistance = totalTripDistance + (+value[reportKeys[2]]);
           
            return (
                <tr key={index}>
                    {colData}    
                </tr>
            )
        });
        
        if(rowData!=null){
            totalHours = 0;
            while(totalRunningDuration>=60){
                totalHours = totalHours + 1;
                totalRunningDuration = totalRunningDuration - 60;
            }
            totalResult =  totalHours + " Hours " + totalRunningDuration + " Min";

            totalTripDistance = totalTripDistance.toFixed(2);
        }
    }else{
        rowData= (
            <tr>
                <td className="no_data" colSpan="4"> No Data Found! </td>
            </tr>
        )
    }

    return (
        <table className="table table-bordered" id="running_report">
            <thead>
                <tr>
                    {col}
                </tr>
            </thead>
            <tbody>
                
                    {rowData}
                    {(props.data && props.data.length>0) && (
                        <tr className="totalRow">
                            <td colSpan="2">
                                <center>Total</center>
                            </td>
                            <td>
                                {totalResult}
                            </td>
                            <td>
                                {totalTripDistance + " Km"}
                            </td>
                        </tr>
                    )}
                    
            </tbody>
        </table>
    )

}