import React, { useState } from "react";
import Header from "../../component/Header/Header";
import SideDrawer from "../../component/SideDrawer/SideDrawer";
import { Switch, Redirect } from "react-router-dom";
import getRoutes from "../../Routes";
import ProtectedRoute from "../../component/Route/ProtectedRoute";

import "./Dashboard.css";

const Dashboard = (props) => {
  const [state, setSideToggle] = useState({
    sideToggle: window.innerWidth <= 767 ? false : true,
  });

  const sidebarToggleHandler = () => {
    setSideToggle((prevState) => {
      return {
        sideToggle: !prevState.sideToggle,
      };
    });
  };

  const sidebarMobileToggleHandler = () => {
    if (window.innerWidth <= 767) {
      setSideToggle((prevState) => {
        return {
          sideToggle: false,
        };
      });
    }
  };

  const routes = getRoutes(props.match.url).map((ele) => {
    return (
      <ProtectedRoute
        map={ele}
        key={ele.to}
        path={ele.to}
        Component={ele.component}
      />
    );
  });

  const dashboardClasses = ["dashboard"];

  if (state.sideToggle) {
    dashboardClasses.push("open-sidebar");
  } else {
    dashboardClasses.push("close-sidebar");
  }

  return (
    <div className={dashboardClasses.join(" ")}>
      <Header toggleHandler={sidebarToggleHandler}></Header>
      <SideDrawer toggleHandler={sidebarMobileToggleHandler}></SideDrawer>

      <div className="content_card">
        <Switch>
          {routes}
          <Redirect
            from={props.match.url + "/"}
            to={props.match.url + "/home"}
          ></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
