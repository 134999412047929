import { REQUESTURL } from "../constant/REQUEST-URL";

export const ReportTypes = [
    { value: "RUNNING_REPORT", label: "Running Report" },
    { value: "TRIP_REPORT", label: "Trip Report" },
    { value: "FUEL_REPORT", label: "Fuel Report" },
    { value: "STOP_REPORT", label: "Stop Report" },
    { value: "SPEED_REPORT", label: "Speed Report" },
    { value: "AC_REPORT", label: "AC Report" },
    { value: "IGNITION_REPORT", label: "Ignition Report" },
    { value: "FUEL_SENSOR_REPORT", label: "Fuel Sensor Report" }
    // { value: "TEMPERATURE_REPORT", label: "Temperature Report" },
];


export const isTable = (reportType) =>{
    if("FUEL_REPORT" === reportType || "TEMPERATURE_REPORT" === reportType || "SPEED_REPORT" === reportType || "FUEL_SENSOR_REPORT" === reportType ){
        return false;
    }
    return true;
}

export const isFuelReport = (reportType) =>{
    if("FUEL_REPORT" === reportType){
        return true;
    }
    return false;
}


export const getColumn = (reportType) => {

    let col = [];
    switch(reportType){
        case "RUNNING_REPORT":
            col=getRunningReportCol();
            break;
        case "STOP_REPORT":
            col=getStopReportCol();
            break;
        case "SPEED_REPORT":
            col=getSpeedVsDistanceCol();
            break;
        case "AC_REPORT":
            col=getAcCol();
            break;
        case "IGNITION_REPORT":
            col=getIgnitionCol();
            break;
        case "TRIP_REPORT":
            col=getTripReportCol();
            break;
        default:
            col=[];
    }
    return col;
}
export const getRunningReportCol = ()=>{
    return [
        { value: "id", label: "ID #" },
        { value: "startDate", label: "Date" },
        { value: "runningDuration", label: "Running Duration" },
        { value: "tripDistance", label: "Trip Distance" },
    ];
}

export const getStopReportCol = ()=>{
    return [
        { value: "id", label: "ID #", class:"stop_id_col" },
        { value: "date", label: "Date", class:"stop_date_col" },
        { value: "stopDuration", label: "Total Stop Duration", class:"stop_totalStop_col" },
        { value: "latlong", label: "Latitude,Longitude", class:"stop_latlong_col" },
    ];
}

export const getTripReportCol = ()=>{
    return [
        { value: "id", label: "ID #" },
        { value: "startTime", label: "Start Time" },
        { value: "endTime", label: "End Time" },
        { value: "tripState", label: "Trip State" },
        { value: "duration", label: "Duration" },
        { value: "runningKm", label: "RunningKM" },
        { value: "startLocation", label: "Start Location" },
        { value: "endLocation", label: "End Location" },
    ];
}


export const getSpeedVsDistanceCol = ()=>{
    return [
        { value: "id", label: "ID #" },
        { value: "date", label: "Date" },
        { value: "one", label: "0-20" },
        { value: "two", label: "21-40" },
        { value: "three", label: "41-60" },
        { value: "four", label: "61-80" },
        { value: "five", label: "81-100" },
        { value: "six", label: "101-120" },
        { value: "seven", label: "121-140" },
        { value: "eight", label: "141-160" },
        { value: "nine", label: "161-180" },
        { value: "ten", label: "181-200" },
        { value: "totalRunningKm", label: "Total Running(km)" },
    ];
}

export const getAcCol = ()=>{
    return [
        { value: "id", label: "ID #" },
        { value: "startDate", label: "Start Date" },
        { value: "endDate", label: "End Date" },
        { value: "onDuration", label: "Total AC On Duration" },
    ];
}

export const getIgnitionCol = ()=>{
    return [
        { value: "id", label: "ID #" },
        { value: "startDate", label: "Start Date" },
        { value: "endDate", label: "End Date" },
        { value: "onDuration", label: "Total Ignition On Duration" },
    ];
}

export const getReportApiEndPoint = (reportType) =>{
    let api = "";
    switch(reportType){
        case "RUNNING_REPORT":
            api=REQUESTURL.RUNNING_REPORT;
            break;
        case "STOP_REPORT":
            api=REQUESTURL.STOP_REPORT;
            break;
        case "SPEED_REPORT":
            api=REQUESTURL.SPEED_REPORT;
            break;
        case "AC_REPORT":
            api=REQUESTURL.AC_REPORT;
            break;
        case "IGNITION_REPORT":
            api=REQUESTURL.IGNITION_REPORT;
            break;
        case "TEMPERATURE_REPORT":
            api=REQUESTURL.TEMPERATURE_REPORT;
            break;
        case "FUEL_REPORT":
            api=REQUESTURL.FUEL_REPORT;
            break;
        case "TRIP_REPORT":
            api=REQUESTURL.TRIP_REPORT;
            break;
        case "FUEL_SENSOR_REPORT":
            api= REQUESTURL.FUEL_SENSOR_REPORT;
            break;
        default:
            api="";
    }
    return api;
}