import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Suggestion from '../../../component/Suggestion/Suggestion';
import { sendAuthRequest } from '../../../util/API';
import { REQUESTURL } from '../../../constant/REQUEST-URL';
import Loader from '../../../component/Loader/Loader';
import Alert from '../../../component/Alert/Alert';


class AddSaleDevice extends React.Component {
    state = {
        usersSuggestion:[],
        devicesSuggestion:[],
        form:{
            user:{
                value:"",
                message:undefined,
                touched: false,
                valid: false,
                label: "User"
            },
            device:{
                value:[],
                message:undefined,
                touched: false,
                valid: false,
                label: "Device"
            }
        },
        isLoading: false,
        alert:{
            timeout:2000,
            message:"",
            messageType:"",
            isVisible:false
        }
    }

    fetchSuggestion = async () =>{
        try{
            const [userSuggestionResponse, deviceSuggestionResponse] = await Promise.all([sendAuthRequest(REQUESTURL.USER_SUGGESTION), sendAuthRequest(REQUESTURL.DEVICE_SUGGESTION)]);
            //user
            let userSuggestion = [];
            if(userSuggestionResponse){
                userSuggestion = userSuggestionResponse.result.map(user=>{
                    return { value: user.userId, label: user.username}
                });
            }
            let deviceSuggestion = [];
            if(deviceSuggestionResponse){
                deviceSuggestion = deviceSuggestionResponse.result.map(device=>{
                    return { value: device.deviceId, label: device.name ? device.name: device.serialNo}
                });
            }
            
            this.setState({
                usersSuggestion: userSuggestion,
                devicesSuggestion:deviceSuggestion
            });

        }catch (error){
            console.log(error);
        }
    }

    componentDidMount(){
        this.fetchSuggestion();
    }
    handleChange = (selectedOption,type) => {
        // console.log(selectedOption);
        // console.log(type);
        let updatedSelect = {...this.state.form}; 
        updatedSelect[type].value = selectedOption;
      
        this.checkValidation(updatedSelect,type);
        this.setState(
            {...updatedSelect}
        );
    };
    multiSelectHandleChange =(selectedOption,type) => {
        let updatedMultiSelect = {...this.state.form}; 
        updatedMultiSelect[type].value = selectedOption;
        this.checkValidation(updatedMultiSelect,type);
        this.setState(
            {...updatedMultiSelect}
        );
    }
    checkValidation = (updatedstate,type) =>{
        updatedstate[type].touched = true;
            if(updatedstate[type].value === "" || updatedstate[type].value.length === 0){
                updatedstate[type].valid = false;
                updatedstate[type].message = "Please select " + updatedstate[type].label + ".";
            }else{
                updatedstate[type].message = "";
                updatedstate[type].valid=true;
            }
    }
    doSubmit = () => {
        this.setState({
            isLoading:true
        });
       let deviceArr = this.state.form.device.value.map(device=>{
           return device.value
       });
        const data={
            deviceIds: deviceArr,
            userId:this.state.form.user.value.value,
        }
        // console.log(data);
        const clearForm = this.clearForm();
        sendAuthRequest(REQUESTURL.ADD_SALEDEVICE,data).then(response => {
            console.log(response);
            this.setState({
                isLoading:false,
                alert:{
                    timeout:2000,
                    message:response.statusMessage,
                    messageType:"SUCCESS",
                    isVisible:true
                }
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                isLoading:false,
                alert:{
                    timeout:2000,
                    message:error,
                    messageType:"ERROR",
                    isVisible:true
                }
            })
        });
    }
    submitHandler = () => {
        // console.log(this.state)
        if(this.state.form.device.valid && this.state.form.user.valid){
           this.doSubmit();
        }else{
            const updatedstate = {...this.state.form};
            for(let type in updatedstate){
                this.checkValidation(updatedstate,type);
            }
            this.setState({
                ...updatedstate
            });
        }
    }
    cancelHandler = () => {
        this.props.history.goBack();
     }
     clearForm = () => {
        const updatedForm = {...this.state.form};
        for (let type in updatedForm) {
            updatedForm[type].value= "";
            updatedForm[type].message=undefined;
            updatedForm[type].valid = false;
            updatedForm[type].touched= false;
        }
        return updatedForm;
    }
     onAlertClose = () => {
         this.setState({
             alert:{
                timeout:2000,
                message:"",
                messageType:"",
                isVisible:false
             }
         });
    }
    render() {
        const isDeviceInvalid = this.state.form.device.message !== "" && this.state.form.device.touched && !this.state.form.device.valid;
        const isUserInvalid = this.state.form.user.message !== "" && this.state.form.user.touched && !this.state.form.user.valid;
        return (
            <div className="add_device page_wrapper_height">
                 {this.state.isLoading ? <Loader></Loader> : null} 
                 {this.state.alert.isVisible ?  <Alert alert={this.state.alert} onClose={this.onAlertClose}></Alert> : null}
                <div className="title_heading">
                    <h2>Add New Sale Device</h2>
                </div>
                <div className="custom_form inner_content_wrapper">
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <div className="inner_scroll_div">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="form-group">
                                            <label className="mb-2 w-100">User <span className="red_text">*</span></label>
                                            <Suggestion  handleChange={this.handleChange}
                                                options={this.state.usersSuggestion} isSearchable={true} type="user" value={this.state.form.user.value}></Suggestion>
                                            {isUserInvalid ? <p className="errorMsg">{this.state.form.user.message}</p>: null}
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 w-100">Device <span className="red_text">*</span></label>
                                            <Suggestion   handleChange={this.multiSelectHandleChange}
                                                options={this.state.devicesSuggestion} isMulti={true} type="device"  value={this.state.form.device.value}></Suggestion>
                                                 {isDeviceInvalid ? <p className="errorMsg">{this.state.form.device.message}</p>: null}
                                        </div>
                                    </div>
                                </div>

                                <div className="submit_btn">
                                    <button className="btn btn-blue mr-2" onClick={this.submitHandler} type="button">Save</button>
                                    <button className="btn btn-green mr-2" type="button" >Clear</button>
                                    <button className="btn btn-gray mr-2" type="button" onClick={this.cancelHandler}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </PerfectScrollbar>

                </div>
            </div>
        );
    }
}
export default AddSaleDevice;