import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Accordion, useAccordionToggle } from 'react-bootstrap';

const CustomToggle = (props) => {
    const decoratedOnClick = useAccordionToggle(props.eventKey);
    return (
        <div className="ml-auto dropdown_btn"
            onClick={decoratedOnClick}
        >
            {props.children}
        </div>
    );
}



class SaleDeviceFilter extends Component{
    constructor(props){
        super(props);
        this.state = {
            username:"",
            email:"",
            deviceName:"",
            serialNo:""
        }
    }

    clearAllHandler = () =>{
        this.setState({
            username:"",
            email:"",
            deviceName:"",
            serialNo:""
        }, ()=>{
            this.props.clearAllHandler();
        });

    }

    applyFilter = () =>{
        if(this.state.username || this.state.email || this.state.deviceName || this.state.serialNo){

            let data = {
                "username":this.state.username,
                "email":this.state.email,
                "deviceName":this.state.deviceName,
                "serialNo":this.state.serialNo
            }
            console.log(data);
            this.props.applyFilter(data);
        }
    }

    render(){
        return (

            <div className="filter_sidebar_scroll">
                <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <div className="filter_sidebar_padding">
                        
                        <div className="filter_heading d-flex">
                            <h4>Filters</h4>
                            <button className="ml-auto teal_text label_button " onClick={this.clearAllHandler}>Clear all</button>
                        </div>
                        <hr />
                        <div className="filters_accordian">
                            <Accordion defaultActiveKey="0">
                                <div className="filter_row">
                                    <div className="filter_head d-flex mb-3">
                                        <p className="title">Device Name</p>
                                        <CustomToggle eventKey="0"><i className="fas fa-chevron-down"></i></CustomToggle>
                                    </div>
                                    <Accordion.Collapse eventKey="0">
                                        <div className="filter_body">
                                            <input type="text" placeholder="Search by name" value={this.state.deviceName} onChange={(event)=>{this.setState({deviceName: event.target.value})}} className="form-control" />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="filter_row">
                                    <div className="filter_head d-flex mb-3">
                                        <p className="title">User Name</p>
                                        <CustomToggle eventKey="1"><i className="fas fa-chevron-down"></i></CustomToggle>
                                    </div>
                                    <Accordion.Collapse eventKey="1">
                                        <div className="filter_body">
                                            <input type="text" placeholder="Search by username" value={this.state.username} onChange={(event)=>{this.setState({username: event.target.value})}} className="form-control" />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="filter_row">
                                    <div className="filter_head d-flex mb-3">
                                        <p className="title">Email</p>
                                        <CustomToggle eventKey="2"><i className="fas fa-chevron-down"></i></CustomToggle>
                                    </div>
                                    <Accordion.Collapse eventKey="2">
                                        <div className="filter_body">
                                            <input type="text" placeholder="Search by email" value={this.state.email} onChange={(event)=>{this.setState({email: event.target.value})}} className="form-control" />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="filter_row">
                                <div className="filter_head d-flex mb-3">
                                    <p className="title">SR No.</p>
                                    <CustomToggle eventKey="3"><i className="fas fa-chevron-down"></i></CustomToggle>
                                </div>
                                <Accordion.Collapse eventKey="3">
                                <div className="filter_body">
                                        <input type="text" placeholder="Search by SR No." className="form-control" 
                                            value={this.state.serialNo} 
                                            onChange={(event)=> this.setState({serialNo:event.target.value})}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                                
                            </Accordion>
    
                        </div>
                        
                    </div>
                    
                </PerfectScrollbar>
                <div className="apply_btn">
                    <button className="btn btn-blue btn-block" type="button" onClick={this.applyFilter}>Apply</button>
                </div>
                </div>
        );
    }

}

export default SaleDeviceFilter;