import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserFilter  from '../Filter/UserFilter';
import UserItem from '../../../component/UserItem/UserItem';
import Alert from '../../../component/Alert/Alert';
import {REQUESTURL} from '../../../constant/REQUEST-URL';
import {sendAuthRequest} from '../../../util/API';
import { isUser } from '../../../util/Auth';
import SweetAlert from 'react-bootstrap-sweetalert';
import ContentLoader from '../../../component/ContentLoader/ContentLoader';
const LIMIT_USER = 10;
class ManageUser extends Component{
    state= {
        users:[],
        displayFilter: window.innerWidth<=991 ? false : true,
        isLoading: true,
        alert:{
            timeout:2000,
            message:"",
            messageType:"",
            isVisible:false
        },
        deleteAlert: false,
        deleteUserId: "",
        filterApplied:false,
        lastItemId:null,
        totalUsers:0,
        currentPage: 1,
    }
    filterHandler = () => {
        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: !prevState.displayFilter
                }
            })
        } 
    }
    applyFilterHandler = (data) => {
        if(window.innerWidth<=991){
            this.setState((prevState)=>{
                return {
                    displayFilter: false
                }
            });
        }
        
        data = {
            ...data,
            filterApplied:true,
            currentPage: 1,
            limit: LIMIT_USER
        }
        console.log(data);
        this.setState({
            isLoading:true,
            filterApplied:true,
            users:[],
            currentPage: 1,
            totalUsers:0,
            filterData:{...data}
        });

        this.fetchUsers(data);

    }
    onCloseAlert = ()=>{
        const alert = {
            ...this.state.alert,
            isVisible:false
        }
        this.setState({
            alert: alert
        })
    }

    scrollDownReachLimitHandler = (container) => {
        if((this.state.totalUsers > 0) && (LIMIT_USER * this.state.currentPage) < this.state.totalUsers && !this.state.isLoading){
            const data = {
                currentPage: this.state.currentPage + 1,
                limit: LIMIT_USER,
                lastItemId: this.state.lastItemId,
                filterApplied:this.state.filterApplied,
                ...this.state.filterData
            }
            this.setState({
                isLoading : true,
                currentPage: this.state.currentPage + 1,
            })
            this.fetchUsers(data);
        }
    }

    getUsersList = (UsersList)=> {
        return (
        <div className="user_list inner_content_list">
            <PerfectScrollbar options={{suppressScrollX:true}}  onYReachEnd={this.scrollDownReachLimitHandler}>
                <div className="inner_scroll_div">
                    <div className="row">
                        {UsersList}
                        {this.state.isLoading? <ContentLoader size={4}  type="USER" />: null}
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
        )
    }
    componentDidMount(){
        let data={
            limit:LIMIT_USER,
            "filterApplied":false,
            "nextPage":true,
            currentPage: this.state.currentPage
        };
        this.fetchUsers(data);
    }

    fetchUsers = (data, res)=>{
        sendAuthRequest(REQUESTURL.FETCH_USERS,data).then(response => {
            let users = [];          
            if(response.result && response.result.users){
                users = response.result.users;
            }
            if(res){
                this.setState({
                    isLoading:false,
                    users: users,
                    alert:{
                        timeout:2000,
                        message: res.statusMessage,
                        messageType:"SUCCESS",
                        isVisible:true 
                    },
                    lastItemId:response.result.lastItemId,
                    totalUsers: response.result.totalUsers,
                    currentPage: response.result.currentPage
                })
            }else{
                const usersData = [...this.state.users, ...users];
                this.setState({
                    isLoading:false,
                    users: usersData,
                    lastItemId:response.result.lastItemId,
                    currentPage: response.result.currentPage,
                    totalUsers: response.result.totalUsers
                })
            }
            
        }).catch(error => {
            if(res){
                throw error
            }else{
                this.setState({
                    isLoading:false,
                    alert:{
                        timeout:2000,
                        message: error,
                        messageType:"ERROR",
                        isVisible:true
                    }
                });
            }
            
        })
    }
    deleteUserHandler = (user) =>{

        const userId = user.userId;
        this.setState({
            deleteAlert:true,
            deleteUserId: userId
        });
    }
    deleteFile =()=>{
        this.setState({
            deleteAlert:false,
            isLoading:true
        });
        const data = {
            userId:this.state.deleteUserId
        }
      
        sendAuthRequest(REQUESTURL.DELETE_USER,data).then(res =>{
            data={
                limit:LIMIT_USER,
                "filterApplied":false,
                "nextPage":true,
                currentPage: 1
            };
            this.fetchUsers(data,res);
        }).catch(error => {
            this.setState({
                isLoading:false,
                alert:{
                    timeout:2000,
                    message: error,
                    messageType:"ERROR",
                    isVisible:true 
                }
            })
        });
    }
    onCancel =()=>{
        this.setState({
            deleteAlert:false
        })
    }

    clearAllHandler = (event) =>{
        this.setState({
            isLoading:true,
            filterApplied:false,
            users:[],
            filterData:null
        });
        let data={
            limit:LIMIT_USER,
            "filterApplied":false,
            "nextPage":true,
            currentPage: 1
        };
        this.fetchUsers(data);
    }
    render(){
        let UserList = [];
        if(this.state.users.length > 0){
            UserList = this.state.users.map( (user) => {
                return (<UserItem key={user.userId} user={user} deleteUserHandler={this.deleteUserHandler }/>)
            });
        }
        
        return (
            <div className="users_page page_wrapper_height">
                 
                {this.state.alert.isVisible?<Alert alert={this.state.alert} onClose={this.onCloseAlert}></Alert> : null}
              
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle= "info"
                    title="Are you sure?"
                    onConfirm={this.deleteFile}
                    onCancel={this.onCancel}
                    btnSize="md"
                    focusCancelBtn
                    show = {this.state.deleteAlert}
                    >
                    You want to delete this User?
                </SweetAlert>
                <div className="title_heading">
                    <h2>Manage Users</h2>
                    <div className="right_part ml-auto">
                      <button className="btn btn btn-green mr-2 d-inline-block d-lg-none" onClick={this.filterHandler}> <i className="fas fa-filter"></i> </button>
                      {/* <button className="btn btn-blue"><i className="fas fa-plus mr-1"></i> New User</button> */}
                      
                     { !isUser() ? <Link to={"/dashboard/users/add-user"} className="btn btn-blue"><i className="fas fa-plus mr-1"></i> New User</Link>:null}
                    </div>
                </div>
                <div className="user_list_sec inner_content_wrapper">
                    {
                        this.state.displayFilter ? (
                        <div className="filter_sidebar">    
                            <UserFilter applyFilter = {this.applyFilterHandler} clearAllHandler={this.clearAllHandler}/>
                        </div>) :
                        null
                    }
                    {
                        
                        window.innerWidth > 991 ? this.getUsersList(UserList) : (                        
                        !this.state.displayFilter && window.innerWidth <=991 ? this.getUsersList(UserList) : null)
                    }
                    
                </div>
            </div>
        );
    }
}
export default ManageUser;