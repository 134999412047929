export const REQUESTURL = {
    AUTHENTICATION: "/authenticate",
    FETCH_DEVICES: "/device/",
    EDIT_DEVICE: "/device/update",
    ADD_DEVICE: "/device/new",
    DELETE_DEVICE: "/device/delete",
    DEVICE_LIST: "/device/list",
    FETCH_USERS: "/user/list",
    ADD_USER: "/user/new",
    EDIT_USER: "/user/update",
    DELETE_USER: "/user/delete",
    FETCH_SALEDEVICE: "/sale-device/list",
    ADD_SALEDEVICE: "/sale-device/sale",
    USER_SUGGESTION: "/user/suggestion",
    DEVICE_SUGGESTION: "/device/suggestion",
    DELETE_SALEDEVICE:"/sale-device/delete",
    FORGOT_PASSWORD:"/user/forgot-password",
    ADD_GEOFENCE:"/geofence/insert",
    DELETE_GEOFENCE:"/geofence/delete",
    FETCH_GEOFENCE:"/geofence/",
    ADD_NOTIFICATION_TOKEN:"/user/addNotificationToken",
    REMOVE_NOTIFICATION_TOKEN:"/user/removeNotificationToken",
    NOTIFICATION_FETCH:"/notifications/",
    PLAYBACK:"/device/playback",
    VIEWMORE: "/device/viewmore",
    VIEWMORE_V2: "/device/viewmore/V2",
    RUNNING_REPORT: "/report/running",
    FUEL_REPORT: "/report/fuel",
    STOP_REPORT: "/report/stop",
    SPEED_REPORT: "/report/speed",
    AC_REPORT: "/report/ac",
    IGNITION_REPORT: "/report/ignition",
    TEMPERATURE_REPORT: "/report/temperature",
    FETCH_DEVICES_V2: "/device/v2/",
    LIVE_TRACK: "/device/livetrack",
    GET_LAST_PARKING_DATA: "/device/getLastParkingData",
    TRIP_REPORT: "/report/trip",
    FUEL_SENSOR_REPORT: "/report/fuelSensor",
    FETCH_EXPIRED_DEVICE_LIST: "/device/expiredDevice",
    GENERATE_LIVE_SHARING_LINK: "/device/generateLiveTrackUrl",
    GET_LIVE_SHARING_LINK: "/device/getLiveSharingLink",
    GET_LIVE_SHARING_DETAILS: "/device/getLiveSharingDetails",
    GET_LIVE_SHARING_LINKS: "/device/getLiveSharingList",
    UPDATE_LIVE_SHARING_LINK: "/device/updateLiveSharingLink",
    DELETE_LIVE_SHARING_LINK: "/device/deleteLiveSharingLink"
}
